import { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import './project.css';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useModal } from 'react-hooks-use-modal';
import projectsService from '../../services/projectsService';
import calendarIcon from '../../assets/icons/calendar.png';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { TextField, InputAdornment, Box } from '@mui/material';
import { Search } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import userService from 'services/userService';

import ja from 'date-fns/locale/ja';
import CompanyService from 'services/clientCompanyService';
import workersService from 'services/wokersService';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import CustomPagination from 'components/CustomPagination';
import { IPageQueries } from 'models';
import ViewToggle from './projectPlan/components/ViewToggle';
import ProjectCard from './projectPlan/components/ProjectCard';
import {
    EntityEnum,
    SugorokuIndexEnum,
    WasteSugorokuIndexEnum,
    WasteSugorokuIndexEnum2,
} from 'utils/enums';
import projectApi from 'services/projectApi';
import { DatePicker, Select } from 'antd';
import { noteOptions } from './projectCreate';
import quoteApi from 'services/quoteApi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const statusOptions = Object.values(WasteSugorokuIndexEnum2).map((item) => ({
    value: item.name,
    label: item.title,
}));

const Projects = (props) => {
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const [projectsList, setProjectsList] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    const [searchedState, setSearchedState] = useState({});
    const [cardViewMode, setCardViewMode] = useState(false);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentProject, setCurrentProject] = useState<any>(null);

    // Configurations
    const [statuses, setStatuses] = useState<any>([]);
    const [statusBase, setStatusBase] = useState<any>({});
    const [businessTypes, setBusinessTypes] = useState<any>([]);
    const [listCompanies, setListCompanies] = useState<any>([]);
    const startDateRef: any = useRef();
    const endDateRef: any = useRef();
    const [listWorkers, setListWorkers] = useState<any>([]);

    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndAtChange = (date: any) => {
        setEndDate(date);
    };

    const onSearch = (data) => {
        setSearchedState({ ...data });
        let newData = {
            ...data,
        };
        if (data?.start_at) {
            newData.start_at = new Date(data?.start_at);
        }
        if (data?.end_at) {
            newData.end_at = new Date(data?.end_at);
        }
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListProjects({
            page: 1,
            per_page: pageQueries.per_page,
            data: newData,
        });
    };

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setStatuses(
                    [
                        ...Object.values(SugorokuIndexEnum).map((v) => ({
                            value: v.name,
                            label: `解体 - ${Math.floor(v.value / 10)}. ${v.title
                                }`,
                        })),
                        ...Object.values(WasteSugorokuIndexEnum).map(
                            (v, i) => ({
                                value: v.name,
                                label: `産廃 - ${i + 1}. ${v.title}`,
                            }),
                        ),
                    ],
                    // convertUserConfigToDropdownOptions(
                    //   response.configurations?.project?.statuses
                    // )
                );
                setStatusBase(response.configurations?.project?.statuses);
                setBusinessTypes(
                    convertUserConfigToDropdownOptions(
                        response.configurations?.project?.business_types,
                    ),
                );
            }
        } catch (err) {
            // empty
        }
    };

    const getListClientCompanies = async () => {
        try {
            const res = await quoteApi.geAlltCompanies(user?.access_token);
            if (res) {
                setListCompanies(
                    convertClientCompaniesToDropdownOptionsCreateProject(
                        res.data.cooperative_companies,
                    ),
                );
            }
            // const response = await CompanyService.getListCompanies({
            //     per_page: 999,
            // });
            // if (response !== null && response !== undefined) {
            // setListCompanies(
            //     convertClientCompaniesToDropdownOptionsCreateProject(
            //         response.client_companies,
            //     ),
            //     );
            // }
        } catch (err) {
            // empty
        }
    };

    const getListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers({
                per_page: 999,
            });
            if (response !== null && response !== undefined) {
                setListWorkers(
                    convertObjectToDropdownOptions(response.workers),
                );
            }
        } catch (err) {
            // empty
        }
    };

    const getListProjects = async ({ page = 1, per_page = 5, data = {} }) => {
        try {
            const res = await projectApi.getProject(user?.access_token, {
                page,
                per_page,
                ...data,
            });
            if (res) {
                const projects = res.data?.projects;
                const meta = res.data?.meta;
                setProjectsList(projects);
                setTotalCount(meta.total_count);
            }
            // const response = await projectsService.getListProjects({
            //     page,
            //     per_page,
            //     ...data,
            // });
            // if (response !== null && response !== undefined) {
            //     console.log('response', response)
            //     setProjectsList(response.projects);
            //     setTotalCount(response.meta.total_count);
            // }
        } catch (err) {
            // empty
        }
    };

    const deleteProject = async () => {
        await projectsService
            .deleteProject(currentProject.id)
            .then((data) => {
                alertSuccess('案件が削除されました。');
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListProjects({
                    page: 1,
                    per_page: pageQueries.per_page,
                    data: { ...searchedState },
                });
            })
            .catch((err) => {
                const response = err?.response;
                if (!response || typeof response === 'undefined') {
                    navigate('login');
                    return;
                }
                alertError(response.data?.error);
            })
            .finally(() => {
                setCurrentProject(null);
                closeDelete();
            });
    };

    useEffect(() => {
        getListConfiguration();
        getListClientCompanies();
        getListWorkers();
    }, []);

    useEffect(() => {
        getListProjects({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                {/* <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate('/projects/create')}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div> */}
                <form onSubmit={handleSubmit(onSearch)}>
                    <div
                        className="search-workers d-flex flex-column"
                        style={{ height: '200px' }}
                    >
                        <div className="search-workers-row grid grid-cols-3 d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <Box
                                    sx={{
                                        width: '100%',
                                        '.input-search': {
                                            border: '1px solid #cdd1d5',
                                            borderRadius: '2px',
                                            marginBottom: '14.5px',
                                            paddingLeft: '14px',
                                        },
                                        '.MuiTextField-root': {
                                            border: '1px solid #cdd1d5',
                                            width: '100%',
                                            borderRadius: '2px',
                                            marginRight: '12.5px',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '40px',
                                            padding: '0px !important',
                                        },
                                    }}
                                    className=""
                                >
                                    <div className="input-add-user">
                                        <p
                                            style={{
                                                marginTop: 0,
                                                marginBottom: '4px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#344054',
                                            }}
                                        >
                                            案件名
                                        </p>
                                    </div>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        sx={{ color: '#000' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        name="property_name"
                                        onChange={(e) => {
                                            setValue(
                                                'property_name',
                                                e.target.value,
                                            );
                                        }}
                                    />
                                    {/* <input
                                        className="height36 search-icon"
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        {...register('free_word')}
                                        placeholder="&#xf002;"
                                    ></input> */}
                                </Box>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <Box
                                    sx={{
                                        width: '100%',
                                        '.input-search': {
                                            border: '1px solid #cdd1d5',
                                            borderRadius: '2px',
                                            marginBottom: '14.5px',
                                            paddingLeft: '14px',
                                        },
                                        '.MuiTextField-root': {
                                            border: '1px solid #cdd1d5',
                                            width: '100%',
                                            borderRadius: '2px',
                                            marginRight: '12.5px',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '40px',
                                            padding: '0px !important',
                                        },
                                    }}
                                >
                                    <div className="input-add-user">
                                        <p
                                            style={{
                                                marginTop: 0,
                                                marginBottom: '4px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#344054',
                                            }}
                                        >
                                            顧客
                                        </p>
                                    </div>
                                    <TextField
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        name="customer_name"
                                        style={{ paddingLeft: '5px' }}
                                        onChange={(e) => {
                                            setValue(
                                                'customer',
                                                e.target.value,
                                            );
                                        }}
                                    />
                                </Box>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>業種</p>
                                    <Controller
                                        name="entity"
                                        control={control}
                                        render={({ field: { value } }) => (
                                            <Select
                                                className="w-full h-[40px]"
                                                showSearch
                                                allowClear
                                                options={noteOptions}
                                                filterOption={(
                                                    input,
                                                    option: any,
                                                ) =>
                                                    (option?.label ?? '')
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase(),
                                                        )
                                                }
                                                onChange={(
                                                    event,
                                                    newValue: any,
                                                ) => {
                                                    if (newValue) {
                                                        setValue(
                                                            'entity',
                                                            newValue?.value,
                                                        );
                                                    } else {
                                                        setValue(
                                                            'entity',
                                                            undefined,
                                                        );
                                                    }
                                                }}
                                            />
                                            // <Dropdown
                                            //     className="dropdown-create-user1 height36"
                                            //     controlClassName="dropdown-control-user height36"
                                            //     options={businessTypes.filter(
                                            //         (v) => v.label !== '不動産',
                                            //     )}
                                            //     value={{
                                            //         label: value?.label || '',
                                            //         value: value?.value || '',
                                            //     }}
                                            //     placeholder=""
                                            //     onChange={(option) => {
                                            //         if (
                                            //             value &&
                                            //             value.value ===
                                            //             option.value
                                            //         ) {
                                            //             setValue(
                                            //                 'business_type',
                                            //                 undefined,
                                            //             );
                                            //         } else {
                                            //             setValue(
                                            //                 'business_type',
                                            //                 option,
                                            //             );
                                            //         }
                                            //     }}
                                            // />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-workers-row grid grid-cols-3 d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: '8px' }}>協力会社</p>
                                    <Select
                                        className="w-full h-[40px]"
                                        showSearch
                                        allowClear
                                        options={listCompanies}
                                        filterOption={(input, option: any) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={(event, newValue: any) => {
                                            if (newValue) {
                                                setValue(
                                                    'manager_company_id',
                                                    newValue?.value,
                                                );
                                            } else {
                                                setValue(
                                                    'manager_company_id',
                                                    undefined,
                                                );
                                            }
                                        }}
                                    />
                                    {/* <Autocomplete
                                        disablePortal
                                        options={listWorkers.map((item) => ({
                                            value: item.value,
                                            label: item.label,
                                        }))}
                                        getOptionLabel={(option: any) =>
                                            option.label || option.value
                                        }
                                        onChange={(event, newValue: any) => {
                                            if (newValue) {
                                                setValue(
                                                    'manager_worker_id',
                                                    newValue,
                                                );
                                            } else {
                                                setValue(
                                                    'manager_worker_id',
                                                    undefined,
                                                );
                                            }
                                        }}
                                        classes={{
                                            root: 'project-list-search-select-style',
                                            input: 'project-list-search-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                    /> */}
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown  margin-left d-flex flex-column">
                                <div className="input-add-user">
                                    <p style={{ marginTop: '8px' }}>
                                        ステータス
                                    </p>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field: { value } }) => {
                                            return (
                                                <Select
                                                    className="w-full h-[40px]"
                                                    showSearch
                                                    allowClear
                                                    options={statusOptions}
                                                    filterOption={(
                                                        input,
                                                        option: any,
                                                    ) =>
                                                        (option?.label ?? '')
                                                            .toLowerCase()
                                                            .includes(
                                                                input.toLowerCase(),
                                                            )
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue: any,
                                                    ) => {
                                                        if (newValue) {
                                                            setValue(
                                                                'status',
                                                                newValue?.value,
                                                            );
                                                        } else {
                                                            setValue(
                                                                'status',
                                                                undefined,
                                                            );
                                                        }
                                                    }}
                                                />
                                                // <Dropdown
                                                //     className="dropdown-create-user1 height36"
                                                //     controlClassName="dropdown-control-user height36"
                                                //     options={statuses}
                                                //     value={{
                                                //         label:
                                                //             value?.label || '',
                                                //         value:
                                                //             value?.value || '',
                                                //     }}
                                                //     placeholder=""
                                                //     onChange={(option) => {
                                                //         if (
                                                //             value &&
                                                //             value.value ===
                                                //             option.value
                                                //         ) {
                                                //             setValue(
                                                //                 'status',
                                                //                 undefined,
                                                //             );
                                                //         } else {
                                                //             setValue(
                                                //                 'status',
                                                //                 option,
                                                //             );
                                                //         }
                                                //     }}
                                                // />
                                            );
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user">
                                    <p style={{ marginTop: '8px' }}>期間</p>
                                </div>
                                <div className="d-flex flex-row w-full gap-[5px]">
                                    <div
                                        className="w-[49%]"
                                        style={{ position: 'relative' }}
                                    >
                                        <Controller
                                            name="start_at"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    onChange={(date) => {
                                                        field?.onChange(date);
                                                        handleStartAtChange(
                                                            date,
                                                        );
                                                    }}
                                                    className="h-[40px] !w-full"
                                                    format="YYYY/MM/DD"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </div>
                                    <span className="symbol mt-[8px]">~</span>
                                    <div
                                        className="w-[49%]"
                                        style={{ position: 'relative' }}
                                    >
                                        <Controller
                                            name="end_at"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    onChange={(date) => {
                                                        field?.onChange(date);
                                                        handleEndAtChange(date);
                                                    }}
                                                    className="h-[40px] !w-full"
                                                    format="YYYY/MM/DD"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="search-workers-row-button width-full margin-left d-flex flex-column">
                            <button
                                className="search-user-button d-flex flex-column"
                                type="submit"
                                style={{
                                    width: '150px',
                                    height: '36px',
                                    marginBottom: '12px',
                                }}
                            >
                                検索
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-workers d-flex flex-column">
                    <div className="table-content d-flex flex-column">
                        {!cardViewMode ? (
                            <TableContainer
                                component={Paper}
                                table-layout={'auto'}
                            >
                                <Table
                                    sx={{ minWidth: 1600 }}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div>案件名</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '5%' }}
                                            >
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    業種
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '20%' }}
                                            >
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    顧客
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{
                                                    width: '7%',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <div>ステータス</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '5%' }}
                                            >
                                                <div>開始</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '5%' }}
                                            >
                                                <div>終了</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '15%' }}
                                            >
                                                <div>見積担当</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '10%' }}
                                            >
                                                <div>協力会社</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                align="center"
                                                style={{ width: '35%' }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projectsList !== null &&
                                            projectsList.map((row, ind) => (
                                                <StyledTableRow key={ind}>
                                                    <StyledTableCell
                                                        align="left"
                                                        component="th"
                                                        scope="row"
                                                        style={{ width: '15%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row.property_name}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '5%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {
                                                                EntityEnum[
                                                                    row.entity
                                                                ]?.title
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '20%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {
                                                                row?.quote
                                                                    ?.customer
                                                                    ?.last_name
                                                            }
                                                            {
                                                                row?.quote
                                                                    ?.customer
                                                                    ?.first_name
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '5%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                                color: WasteSugorokuIndexEnum2[
                                                                    row?.status
                                                                ].textColor,
                                                                backgroundColor:
                                                                    WasteSugorokuIndexEnum2[
                                                                        row
                                                                            ?.status
                                                                    ].bgColor,
                                                                borderRadius:
                                                                    '15px',
                                                                padding:
                                                                    '4px 9px',
                                                            }}
                                                        >
                                                            {
                                                                WasteSugorokuIndexEnum2[
                                                                    row?.status
                                                                ].title
                                                            }
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '5%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row.from_date
                                                                ? moment(
                                                                    row.from_date,
                                                                ).format(
                                                                    'YYYY/MM/DD',
                                                                )
                                                                : ''}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '5%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row.end_date
                                                                ? moment(
                                                                    row.end_date,
                                                                ).format(
                                                                    'YYYY/MM/DD',
                                                                )
                                                                : ''}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '15%' }}
                                                    >
                                                        <div className="text-overflow1 whitespace-nowrap">
                                                            {
                                                                row?.person_in_charge
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align="center"
                                                        style={{ width: '10%' }}
                                                    >
                                                        <div
                                                            className="text-overflow1"
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {
                                                                row?.manager_company
                                                            }
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        align="center"
                                                        style={{
                                                            width: '30%',
                                                            minWidth: '270px',
                                                        }}
                                                    >
                                                        <button
                                                            className="button-tb"
                                                            style={{
                                                                width: '72px',
                                                                margin: '5px',
                                                            }}
                                                            onClick={() => {

                                                                navigate(
                                                                    `/projects/detail/${row.id}/plan`,
                                                                    {
                                                                        state: {
                                                                            id: row.id,
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                        >
                                                            詳細
                                                        </button>
                                                        <button
                                                            className="button-tb"
                                                            style={{
                                                                width: '72px',
                                                                margin: '5px',
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    `/projects/edit/${row?.quote?.id}?project_id=${row?.id}`,
                                                                );
                                                            }}
                                                        >
                                                            編集
                                                        </button>
                                                        <button
                                                            className="button-tb"
                                                            style={{
                                                                width: '72px',
                                                                margin: '5px',
                                                                background:
                                                                    '#FF5045',
                                                            }}
                                                            onClick={() => {
                                                                openDelete();
                                                                setCurrentProject(
                                                                    row,
                                                                );
                                                            }}
                                                        >
                                                            削除
                                                        </button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    gap: '28px',
                                }}
                            >
                                {projectsList !== null &&
                                    projectsList.map((item) => (
                                        <ProjectCard
                                            key={item.id}
                                            name={item.name}
                                            startDate={item.start_at || ''}
                                            imagePath={
                                                item.image?.image_path || ''
                                            }
                                            onClick={() => {
                                                navigate(
                                                    `/projects/detail/${item.id}/plan`,
                                                    { state: { id: item.id } },
                                                );
                                            }}
                                        />
                                    ))}
                            </div>
                        )}

                        <ModalDelete>
                            <div
                                className="modal-create-user d-flex flex-column"
                                style={{ height: '152px' }}
                            >
                                <div className="modal-create-user-title d-flex flex-row">
                                    本当に削除しますか?
                                </div>
                                <div
                                    className="modal-create-user-buttons d-flex flex-row"
                                    style={{ marginTop: '50px' }}
                                >
                                    <button
                                        className="modal-create-user-button1 d-flex flex-row"
                                        onClick={closeDelete}
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        onClick={deleteProject}
                                        className="modal-create-user-button2 d-flex flex-row"
                                    >
                                        はい
                                    </button>
                                </div>
                            </div>
                        </ModalDelete>
                    </div>
                </div>
                {projectsList && projectsList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListProjects({
                                page,
                                per_page: pageQueries.per_page,
                                data: { ...searchedState },
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default Projects;
