import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Table,
    TableProps,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import PlusIcon from 'assets/icons/white-plus.svg';
import styles from './styles.module.scss';
import { STATUS_PERFORM } from 'utils/constants';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ButtonSubmit from 'pages/project/ButtonSubmit';
import dayjs from 'dayjs';
import IconRemove from 'assets/icons/ic-bg-remove.png';
import { useModal } from 'react-hooks-use-modal';
import { validate } from 'utils/constants/validate';
import { FORMAT } from 'utils/constants/format-date-time';
import performService from 'services/performService';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import LoadingSpin from 'components/LoadingSpin';
import clsx from 'clsx';
interface PerformProps {
    project_id: number | any;
    implement_id?: number | any;
    project?: any;
    setTab?: Dispatch<SetStateAction<number>>;
    getDetailProject?: any;
}

interface DataType {
    key?: number;
    id?: number;
    construction_name?: string;
    update_date?: any;
    status?: string;
}

const dataDefault: DataType[] = [
    {
        key: 1,
        construction_name: '',
        update_date: dayjs(),
        status: 'new_implement',
    },
];

const Perform: React.FC<PerformProps> = ({
    implement_id,
    project_id,
    setTab,
    project,
    getDetailProject,
}) => {
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
        },
    );

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [currentRecord, setCurrentRecord] = useState<{
        key?: number;
        id?: number;
    }>({ key: 0, id: 0 });

    const [dataPerform, setDataPerform]: any = useState([]);

    const defaultCount = !implement_id
        ? dataDefault?.length + 1
        : dataPerform?.length + 1;

    const [count, setCount] = useState(defaultCount);

    const getPerforms = async () => {
        setIsLoading(true);
        try {
            const responsePerform = await performService.getListPerform({
                id: implement_id,
                project_id: Number(project_id),
            });
            if (responsePerform !== null && responsePerform !== undefined) {
                const dataFormat =
                    responsePerform?.implement?.implement_items?.map(
                        (item: any, index: number) => {
                            const update_date_format = item?.created_at
                                ? dayjs(item?.created_at).format(
                                    FORMAT.FORMAT_DATE_UPPER,
                                )
                                : '';
                            return {
                                ...item,
                                key: index + 1,
                                construction_name: item?.name,
                                update_date: update_date_format,
                            };
                        },
                    );
                setIsLoading(false);
                setDataPerform(dataFormat);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleAdd = () => {
        const newData = {
            key: count,
            construction_name: '',
            update_date: dayjs(),
            status: 'new_implement',
        };

        setDataPerform([...dataPerform, newData]);
        setCount(count + 1);
    };

    const handleDeleteInvoice = async (implement_items_id?: number) => {
        try {
            const data = await performService.deletePerform(
                implement_id,
                Number(implement_items_id),
                Number(project_id),
            );
            if (data) {
                alertSuccess('工事の削除が完了しました。');
                closeDelete();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    const handleDelete = (key: any, id: any) => {
        const newData = dataPerform
            .filter((item: any) => item.key !== key)
            ?.map((element: any, index: number) => {
                return {
                    ...element,
                    key: index + 1,
                };
            });
        closeDelete();
        setDataPerform(newData);
        setCount(count - 1);
        if (!currentRecord?.id) {
            alertSuccess('工事の削除が完了しました。');
            closeDelete();
        }
        if (implement_id && currentRecord?.id) {
            handleDeleteInvoice(id);
        }
    };

    const updateValuesInvoice = (
        keyIndex?: number,
        keyValue?: string,
        data?: any,
    ) => {
        const dataPerformFormat = dataPerform?.map(
            (item: any, index: number) => {
                const isKey = item?.key === keyIndex;
                return isKey
                    ? {
                        ...item,
                        [`${keyValue}`]: data,
                        [`update_date`]: dayjs(),
                    }
                    : item;
            },
        );
        setDataPerform(dataPerformFormat);
    };

    const defaultColumns: TableProps<DataType>['columns'] = [
        {
            title: '番号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            width: '10%',
            render: (text, record) => (
                <>
                    <div>{record?.key}</div>
                </>
            ),
        },
        {
            title: '工事名',
            dataIndex: 'construction_name',
            key: 'construction_name',
            render: (text: any, record: any) => (
                <>
                    <Form.Item
                        className="mb-0"
                        name={`construction_name-${record?.key}`}
                        rules={[
                            () => ({
                                required: true,
                                validator(_: any, value: any) {
                                    if (!value) {
                                        return Promise.reject(
                                            new Error(
                                                `工事名${validate?.validate_input}`,
                                            ),
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input
                            disabled={isDisabledField}
                            className="rounded-lg"
                            onChange={(data) => {
                                const constructionNameFormat =
                                    data?.target?.value;
                                const key = record?.key;
                                updateValuesInvoice(
                                    key,
                                    'construction_name',
                                    constructionNameFormat,
                                );
                            }}
                        />
                    </Form.Item>
                </>
            ),
            width: '30%',
        },
        {
            title: '更新日',
            dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <Form.Item
                        className="mb-0 w-full"
                        name={`update_date-${record?.key}`}
                    >
                        <DatePicker
                            placeholder=""
                            disabled
                            className="rounded-lg w-[80%]"
                            format={FORMAT.FORMAT_DATE_UPPER}
                        />
                    </Form.Item>
                </>
            ),
            width: '20%',
        },
        {
            title: 'ステータス',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (text: any, record: any) => (
                <>
                    <div className="flex justify-center w-full">
                        <Form.Item
                            className="mb-0 line-clamp-1 w-full h-10"
                            name={`status-${record?.key}`}
                            rules={[
                                () => ({
                                    required: true,
                                    validator(_: any, value: any) {
                                        if (!value) {
                                            return Promise.reject(
                                                new Error(
                                                    `ステータス${validate?.validate_input}`,
                                                ),
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                        >
                            <Select
                                allowClear
                                className="!w-[60%] !text-sm"
                                options={STATUS_PERFORM}
                                suffixIcon={
                                    <CaretDownOutlined className="!text-[black]" />
                                }
                                onChange={(data) => {
                                    const statusFormat = data?.trim();

                                    const key = record?.key;
                                    updateValuesInvoice(
                                        key,
                                        'status',
                                        statusFormat,
                                    );
                                }}
                                disabled={!record?.id || isDisabledField}
                            />
                        </Form.Item>
                    </div>
                </>
            ),
            width: '30%',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <button
                    onClick={() => {
                        openDelete();
                        setCurrentRecord(record);
                        // handleDelete(record.key, record?.id);
                    }}
                    type="button"
                    className={clsx('button-tb btn-delete !bg-[#ff5045]', {
                        'cursor-no-drop': isDisabledField,
                    })}
                    disabled={isDisabledField}
                >
                    削除
                </button>
            ),
            width: '10%',
            align: 'center',
        },
    ];

    const columns = defaultColumns?.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    const onSubmit = async (values: DataType) => {
        if (dataPerform.length === 0) {
            alertError('登録をするためには、最低1つの工事が必要です。');
        } else {
            setIsLoading(true);
            const formData = new FormData();
            dataPerform.forEach((item) => {
                formData.append(
                    'implement_items_attributes[][name]',
                    item.construction_name?.trim() || '',
                );
                formData.append(
                    'implement_items_attributes[][status]',
                    item.status || '',
                );
                // case update
                if (item.id) {
                    formData.append(
                        'implement_items_attributes[][id]',
                        item.id || '',
                    );
                }
            });
            formData.append('project_id', String(project_id));

            if (implement_id) {
                await updatePerform(implement_id, formData);
            } else {
                await createPerform(formData);
            }
        }
    };

    const createPerform = async (formData: FormData) => {
        try {
            const data = await performService.createPerform(formData);
            if (data) {
                alertSuccess('工事が保存されました。');
                getDetailProject();
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const updatePerform = async (implement_id: number, formData?: any) => {
        try {
            const data = await performService.updatePerform(
                implement_id,
                formData,
            );
            setIsLoading(false);
            alertSuccess('工事が保存されました。');
            getPerforms();
            getDetailProject();
            if (data?.implement?.status?.includes('done')) {
                setTab && setTab(3);
            }
        } catch (error) {
            setIsLoading(false);
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const convertFieldsValue = () => {
        const fieldsValue =
            dataPerform?.map((item: any, index: number) => {
                const indexFormat = index + 1;
                return {
                    [`construction_name-${indexFormat}`]:
                        item?.construction_name,
                    [`update_date-${indexFormat}`]: dayjs(item?.update_date),
                    [`status-${indexFormat}`]: item?.status,
                };
            }) ?? [];
        const objFieldsValue = fieldsValue.reduce((acc, item) => {
            return { ...acc, ...item };
        }, {});

        return objFieldsValue;
    };

    const fieldsValue = convertFieldsValue();

    useEffect(() => {
        form?.setFieldsValue(fieldsValue);
    }, [fieldsValue]);

    useEffect(() => {
        if (implement_id) {
            getPerforms();
        }
    }, [implement_id]);

    useEffect(() => {
        setCount(defaultCount);
    }, [defaultCount]);

    useEffect(() => {
        if (!implement_id) {
            setDataPerform(dataDefault);
        }
    }, [implement_id]);

    const isDisabledField =
        (project?.implement !== null && project?.bills?.length > 0) ||
        project?.order === null;

    return (
        <>
            <div className={styles.tableCustom}>
                {isLoading ? (
                    <LoadingSpin />
                ) : (
                    <Form form={form} onFinish={onSubmit}>
                        <Table
                            columns={columns}
                            dataSource={dataPerform}
                            pagination={false}
                            locale={{ emptyText: 'データがありません。' }}
                        />
                        <div className="w-full flex justify-center mt-3">
                            <Button
                                onClick={handleAdd}
                                type="primary"
                                className="mb-4 w-fit !bg-[#215493] !border-none !rounded-lg !text-[#ffffff]"
                                disabled={isDisabledField}
                            >
                                <div className="flex gap-2">
                                    <img
                                        src={PlusIcon}
                                        alt="plus"
                                        height={16}
                                        width={16}
                                    />
                                    <span>追加</span>
                                </div>
                            </Button>
                        </div>
                        <ButtonSubmit disabled={isDisabledField} />
                    </Form>
                )}
            </div>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '243px' }}
                >
                    <img src={IconRemove} />
                    <div className="modal-create-user-title d-flex flex-row mt-4 font-semibold text-2xl">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row !border-[#e4e4e4] !rounded-[3px] !h-9"
                            onClick={closeDelete}
                        >
                            いいえ
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row !bg-[#d83535] !rounded-[3px] !h-9"
                            onClick={() => {
                                handleDelete(
                                    currentRecord.key,
                                    currentRecord?.id,
                                );
                            }}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
        </>
    );
};
export default Perform;
