import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
interface ButtonSubmitProps {
    disabled?: boolean;
}
const ButtonSubmit: React.FC<ButtonSubmitProps> = ({ disabled }) => {
    const navigate = useNavigate();
    return (
        <>
            <hr className="mt-5" />
            <div className="flex gap-3 justify-end">
                <button
                    className={clsx(
                        'back-button d-flex flex-row !rounded-sm !w-[124px] !h-[48px]',
                        {
                            'cursor-no-drop': disabled,
                        },
                    )}
                    onClick={() => navigate('/projects')}
                    disabled={disabled}
                >
                    キャンセル
                </button>
                <button
                    disabled={disabled}
                    type="submit"
                    className={clsx(
                        'back-button d-flex flex-row bg-[#215493] !text-[#ffffff] !rounded-sm !h-[48px] !w-[124px]',
                        {
                            'cursor-no-drop': disabled,
                        },
                    )}
                >
                    保存
                </button>
            </div>
        </>
    );
};
export default ButtonSubmit;
