import Swal from 'sweetalert2';

/*
 * alert
 *
 */
export const alertError = (message: string): void => {
  Swal.fire({
    title: 'エラー',
    text: message,
    icon: 'error',
    confirmButtonText: 'キャンセル',
    confirmButtonColor: '#215493',
  });
};

export const textTrim = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    let slicedText;
    slicedText = text.slice(0, maxLength) + "...";
    return slicedText;
  } else {
    return text;
  }
}

export const alertSuccess = (message: string): void => {
  Swal.fire({
    title: message,
    icon: 'success',
    confirmButtonText: '閉じる',
    confirmButtonColor: '#215493',
    customClass: {
      title: 'custom-swal-title-class',
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    }
  });
}

export const alertSuccessPopup = (
  { title, confirmButtonText }:
    { title: string, confirmButtonText: string }
): void => {
  Swal.fire({
    title,
    icon: 'success',
    confirmButtonText,
    confirmButtonColor: '#215493',
    customClass: {
      title: `custom-swal-title-class`,
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    }
  });
}

export const alertSuccessPopup2 = (
  { title, confirmButtonText }:
    { title: string, confirmButtonText: string }
): void => {
  Swal.fire({
    title,
    icon: 'success',
    confirmButtonText,
    confirmButtonColor: '#215493',
    customClass: {
      title: `custom-swal-title-class text-[#07A315]`,
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    }
  });
}

export const alertPopup = (
  { type, title, confirmButtonText = '閉じる', haveCustomClass }:
    {
      type: 'success' | 'error' | 'warning' | 'info' | 'question',
      title: string,
      confirmButtonText?: string,
      haveCustomClass?: boolean
    }
): void => {
  Swal.fire({
    title,
    icon: type,
    confirmButtonText,
    confirmButtonColor: '#215493',
    customClass: haveCustomClass ? {
      title: 'custom-swal-title-class',
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    } : undefined
  });
}

export const validateZipcode = (zipcode: string) => {
  return new RegExp('^(^$|[0-9]{3}-[0-9]{4}|ー)$').test(zipcode);
}
