import Api from './api/clientCompanyApi';
import pick from 'lodash/pick';
// import projectList from '../shared/mocks/projectLists.json';

const getListCompanies = async (input: any = {}) => {
    // return projectList; // TODO remove debug
    let data = pick(input, ['page', 'per_page', 'free_word', 'business_type']);
    const params = new URLSearchParams(data);
    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (err) {
        window.location.href = '/login';
        return null;
    }
};

const getCompany = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const createCompany = async (body) => {
    let data = pick(body, [
        'name',
        'email',
        'fax',
        'zipcode',
        'address',
        'date_of_establishment',
        'capital',
        'number_of_employees',
        'phone',
        'business_content',
        'business_type',
        'note',
        'is_client_company',
        'is_cooperative_company',
        'province',
        'company_address',
        'person_in_charge',
        'person_in_charge_phone_number',
    ]);
    data.parent_company_id = body.parent_company_id?.value;
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .post('', data, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            // window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const updateCompany = async (body, id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, [
        'name',
        'email',
        'fax',
        'zipcode',
        'address',
        'date_of_establishment',
        'capital',
        'number_of_employees',
        'business_type',
        'phone',
        'business_content',
        'note',
        'is_cooperative_company',
        'province',
        'company_address',
        'person_in_charge',
        'person_in_charge_phone_number',
    ]);
    data.parent_company_id = body.parent_company_id?.value || '';
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .put(`${id}`, data, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const deleteCompany = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .delete(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const clientCompanyService = {
    getListCompanies,
    createCompany,
    updateCompany,
    deleteCompany,
    getCompany,
};

export default clientCompanyService;
