import { useState, useEffect } from 'react';
import './clientCompany.css';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/icons/Plus.png';
import { Form, Input, Rate, Table } from 'antd'; // Renamed to avoid conflict
import { useModal } from 'react-hooks-use-modal';
import CooperativeCompanyService from '../../services/cooperativeCompaniesService';
import { Box, FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CloseOutlined } from '@mui/icons-material';
import 'react-dropdown/style.css';
import { IPageQueries } from 'models';
import { LeftOutlinedCustom } from 'pages/invoices';
const { TextArea, Search } = Input;
import { RightOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { textTrim } from 'utils/helper/appHelper';
import CompanyService from 'services/companyService';

interface DetailReview {
    rate?: number;
    notes?: string;
    id?: number;
    cooperative_company_id?: number;
}
const PER_PAGE = 10;

const CooperativeCompany = (props) => {
    const navigate = useNavigate();
    const [formReview] = Form.useForm();

    const [companiesList, setCompanyList] = useState<any[]>([]);
    const [provincesOptions, setProvincesOptions] = useState<any[]>([]);
    const [provincesOption, setProvincesOption] = useState('');
    const [detailReview, setDetailReview] = useState<DetailReview>({
        rate: 0,
        notes: '',
        id: undefined,
        cooperative_company_id: undefined,
    });

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchedState, setSearchedState] = useState<string>('');
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 10,
    });

    const [companyDelete, setCompanyDelete] = useState<any>(null);

    const getListCompany = async ({
        page = 1,
        per_page = 5,
        free_word = '',
        province_name = '',
    }) => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies({
                    page,
                    per_page,
                    free_word,
                    province_name,
                });
            if (response !== null && response !== undefined) {
                const totalCount = response?.meta?.total_count;

                const totalInteger = Math.floor(totalCount / PER_PAGE);
                const totalSurplus = totalCount % PER_PAGE;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;

                setCompanyList(response?.cooperative_companies);
                setTotalCount(totalCount);

                setTotalPage(totalPageCustom);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const deleteCompany = async () => {
        try {
            const response =
                await CooperativeCompanyService.deleteCooperativeCompany(
                    companyDelete.id,
                );
            if (response !== null && response !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListCompany({
                    page: 1,
                    per_page: pageQueries.per_page,
                    free_word: searchedState,
                });
                setCompanyDelete(null);
                closeDelete();
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleSearch = () => {
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        const provinceFormat = provincesOptions?.find(
            (element) => element?.id === provincesOption,
        )?.label;   

        getListCompany({
            page: 1,
            per_page: pageQueries.per_page,
            free_word: searchedState,
            province_name: provinceFormat,
        });
    };

    useEffect(() => {
        handleSearch();
    }, [provincesOption, searchedState]);

    const handleReviewSubmission = async (valuesFormat, id) => {
        try {
            if (id) {
                await CooperativeCompanyService.updateCooperativeCompanyReview(
                    valuesFormat,
                );
            } else {
                await CooperativeCompanyService.createCooperativeCompanyReview(
                    valuesFormat,
                );
            }
            handleCloseReview();
            getListCompany({
                page: pageQueries.page,
                per_page: pageQueries.per_page,
            });
            return true;
        } catch (err) {
            const response = err?.response;
            if (!response || typeof response === 'undefined') {
                // window.location.href = '/login';
            }
            throw err;
        }
    };

    const onSubmitReview = (values) => {
        const valuesFormat = {
            stars: values.rate ?? '',
            body: values?.notes?.trim() ?? '',
            id: detailReview?.id ?? undefined,
            cooperative_company_id:
                detailReview?.cooperative_company_id ?? undefined,
        };
        return handleReviewSubmission(valuesFormat, detailReview?.id);
    };

    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    useEffect(() => {
        getListCompany({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete] = useModal('root', {
        preventScroll: true,
    });

    const [ModalReview, openReview, closeReview, isModalReview] = useModal(
        'root',
        {
            preventScroll: true,
        },
    );

    const columns: any = [
        {
            title: '会社名',
            width: 232,
            dataIndex: 'company_name',
            key: '1',
            render: (text, row) => <span>{row?.company?.name ?? ''}</span>,
        },
        {
            title: '電話番号',
            width: 140,
            dataIndex: 'phone',
            key: '2',
            render: (text, row) => <span>{row?.company?.phone ?? ''}</span>,
        },
        {
            title: '業種',
            dataIndex: 'business_type',
            key: '3',
            width: 149,
            render: (text, row) => (
                <span>{row?.company?.business_type ?? ''}</span>
            ),
        },
        {
            title: '施工エリア',
            dataIndex: 'construction_area',
            key: '10',
            width: 149,
            render: (text, row) => (
                <span>{row?.company?.business_type ?? ''}</span>
            ),
        },
        {
            title: 'メール',
            dataIndex: 'email',
            key: '4',
            width: 226,
            render: (text, row) => <span>{row?.company?.email ?? ''}</span>,
        },
        {
            title: '担当者名',
            dataIndex: 'person_in_charge',
            key: '5',
            width: 226,
            render: (text, row) => (
                <span>{row?.company?.person_in_charge ?? ''}</span>
            ),
        },
        {
            title: '担当者電話番号',
            dataIndex: 'person_in_charge_phone_number',
            key: '6',
            width: 226,
            render: (text, row) => (
                <span>{row?.company?.person_in_charge_phone_number ?? ''}</span>
            ),
        },
        {
            title: '住所',
            dataIndex: 'address',
            key: '7',
            width: 226,
            render: (text, row) => (
                <div>
                    <span>{row?.company?.province ?? ''}</span>&nbsp;
                    <span>{row?.company?.company_address ?? ''}</span>
                </div>
            ),
        },

        {
            title: '評価',
            dataIndex: 'rating',
            key: '8',
            width: 200,
            fixed: 'right',
            render: (text, row) => {
                const review = row?.review;

                return (
                    <div
                        key={row?.id}
                        className="flex flex-col cursor-pointer"
                        onClick={() => {
                            openReview();
                            setDetailReview({
                                rate: review?.stars,
                                notes: review?.body,
                                id: review?.id,
                                cooperative_company_id: row?.id,
                            });
                        }}
                    >
                        <Rate
                            disabled
                            value={review?.stars ? review?.stars : ''}
                            className="!text-[8px]"
                        />
                        <span
                            title={review?.body}
                            className="text-xs font-normal text-[#434343]"
                        >
                            {review?.body ? textTrim(review?.body, 20) : ''}
                        </span>
                    </div>
                );
            },
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: '9',
            width: '13%',
            fixed: 'right',
            render: (text, row) => (
                <div className="flex gap-2">
                    <button
                        className="button-tb !p-0 !rounded-[3px] !text-sm"
                        style={{ width: '48px' }}
                        onClick={() => {
                            navigate(`/cooperative-companies/detail/${row.id}`);
                        }}
                    >
                        詳細
                    </button>
                    <button
                        className="button-tb !p-0 !rounded-[3px] !text-sm"
                        style={{ width: '48px' }}
                        onClick={() => {
                            navigate(`/cooperative-companies/edit/${row.id}`);
                        }}
                    >
                        編集
                    </button>
                    <button
                        className="button-tb !p-0 !rounded-[3px] !text-sm"
                        style={{
                            width: '48px',
                            background: '#FF5045',
                        }}
                        onClick={() => {
                            openDelete();
                            setCompanyDelete(row);
                        }}
                    >
                        削除
                    </button>
                </div>
            ),
        },
    ];

    const getListProvinceName = async () => {
        try {
            const response = await CompanyService.getListProvinces();
            if (response !== null && response !== undefined) {
                const data = Object?.values(
                    response?.data as Record<string, any>,
                )?.reduce((acc: any, val: any) => acc.concat(val), []);

                const dataProvinces = data.map((element, index) => {
                    return {
                        ...element,
                        label: element?.province,
                        value: element?.id,
                    };
                });

                setProvincesOptions(dataProvinces);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleChangeProvince = (event) => {
        const {
            target: { value },
        } = event;
            
        setProvincesOption(value);
    };

    const handleCloseReview = () => {
        formReview.resetFields();
        closeReview();
    };

    useEffect(() => {
        formReview.setFieldsValue({
            rate: detailReview.rate,
            notes: detailReview.notes,
        });
    }, [detailReview]);

    useEffect(() => {
        !isModalReview && formReview.resetFields();
    }, [isModalReview]);

    useEffect(() => {
        getListCompany({
            page: page,
            per_page: pageQueries.per_page,
        });
    }, [page]);

    useEffect(() => {
        getListProvinceName();
    }, []);

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <div className="flex justify-between items-center">
                    <div className="search-client-company-button d-flex flex-row !h-fit items-center">
                        <Box
                            sx={{
                                width: '100%',
                                '.input-search': {
                                    border: '1px solid #cdd1d5',
                                    borderRadius: '8px',
                                    marginBottom: '14.5px',
                                    paddingLeft: '14px',
                                },
                                '.MuiTextField-root': {
                                    border: '1px solid #cdd1d5',
                                    width: '100%',
                                    borderRadius: '8px',
                                    marginRight: '12.5px',
                                },
                                '.MuiInputBase-input': {
                                    height: '35px',
                                    padding: '0px !important',
                                },
                            }}
                            style={{ width: '313px' }}
                        >
                            <Search
                                placeholder="検索"
                                onSearch={(e) => {
                                    setSearchedState(e?.trim());
                                    handleSearch();
                                }}
                                style={{ width: 311, height: 32 }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                ' .MuiSelect-select': {
                                    border: '1px solid #cdd1d5',
                                    borderRadius: '2px',
                                },
                                '.MuiInputBase-input': {
                                    padding: '3.5px !important',
                                },
                                '.MuiSelect-root': {
                                    height: '32px !important',
                                },
                            }}
                            className="d-flex flex-column"
                            style={{ width: '292px', height: '32px' }}
                        >
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={provincesOption}
                                onChange={(data) => {
                                    handleChangeProvince(data);
                                }}
                                displayEmpty
                                renderValue={(selected: any) => {
                                    if (selected.length === 0) {
                                        return (
                                            <em
                                                style={{
                                                    color: '#cdd1d5',
                                                }}
                                            >
                                                都道府県で検索
                                            </em>
                                        );
                                    } else {
                                        const selectedFormat =
                                            provincesOptions?.find(
                                                (item) => item?.id === selected,
                                            )?.label;
                                        return selectedFormat;
                                    }
                                }}
                            >
                                {provincesOptions?.map((element, index) => (
                                    <MenuItem key={index} value={element.value}>
                                        {element.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </div>

                    <div className="create-button d-flex flex-row">
                        <button
                            className="create-user-button d-flex flex-row !border-[#215493]"
                            onClick={() =>
                                navigate('/cooperative-companies/create')
                            }
                        >
                            <img
                                width={'16px'}
                                height={'16px'}
                                src={plus}
                            ></img>
                            新規作成
                        </button>
                    </div>
                </div>
                <div
                    className="table-workers table-company d-flex flex-column mb-3"
                    style={{ marginTop: '10px' }}
                >
                    <div className="table-content d-flex flex-column px-4">
                        <Table
                            columns={columns}
                            dataSource={companiesList}
                            scroll={{
                                x:
                                    companiesList?.length > 0
                                        ? 'max-content'
                                        : undefined,
                            }}
                            pagination={false}
                            className="mt-3 table-company"
                            locale={{ emptyText: 'データがありません。' }}
                        />

                        <ModalDelete>
                            <div
                                className="modal-create-user d-flex flex-column"
                                style={{ height: '152px' }}
                            >
                                <div className="modal-create-user-title d-flex flex-row">
                                    本当に削除しますか?
                                </div>
                                <div
                                    className="modal-create-user-buttons d-flex flex-row"
                                    style={{ marginTop: '50px' }}
                                >
                                    <button
                                        className="modal-create-user-button1 d-flex flex-row"
                                        onClick={closeDelete}
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        onClick={deleteCompany}
                                        className="modal-create-user-button2 d-flex flex-row"
                                    >
                                        はい
                                    </button>
                                </div>
                            </div>
                        </ModalDelete>
                        <ModalReview>
                            <div
                                className="modal-review-company d-flex flex-column"
                                style={{ height: '152px' }}
                            >
                                <div className="modal-create-user-title d-flex flex-row !text-base">
                                    <div className="w-[60%] text-right">
                                        評価する
                                    </div>
                                    <div className="w-[40%] text-right">
                                        <CloseOutlined
                                            className="cursor-pointer"
                                            onClick={handleCloseReview}
                                        />
                                    </div>
                                </div>
                                <Form
                                    form={formReview}
                                    onFinish={onSubmitReview}
                                >
                                    <div>
                                        <Form.Item name="rate">
                                            <Rate className="!text-[32px]" />
                                        </Form.Item>
                                        <div className="mt-3">
                                            <div className="pb-2">評価内容</div>
                                            <Form.Item name="notes">
                                                <TextArea
                                                    showCount
                                                    maxLength={5000}
                                                    style={{
                                                        height: 101,
                                                        resize: 'none',
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="modal-create-user-buttons d-flex flex-row">
                                        <button
                                            className="modal-create-user-button1 d-flex flex-row !rounded-[3px] !border-[#e4e4e4]"
                                            onClick={handleCloseReview}
                                        >
                                            いいえ
                                        </button>
                                        <button
                                            type="submit"
                                            className="modal-create-user-button2 d-flex flex-row !rounded-[3px]"
                                        >
                                            評価する
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </ModalReview>
                    </div>
                </div>
                {companiesList && companiesList.length > 0 && (
                    <>
                        <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                            <p className="font-normal text-[14px] text-[#666666]">
                                ページ {page} / {totalPage}
                            </p>
                            <div className="flex gap-[25px]">
                                <LeftOutlinedCustom
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black]':
                                                statusPrev && page !== 1,
                                            '!text-[#D3D3D3]': page === 1,
                                        },
                                    )}
                                    onClick={setPagePrev}
                                    disabled
                                />

                                <RightOutlined
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black] inline-flex':
                                                statusNext &&
                                                page !== totalPage,
                                            '!text-[#D3D3D3]':
                                                page === totalPage,
                                        },
                                    )}
                                    onClick={setPageNext}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CooperativeCompany;
