import styled from 'styled-components';

export const QuoteListStyles = styled.div`
    .ant-select-selection-item {
    padding-top: 5px !important;
    }

    .ant-select-selection-placeholder {
    padding-top: 5px !important;
    }
`;
