export const EDIT_SUGOROKU_MODE = 'edit';
export const VIEW_SUGOROKU_MODE = 'view';

// export const ListItems = [
//     {
//       icon: '/img/topSP/icon-todo.svg',
//       name: 'TODO<br/>リスト',
//       route: '/sp/todo',
//     },
//     {
//       icon: '/img/topSP/icon-calendar.svg',
//       name: 'カレンダー',
//       route: '/sp/calendar',
//     },
//     {
//         icon: '/img/topSP/icon-project.svg',
//         name: '案件一覧',
//         route: '/sp/projects',
//     },
//     {
//         icon: '/img/topSP/icon-chat.svg',
//         name: '社内<br/>メッセージ',
//         route: '/sp/chats',
//     },
//     {
//         icon: '/img/topSP/icon-job.svg',
//         name: '契約外作業<br/>確認書',
//         route: '/sp/jobs',
//     },
//     {
//         icon: '/img/topSP/icon-report.svg',
//         name: '日報',
//         route: '/sp/projects_list',
//     },
//     {
//         icon: '/img/topSP/icon-target.svg',
//         name: '目標',
//         route: '/sp/target',
//     },
//     {
//         icon: '/img/topSP/icon-car.svg',
//         name: '車両点<br/>検簿',
//         route: '/sp/inspection-records',
//     },
//     {
//         icon: '/img/topSP/icon-alarm.svg',
//         name: '出退勤',
//         route: '/sp/timekeeping',
//     },
//     {
//       icon: '/img/topSP/icon-stocknote.svg',
//       name: '納品書一覧',
//       route: '/sp/stock_notes',
//   },
//   {
//     icon: '/img/topSP/icon-pr.svg',
//     name: '経費要望',
//     route: '/sp/purchase-request',
//   },
//   {
//     icon: '/img/topSP/icon-ojt.svg',
//     name: '各種<br/>マニュアル',
//     route: '/sp/internal-ojt',
//   },
//   {
//     icon: '/img/topSP/icon-todo.svg',
//     name: '見積管理',
//     route: '/sp/quote/list',
//   },
// ];

export const ListItems = [
    {
        icon: '/img/topSP/icon-alarm.svg',
        name: '出退勤',
        route: '/sp/timekeeping',
    },
];

export const MONTHS_IN_YEAR = [
    {
        id: '1',
        name: '1月',
    },
    {
        id: '2',
        name: '2月',
    },
    {
        id: '3',
        name: '3月',
    },
    {
        id: '4',
        name: '4月',
    },
    {
        id: '5',
        name: '5月',
    },
    {
        id: '6',
        name: '6月',
    },
    {
        id: '7',
        name: '7月',
    },
    {
        id: '8',
        name: '8月',
    },
    {
        id: '9',
        name: '9月',
    },
    {
        id: '10',
        name: '10月',
    },
    {
        id: '11',
        name: '11月',
    },
    {
        id: '12',
        name: '12月',
    },
];

export const WEEKS_IN_MONTH = [
    {
        id: '1',
        name: '第1週',
    },
    {
        id: '2',
        name: '第2週',
    },
    {
        id: '3',
        name: '第3週',
    },
    {
        id: '4',
        name: '第4週',
    },
    {
        id: '5',
        name: '第5週',
    },
];

export const STATUS_PERFORM = [
    {
        label: '新規',
        value: 'new_implement',
    },
    {
        label: '作業中',
        value: 'in_progress',
    },
    {
        label: '完了',
        value: 'done',
    },
];

export const STATUS_INVOICE = [
    {
        label: '未決済',
        value: 'unpaid',
        color: '#feab35',
        bg: '#fff7e6',
    },
    {
        label: '決済済み',
        value: 'paid',
        color: '#05d16d',
        bg: '#f6ffed',
    },
    {
        label: '請求書未作成',
        value: 'invoice_not_created',
        color: '#ff0000',
        bg: '#ffe5e5',
    },
];

export const LIST_BILL_SP = [
    {
        invoice_name: '内装工事',
        created_at: '2024/11/30',
        status: 'unpaid',
        id: 1,
    },
    {
        invoice_name: '内装工事',
        created_at: '2024/11/30',
        status: 'paid',
        id: 2,
    },
    {
        invoice_name: '外装工事',
        created_at: '2024/12/01',
        status: 'unpaid',
        id: 3,
    },
    {
        invoice_name: '電気工事',
        created_at: '2024/12/02',
        status: 'unpaid',
        id: 4,
    },
    {
        invoice_name: '水道工事',
        created_at: '2024/12/03',
        status: 'unpaid',
        id: 5,
    },
    {
        invoice_name: 'ガス工事',
        created_at: '2024/12/04',
        status: 'unpaid',
        id: 6,
    },
    {
        invoice_name: '塗装工事',
        created_at: '2024/12/05',
        status: 'unpaid',
        id: 7,
    },
    {
        invoice_name: '防水工事',
        created_at: '2024/12/06',
        status: 'unpaid',
        id: 8,
    },
    {
        invoice_name: '解体工事',
        created_at: '2024/12/07',
        status: 'unpaid',
        id: 9,
    },
];

export const LIST_TAB_PROJECT = [
    {
        label: '見積作成',
        value: 0,
    },
    {
        label: '受注',
        value: 1,
    },
    {
        label: '工事',
        value: 2,
    },
    {
        label: '請求書作成',
        value: 3,
    },
];

export const FILE_LIST_PDF = [
    {
        id: 1,
        file_name: '請求書-4 (1).pdf',
        size: '37.3 KB',
        file_path:
            'https://api-conaffetto-dev.codeaplha.biz/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdGtoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d895cee1abd39c995396c7af6c1664c81196a622/%E7%B4%8D%E5%93%81%E4%BC%9D%E7%A5%A8.pdf',
    },
    {
        id: 2,
        file_name: '請求書-4 (2).pdf',
        size: '37.4 KB',

        file_path:
            'https://api-conaffetto-dev.codeaplha.biz/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdG9oIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--967c50ae2235c439ac356afe9e968c4f2354f558/%E8%AB%8B%E6%B1%82%E6%9B%B8%20(1).pdf',
    },
    {
        id: 3,
        file_name: '請求書-4 (3).pdf',
        size: '37.5 KB',

        file_path:
            'https://api-conaffetto-dev.codeaplha.biz/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHNoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f49b7998a08ae6a87acc015d636949f50144aa55/%E8%AB%8B%E6%B1%82%E6%9B%B8-4%20(1).pdf',
    },
    {
        id: 4,
        file_name: '請求書-4 (4).pdf',
        size: '37.6 KB',
        file_path:
            'https://api-conaffetto-dev.codeaplha.biz/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9cbb8b9f97d6ae919ffc10c6c18a1660dff998ae/%E8%AB%8B%E6%B1%82%E6%9B%B8-4.pdf',
    },
];

export const BILL_ITEM = [
    {
        id: 41,
        name: 'test 5',
        quantity: 435,
        unit: '式',
        unit_price: '34543.0',
        amount: '15026205.0',
        total_price: '16528826.0',
    },
    {
        id: 42,
        name: 'test 6',
        quantity: 345,
        unit: '式',
        unit_price: '34534.0',
        amount: '11914230.0',
        total_price: '13105653.0',
    },
    {
        id: 43,
        name: 'test 7',
        quantity: 345,
        unit: '式',
        unit_price: '34534.0',
        amount: '11914230.0',
        total_price: '13105653.0',
    },
];
