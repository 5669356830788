import { Table, TableColumnsType } from 'antd';
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { formatMoney } from 'utils/helper/helpers';
import { STATUS_INVOICE } from 'utils/constants';
import { WasteSugorokuIndexEnum2 } from 'utils/enums';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IconRemove from 'assets/icons/ic-bg-remove.png';
import { StyledModalInvoice } from 'pages/project/projectPlan/Invoices/ModalCUDInvoice/styled';
import invoiceService from 'services/invoiceService';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
export interface DataTypeProject {
    key: React.Key;
    project_name: string;
    property_name?: string;
    status_of_project: string;
    status?: string;
    project_id: number;
    id?: number;
    bills?: [];
}
interface ExpandedDataType {
    key: React.Key;
    invoice_id?: number | undefined;
    number?: number;
    invoice_name?: string;
    created_at?: string;
    total_amount?: string;
    status_of_invoice?: string;
}

export interface billItem {
    id?: number | undefined;
    name: string;
    total_price: string;
    status: string;
}
interface TableInvoicesProps {
    billOfProjects: DataTypeProject[];
    fetchBillOfProjects: any;
}

const columns: TableColumnsType<DataTypeProject> = [
    {
        title: <div className="!text-left font-bold">案件名</div>,
        dataIndex: 'project_name',
        key: 'project_name',
        width: '55%',
        render: (text, row, index) => {
            return {
                children: (
                    <div className={'line-clamp-1 break-all  text-left'}>
                        {row?.project_name}
                    </div>
                ),
            };
        },
    },
    {
        title: <div className="!text-center font-bold">状態</div>,
        dataIndex: 'status_of_project',
        key: 'status_of_project',
        width: '45%',

        render: (text, row, index) => {
            return {
                children: (
                    <div
                        className="text-overflow1 w-[100px]"
                        style={{
                            whiteSpace: 'nowrap',
                            color: WasteSugorokuIndexEnum2[
                                row?.status_of_project
                            ].textColor,
                            backgroundColor:
                                WasteSugorokuIndexEnum2[row?.status_of_project]
                                    .bgColor,
                            borderRadius: '15px',
                            padding: '4px 9px',
                        }}
                    >
                        {WasteSugorokuIndexEnum2[row?.status_of_project].title}
                    </div>
                ),
                props: {
                    className: 'text-center',
                },
            };
        },
    },
];


const TableInvoices: React.FC<TableInvoicesProps> = ({ billOfProjects,fetchBillOfProjects }) => {
    const navigate = useNavigate();

    const [isOpenDeleteInvoice, setIsOpenDeleteInvoice] = useState(false);

    const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Default expanded row key

    const [currentRecord, setCurrentRecord] = useState({ id: undefined });

    const showOpenDeleteInvoice = () => {
        setIsOpenDeleteInvoice(true);
    };

    const handleCloseDeleteInvoice = () => {
        setIsOpenDeleteInvoice(false);
    };

    const onExpand = (expanded: any, record: any) => {
        setExpandedRowKeys((prevExpandedRowKeys: any) => {
            if (expanded) {
                return [...prevExpandedRowKeys, record.id];
            } else {
                return prevExpandedRowKeys.filter((key) => key !== record.id);
            }
        });
    };

    const expandColumns: TableColumnsType<ExpandedDataType> = [
        {
            title: '番号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            width: '10%',
            render: (text, record) => (
                <>
                    <div>{record?.number}</div>
                </>
            ),
        },
        {
            title: '請求書名',
            dataIndex: 'invoice_name',
            key: 'invoice_name',
            align: 'center',
            render: (text: any, record: any) => (
                <>
                    <div className="break-all">
                        {record?.invoice_name ?? ''}
                    </div>
                </>
            ),
            width: '25%',
        },
        {
            title: '作成日',
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div className="break-all">
                        {record?.created_at
                            ? dayjs(record?.created_at).format(
                                  FORMAT.FORMAT_DATE_UPPER_JAPAN,
                              )
                            : ''}
                    </div>
                </>
            ),
            width: '15%',
        },
        {
            title: '合計金額',
            dataIndex: 'total_amount',
            key: 'total_amount',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div className="break-all">
                        {record?.total_amount
                            ? '¥' +
                              Number(
                                  formatMoney(
                                      String(record?.total_amount || 0),
                                  ).replace(/,/g, ''),
                              ).toLocaleString('en')
                            : ''}
                    </div>
                </>
            ),
            width: '15%',
        },
        {
            title: '状態',
            key: 'status_of_invoice',
            dataIndex: 'status_of_invoice',
            align: 'center',
            render: (text: any, record: any) => {
                const status_of_invoice = STATUS_INVOICE?.find(
                    (item) => item.value === record?.status_of_invoice,
                );
                return (
                    <>
                        {status_of_invoice ? (
                            <div className="w-full flex justify-center break-all">
                                <div
                                    className="w-[120px] p-3 flex items-center justify-center rounded-2xl h-7"
                                    style={{
                                        color: status_of_invoice?.color,
                                        backgroundColor: status_of_invoice?.bg,
                                    }}
                                >
                                    {status_of_invoice?.label}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                );
            },
            width: '25%',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record: any) => {
                const isDisableField = record?.status_of_invoice === 'paid';
                return (
                    <div className="flex break-all gap-2">
                        <button
                            className={clsx(
                                'button-tb !p-0 !rounded-[3px] !text-sm',
                                {
                                    'cursor-no-drop': isDisableField,
                                },
                            )}
                            style={{ width: '48px' }}
                            onClick={() => {
                                navigate(
                                    `/quote/list/invoice/${
                                        record.project_id
                                    }?invoice_id=${
                                        record?.invoice_id && record?.invoice_id
                                    }`,
                                    { state: { type: 'detail' } },
                                );
                            }}
                        >
                            詳細
                        </button>
                        <button
                            className={clsx(
                                'button-tb !p-0 !rounded-[3px] !text-sm',
                                {
                                    'cursor-no-drop': isDisableField,
                                },
                            )}
                            style={{ width: '48px' }}
                            onClick={() => {
                                navigate(
                                    `/quote/list/invoice/${
                                        record.project_id
                                    }?invoice_id=${
                                        record?.invoice_id && record?.invoice_id
                                    }`,
                                    { state: { type: 'edit' } },
                                );
                            }}
                            disabled={isDisableField}
                        >
                            編集
                        </button>
                        <button
                            className={clsx(
                                'button-tb !p-0 !rounded-[3px] !text-sm',
                                {
                                    'cursor-no-drop': isDisableField,
                                },
                            )}
                            style={{
                                width: '48px',
                                background: '#FF5045',
                            }}
                            onClick={() => {
                                setCurrentRecord(record);
                                showOpenDeleteInvoice();
                            }}
                            disabled={isDisableField}
                        >
                            削除
                        </button>
                    </div>
                );
            },
            width: '10%',
            align: 'center',
        },
    ];

    const expandedRowRender = (record: DataTypeProject) => {
        return (
            <Table<ExpandedDataType>
                columns={expandColumns}
                dataSource={record?.bills}
                pagination={false}
            />
        );
    };
    const expandIcon = ({ expanded, onExpand, record }) => {
        let buttonText = expanded ? (
            <UpSquareOutlined className="!text-[#1890ff] !text-[20px]" />
        ) : (
            <DownSquareOutlined className="!text-[#1890ff] !text-[20px]" />
        );
        return (
            <div
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
            >
                {buttonText}
            </div>
        );
    };

    const handleDeleteInvoice = async (invoice_id?: number) => {
        try {
            const data = await invoiceService.deleteInvoice(invoice_id);
            if (data) {
                alertSuccessPopup({
                    title: '請求書が削除されました。',
                    confirmButtonText: '閉じる',
                });
                handleCloseDeleteInvoice();
                fetchBillOfProjects();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    return (
        <>
            <div className={clsx(styles.tableCustomize)}>
                <Table<DataTypeProject>
                    columns={columns}
                    expandable={{
                        expandedRowRender,
                        childrenColumnName: '!p-0',
                        expandedRowKeys: expandedRowKeys,
                        onExpand: (expanded, record) =>
                            onExpand(expanded, record),
                    }}
                    dataSource={billOfProjects}
                    pagination={false}
                    expandIcon={expandIcon}
                />
            </div>
            <StyledModalInvoice
                className="!p-0"
                open={isOpenDeleteInvoice}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleCloseDeleteInvoice}
                centered
                width={462}
            >
                <div
                    className="modal-create-user !w-full d-flex flex-column"
                    style={{ height: '243px' }}
                >
                    <img src={IconRemove} />
                    <div className="modal-create-user-title d-flex flex-row mt-4 font-semibold text-2xl">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row !border-[#e4e4e4] !rounded-[3px] !h-9"
                            onClick={handleCloseDeleteInvoice}
                            type="button"
                        >
                            いいえ
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row !bg-[#d83535] !rounded-[3px] !h-9"
                            type="button"
                            onClick={() => {
                                handleDeleteInvoice(currentRecord?.id);
                            }}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </StyledModalInvoice>
        </>
    );
};
export default TableInvoices;
