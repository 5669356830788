import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
    Upload,
    message,
    Modal,
    DatePicker,
    ConfigProvider,
    Spin,
    Select,
} from 'antd';
import useSPRepairForm, { ReceivedProps, Props } from './hook';
import { SPQuoteFormStyles } from './styled';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import './style.scss';
import jaJP from 'antd/es/locale/ja_JP';
import { useTranslation } from 'react-i18next';
import repairValidation from 'utils/validation/registers/repair';
import { MAX_LENGTH } from 'pages/project/projectPlan/projectTabs/constant';
import { FORMAT } from 'utils/constants/format-date-time';

const UploadCustom = styled(Upload)<{ $isFull?: boolean }>`
    .ant-upload-list-picture-card {
        width: 332px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;

const SPRepairFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    dummyRequest,
    register,
    screenType,
    isLoading,
    errors,
    setValue,
    listFile,
    setListFile,
    fileLength,
    setFileLength,
    isExcessiveUpload,
    setIsExcessiveUpload,
    clearErrors,
    navigateBackToDetail,
    handleValidateWhitespace,
    totalImageSize,
    isAllowUpload,
    setAllowUpload,
    setTotalImageSize,
    listCompany,
}) => {
    const { RangePicker } = DatePicker;
    const UPLOAD_LIMIT = 100;
    const { t } = useTranslation();
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    useEffect(() => {
        if (sizeUploadLimitError) {
            message.error(
                `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
            );
            setSizeUploadLimitError(false);
        }
    }, [sizeUploadLimitError]);

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 100 - fileLength;
        const filteredPdfAfterUid = info?.fileList.filter((bItem) =>
            listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterReMove: any =
            loadFile < filteredPdfAfterRedundant?.length &&
            filteredPdfAfterRedundant?.slice(0, loadFile > 1 ? loadFile : 1);

        if (info.file.status === 'done') {
            setTotalImageSize((prevSize) => {
                return prevSize + (info.file.size as number);
            });
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile > 0) {
            if (filteredPdfAfterReMove?.length > 0) {
                setListFile(
                    filteredPdfAfterUid?.concat(filteredPdfAfterReMove),
                );
                setFileLength(
                    filteredPdfAfterUid?.length +
                        filteredPdfAfterReMove?.length,
                );
                setAllowUpload(
                    filteredPdfAfterUid?.length +
                        filteredPdfAfterReMove?.length <
                        100,
                );
                setValue('images', filteredPdfAfterUid);
            } else {
                setAllowUpload(info.fileList.length < 100);
                setValue('images', info.fileList);
                setFileLength(info?.fileList?.length);
                setListFile(info?.fileList);
            }
        } else {
            setAllowUpload(info.fileList.length < 100);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        }
        clearErrors('images');
    };
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const id = useParams();
    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const availableSlots = 100 - fileLength;
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        if (fileList.length > availableSlots) {
            setIsExcessiveUpload(true);
            return Upload.LIST_IGNORE;
        }
        const accumulatedImageSize = totalImageSize + uploadingSizes;

        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;

        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const getBase64 = (file: any): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
        );
    };

    const uploadButton = (
        <div className="text-[#5392F0] flex flex-col items-center pr-[5px]">
            <img
                src={uploadIcon}
                className="w-[21px] h-[21px]"
                alt="upload image icon"
            />
            <div>写真 ({listFile?.length > 0 ? listFile?.length : 0}/100)</div>
        </div>
    );

    useEffect(() => {
        if (isExcessiveUpload) {
            message.error('ファイルアップロードは100ファイルまでです。');
            setIsExcessiveUpload(false);
        }
    }, [isExcessiveUpload]);

    return (
        <>
            <ConfigProvider locale={jaJP}>
                {isLoading && (
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            top: 0,
                            left: 0,
                            zIndex: 1000,
                        }}
                        className="position-fixed d-flex align-items-center justify-content-center"
                    >
                        <Spin />
                    </div>
                )}
                <SPQuoteFormStyles>
                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="w-full flex flex-col gap-y-[14px]">
                            <div className="flex flex-col w-full">
                                <div className="flex items-center">
                                    <div className="mr-4">写真アップロード</div>
                                    <img
                                        src={warningIcon}
                                        alt="warning circle icon"
                                    />
                                    <div className="text-[#808080]">
                                        1:1 スケール画像
                                    </div>
                                </div>
                                <div>
                                    {isMac ? (
                                        <UploadCustom
                                            locale={{
                                                uploading: 'アップロード中',
                                            }}
                                            listType="picture-card"
                                            customRequest={dummyRequest}
                                            {...register('images', {
                                                required:
                                                    '画像は最低1枚以上アップロードしてください。',
                                            })}
                                            onChange={handleChangeFile}
                                            beforeUpload={handleBeforeUpload}
                                            fileList={listFile}
                                            onPreview={handlePreview}
                                            multiple
                                            accept=".jpg, .jpeg, .png, .gif"
                                            $isFull={
                                                typeof fileLength ===
                                                    'number' && fileLength >= 10
                                            }
                                        >
                                            {isAllowUpload && uploadButton}
                                        </UploadCustom>
                                    ) : (
                                        <UploadCustom
                                            locale={{
                                                uploading: 'アップロード中',
                                            }}
                                            listType="picture-card"
                                            customRequest={dummyRequest}
                                            {...register('images', {
                                                required:
                                                    '画像は最低1枚以上アップロードしてください。',
                                            })}
                                            onChange={handleChangeFile}
                                            beforeUpload={handleBeforeUpload}
                                            fileList={listFile}
                                            onPreview={handlePreview}
                                            multiple
                                            // accept=".jpg, .jpeg, .png, .gif"
                                            $isFull={
                                                typeof fileLength ===
                                                    'number' && fileLength >= 10
                                            }
                                        >
                                            {isAllowUpload && uploadButton}
                                        </UploadCustom>
                                    )}
                                </div>

                                <Modal
                                    open={previewOpen}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                >
                                    <img
                                        alt="example"
                                        style={{ width: '100%' }}
                                        src={previewImage}
                                    />
                                </Modal>

                                {errors.images && (
                                    <span className="error !text-[14px]">
                                        {errors.images.message?.toString()}
                                    </span>
                                )}
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="person_in_charge"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                担当者名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'person_in_charge',
                                                    repairValidation().person_in_charge(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.person_in_charge && (
                                                <span className="error !text-[14px]">
                                                    {errors.person_in_charge.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="company_name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                法人名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'company_name',
                                                    repairValidation().company_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.company_name && (
                                                <span className="error !text-[14px]">
                                                    {errors.company_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="property_name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                物件名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'property_name',
                                                    repairValidation().property_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.property_name && (
                                                <span className="error !text-[14px]">
                                                    {errors.property_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <Controller
                                    name="construction_site"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                施工場所住所
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'construction_site',
                                                    repairValidation().construction_site(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.construction_site && (
                                                <span className="error !text-[14px]">
                                                    {errors.construction_site.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <Controller
                                    name="desired_date"
                                    control={control}
                                    rules={repairValidation().desired_date()}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                希望工事期間
                                            </p>
                                            <RangePicker
                                                placeholder={[
                                                    '開始日',
                                                    '終了日',
                                                ]}
                                                className="!h-10 w-full"
                                                format={
                                                    FORMAT.FORMAT_DATE_UPPER
                                                }
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.desired_date && (
                                                <span className="error !text-[14px]">
                                                    {errors.desired_date.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="content"
                                    rules={{
                                        required: true,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                修繕内容
                                            </p>
                                            <textarea
                                                autoComplete="off"
                                                className="border-[1px] border-solid border-[#cdd1d5]"
                                                rows={4}
                                                {...register(
                                                    'content',
                                                    repairValidation().content(),
                                                )}
                                                {...field}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            />
                                            {errors.content && (
                                                <span className="error !text-[14px]">
                                                    {errors.content.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div
                            className="d-flex flex-row"
                            style={{
                                marginTop: '14px',
                                justifyContent: 'center',
                            }}
                        >
                            {screenType === 'edit' && (
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    style={{ width: '167.5px' }}
                                    type="button"
                                    onClick={navigateBackToDetail}
                                >
                                    キャンセル
                                </button>
                            )}
                            <button
                                className="modal-create-user-button2 d-flex flex-row"
                                style={{
                                    marginLeft: '10px',
                                    width: '167.5px',
                                }}
                                type="submit"
                                disabled={isLoading}
                            >
                                送信
                            </button>
                        </div>
                    </form>
                </SPQuoteFormStyles>
            </ConfigProvider>
        </>
    );
};
const SPRepairForm: FC<ReceivedProps> = (props) => {
    return <SPRepairFormLayout {...useSPRepairForm(props)} />;
};
export default SPRepairForm;
