import { useState, useEffect, useRef, FocusEventHandler } from 'react';
import {
    useNavigate,
    useLocation,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import projectsService from '../../../services/projectsService';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';
import userService from 'services/userService';
import CompanyService from '../../../services/clientCompanyService';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { Button, Box } from '@mui/material';
import styled from 'styled-components';
import ja from 'date-fns/locale/ja';
import createProjectValidation from 'utils/validation/registers/projects/createProjectValidation';
import { useTranslation } from 'react-i18next';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { handleFilter } from 'utils/helper/filterDropdown';
import BlackPlus from 'assets/icons/black-plus.png';
import workersService from 'services/wokersService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import {
    convertErrorMessagesToObject,
    validateFullWhitespace,
} from 'utils/validation/validatorHelper';
import isEmpty from 'lodash/isEmpty';
import { BusinessTypeEnum } from 'utils/enums';
import { getFilePreview } from 'utils/helper/file';
import moment from 'moment';
import { ProjectCreateStyles } from './styled';
import { RequireSign } from 'components/common';
import { DatePicker, message, Select } from 'antd';
import Upload, {
    RcFile,
    UploadChangeParam,
    UploadFile,
    UploadProps,
} from 'antd/lib/upload';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import projectApi from 'services/projectApi';
import ProgressStepCreate from './progressStepCreate';
import { TYPE_OF_SCREEN } from '../projectPlan/projectTabs/constant';
import quoteApi from 'services/quoteApi';
import { determineStatus } from '../projectPlan';

export const noteOptions = [
    {
        label: '公共',
        value: 'entity_public',
    },
    {
        label: '法人',
        value: 'corporation',
    },
    {
        label: '個人',
        value: 'individual',
    },
];

export type ReceivedProps = {
    screenType: 'create' | 'edit' | 'detail';
};

const ProjectCreate = (props: ReceivedProps) => {
    const { screenType } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();
    const wordLimit = 5000;
    const [isDisable, setDisable] = useState(false);

    const [imageFile, setImageFile] = useState<any>(null);
    // Configurations
    const [statuses, setStatuses] = useState<any>([]);
    const [isExcessiveUpload, setIsExcessiveUpload] = useState<boolean>(false);
    const [listCompanies, setListCompanies] = useState<any>([]);
    const [listCooperatives, setListCooperatives] = useState<any>([]);
    const [listWorkers, setListWorkers] = useState<any>([]);
    const [showCooperativeField, setShowCooperativeField] =
        useState<boolean>(true);
    const [projectStatus, setProjectStatus]: any = useState('');
    const [fileLength, setFileLength] = useState<number>(0);
    const [indexError, setIndexError] = useState(0);
    const [totalImageSize, setTotalImageSize] = useState<number>(0);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    const [listFile, setListFile]: any = useState([]);
    const [isAllowUpload, setAllowUpload] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const project_id = searchParams.get('project_id'); // determine wether that we can edit or view-only
    const handleUploadFile = (files) => {
        if (!files || files.length < 0) return;

        const fileObject = {
            file: files[0],
            path: getFilePreview(files[0]),
        };

        setImageFile(fileObject);
    };
    const UPLOAD_LIMIT = 100;

    const uploadButton = (
        <div className="text-[#000000D9] flex flex-col items-center">
            <img
                src={BlackPlus}
                className="w-[14px] h-[14px]"
                alt="upload image icon"
            />
            <div>ファイルを選択</div>
        </div>
    );

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        const availableSlots = 100 - fileLength;
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        if (fileList.length > availableSlots) {
            setIsExcessiveUpload(true);
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10;
        setIndexError(0);
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        } else {
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };

    const getDetail = async () => {
        setIsLoading(true);
        try {
            const res = await projectApi.getProjectDetail(
                user?.access_token,
                project_id,
            );
            if (res) {
                const data = res.data.project;
                setValue('entity', data?.entity);
                setValue('content', data?.content);
                setValue('company_name', data?.corporate_name);
                setValue('content', data?.content);
                setValue('construction_site', data?.construction_site);
                setValue('address', data?.address);
                setValue('person_in_charge', data?.person_in_charge);
                setValue('property_name', data?.property_name);
                const isAllPaid =
                    data?.bills?.length > 0
                        ? data?.bills?.every(
                              (item: any) => item.status === 'paid',
                          )
                        : false;

                const statusFormat = determineStatus(data, isAllPaid);
                const project_format = {
                    ...res?.data?.project,
                    status: statusFormat,
                };
                setProjectStatus(statusFormat);
                const fileArr: any = [];
                let imageSize = 0;
                if (data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                                imageSize += myFile.size;
                            });
                    }
                }
                setValue('images', fileArr);
                setTotalImageSize((prev) => prev + imageSize);
                setListFile(fileArr);
                setFileLength(fileArr.length);
                setAllowUpload(data?.images?.length < 10);
            }
        } catch (error) {
            //
        } finally {
            setIsLoading(false);
        }
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };

    const dummyRequest: UploadProps['customRequest'] = async ({
        file,
        onSuccess,
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };

    const getDetailRepair = async () => {
        try {
            const res = await quoteApi.getSPQuoteDetail(user?.access_token, id);
            if (res) {
                const data = res.data.quote_repair || res.data.quote;
                setValue('company_name', data?.company_name);
                setValue('content', data?.content);
                setValue('construction_site', data?.construction_site);
                setValue('address', data?.address);
                setValue('person_in_charge', data?.person_in_charge);
                setValue('property_name', data?.property_name);
                setAllowUpload(data.images.length < 100);
                const fileArr: any = [];
                const pdfArr: any = [];
                let imageSize = 0;
                if (data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                                imageSize += myFile.size;
                            });
                    }
                }
                setValue('images', fileArr);
                setTotalImageSize((prev) => prev + imageSize);
                setListFile(fileArr);
                setFileLength(fileArr.length);
                data?.images?.length > 0 &&
                    setAllowUpload(data?.images?.length < 100);
            }
        } catch (error) {
            //
        }
    };

    const submitData = async (value: any, evt: any) => {
        // evt.preventDefault()
        const isAllLE100MB = totalImageSize < 1024 * 1024 * 100;

        evt.preventDefault();
        setIsLoading(true);
        let formData = new FormData();

        formData.append('person_in_charge', value?.person_in_charge);
        formData.append('address', value?.address);
        formData.append('content', value?.content);
        for (let i = 0; i < value?.images.length; i++) {
            formData.append('images[]', value?.images[i].originFileObj);
        }

        formData.append('corporate_name', value?.company_name);
        formData.append('property_name', value?.property_name);
        formData.append('construction_site', value.construction_site);
        formData.append('entity', value?.entity);
        formData.append('quote_id', String(id));
        if (!isAllLE100MB) {
            message.error('画像の合計サイズは 100MB を超えてはなりません');
            alertError('画像の合計サイズは 100MB を超えてはなりません');
            setIsLoading(false);
        } else {
            if (project_id) {
                try {
                    const res = await projectApi.editProject(
                        user?.access_token,
                        formData,
                        project_id,
                    );
                    if (res) {
                        alertSuccess('修繕依頼を作成しました。');
                        navigate(`/projects`);
                        setIsLoading(false);
                        setTotalImageSize(0);
                    }
                } catch (error) {
                    console.log('error', error.message);
                    setIsLoading(false);
                    // alertError(error.message);
                }
            } else {
                try {
                    const res = await projectApi.createProject(
                        user?.access_token,
                        formData,
                    );
                    if (res) {
                        alertSuccess('修繕依頼を作成しました。');
                        navigate(`/projects`);
                        setIsLoading(false);
                        setTotalImageSize(0);
                    }
                } catch (error) {
                    console.log('error', error.message);
                    setIsLoading(false);
                    // alertError(error.message);
                }
            }
        }
    };

    useEffect(() => {
        if (screenType === TYPE_OF_SCREEN.detail) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, []);

    useEffect(() => {
        if (id && project_id) {
            getDetail();
        } else {
            getDetailRepair();
        }
    }, [id]);
    useEffect(() => {
        if (isExcessiveUpload) {
            message.error('ファイルアップロードは100ファイルまでです。');
            setIsExcessiveUpload(false);
        }
    }, [isExcessiveUpload]);

    useEffect(() => {
        if (sizeUploadLimitError) {
            message.error(
                `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
            );
            setSizeUploadLimitError(false);
        }
    }, [sizeUploadLimitError]);

    return (
        <ProjectCreateStyles>
            <form
                onSubmit={handleSubmit(submitData)}
                className="container-project-create-detail d-flex flex-row"
            >
                <Box
                    sx={{
                        '.select-search': {
                            '.is-selected': {
                                backgroundColor: '#f2f9fc !important',
                                color: '#000 !important',
                            },
                        },
                        '.select-search__input': {
                            boxShadow: 'none !important',
                            borderColor: '#CDD1D5 !important',
                        },
                        '.select-search__option:hover': {
                            backgroundColor: '#f2f9fc !important',
                            background: '#f2f9fc !important',
                        },
                    }}
                    className="project-create-content d-flex flex-column"
                >
                    <ProgressStepCreate status={projectStatus} />

                    <div className="grid grid-cols-3 gap-[15px]">
                        <div className="flex flex-col mt-2">
                            <p className="fieldLabel flex flex-row !w-[102px]">
                                <RequireSign />
                                担当者名
                            </p>
                            <Controller
                                name="person_in_charge"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            // name="name"
                                            className="textBox rounded-[2px] w-full h-[40px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'person_in_charge',
                                                createProjectValidation(
                                                    t,
                                                ).person_in_charge(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            maxLength={255}
                                            disabled={isDisable}
                                        ></input>
                                        {errors.person_in_charge && (
                                            <span className="error">
                                                {errors.person_in_charge.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col mt-2">
                            <p className="fieldLabel flex flex-row !w-[102px]">
                                <RequireSign />
                                法人名
                            </p>
                            <Controller
                                name="company_name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            // name="name"
                                            className="textBox rounded-[2px] w-full h-[40px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'company_name',
                                                createProjectValidation(
                                                    t,
                                                ).construction_site(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            maxLength={255}
                                            disabled={isDisable}
                                        ></input>
                                        {errors.company_name && (
                                            <span className="error">
                                                {errors.company_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col mt-2">
                            <p className="fieldLabel flex flex-row !w-[102px]">
                                <RequireSign />
                                物件名
                            </p>
                            <Controller
                                name="property_name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            // name="name"
                                            className="textBox rounded-[2px] w-full h-[40px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'property_name',
                                                createProjectValidation(
                                                    t,
                                                ).property_name(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            maxLength={255}
                                            disabled={isDisable}
                                        ></input>
                                        {errors.name && (
                                            <span className="error">
                                                {errors.name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col mt-2">
                            <p className="fieldLabel flex flex-row !w-[102px]">
                                <RequireSign />
                                施工場所
                            </p>
                            <Controller
                                name="construction_site"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            // name="name"
                                            className="textBox rounded-[2px] w-full h-[40px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'construction_site',
                                                createProjectValidation(
                                                    t,
                                                ).construction_site(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            maxLength={255}
                                            disabled={isDisable}
                                        ></input>
                                        {errors.construction_site && (
                                            <span className="error">
                                                {errors.construction_site.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col mt-2">
                            <p className="fieldLabel flex flex-row !w-[102px]">
                                <RequireSign />
                                ご住所
                            </p>
                            <Controller
                                name="address"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            // name="name"
                                            className="textBox rounded-[2px] w-full h-[40px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'address',
                                                createProjectValidation(
                                                    t,
                                                ).address(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            maxLength={255}
                                            disabled={isDisable}
                                        ></input>
                                        {errors.address && (
                                            <span className="error">
                                                {errors.address.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        <div className="flex flex-col">
                            <p className="fieldLabel mt-2 flex flex-row">
                                <RequireSign />
                                公共・法人・個人
                            </p>
                            <Controller
                                name="entity"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div className="wrapSelectField">
                                        <Select
                                            options={noteOptions}
                                            showSearch
                                            {...register(
                                                'entity',
                                                createProjectValidation(
                                                    t,
                                                ).note(),
                                            )}
                                            {...field}
                                            filterOption={(
                                                input,
                                                option: any,
                                            ) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase(),
                                                    )
                                            }
                                            disabled={isDisable}
                                            className="!w-full !h-[40px]"
                                        />
                                    </div>
                                )}
                            />
                            {errors.entity && (
                                <span className="error">
                                    {errors.entity.message?.toString()}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col w-full mt-[10px]">
                        <div className="flex items-center mb-3">
                            <RequireSign />
                            <div className="mr-4 text-base">アップロード</div>
                            <img src={warningIcon} alt="warning circle icon" />
                            <div className="text-[#808080] pl-[3px]">
                                {' '}
                                1:1 スケール画像
                            </div>
                        </div>
                        <WrapperStyle className="flex flex-row gap-[15px]">
                            <UploadCustom
                                locale={{
                                    uploading: 'アップロード中',
                                }}
                                accept=".jpg, .jpeg, .png, .gif"
                                listType="picture-card"
                                className="!ml-0"
                                customRequest={dummyRequest}
                                {...register('images', {
                                    required:
                                        '画像は最低1枚以上アップロードしてください。',
                                })}
                                style={{ width: 'auto' }}
                                onChange={handleChangeFile}
                                beforeUpload={handleBeforeUpload}
                                fileList={listFile && listFile}
                                multiple
                                $isFull={
                                    typeof fileLength === 'number' &&
                                    fileLength >= 10
                                }
                                disabled={isDisable}
                            >
                                {isAllowUpload && uploadButton}
                            </UploadCustom>
                        </WrapperStyle>

                        {errors.images && (
                            <span className="error !text-[14px]">
                                {errors.images.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="content"
                            control={control}
                            render={(props) => {
                                return (
                                    <>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            修繕内容
                                        </p>
                                        <textarea
                                            {...register('content')}
                                            {...props}
                                            className="rounded-[2px] border-[1px] border-[#CDD1D5] p-[5px]"
                                            rows={5}
                                            maxLength={wordLimit}
                                            disabled={isDisable}
                                        ></textarea>
                                        <p className="text-right text-[#00000040]">
                                            {props.field.value !== undefined
                                                ? props.field.value.length >
                                                  wordLimit
                                                    ? wordLimit
                                                    : props.field.value.length
                                                : 0}
                                            /{wordLimit}
                                        </p>
                                    </>
                                );
                            }}
                        />
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{
                            marginTop: '14px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            style={{ width: '167.5px' }}
                            onClick={() => navigate('/projects')}
                        >
                            戻る
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{ marginLeft: '10px', width: '167.5px' }}
                            type="submit"
                        >
                            登録
                        </button>
                    </div>
                </Box>
            </form>
        </ProjectCreateStyles>
    );
};

const UploadCustom = styled(Upload)<{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
    .ant-upload-picture-card-wrapper {
        width: auto !important;
    }
    .ant-upload-list {
        width: auto !important;
        max-width: 100% !important;
    }
`;

const WrapperStyle = styled.div`
    .ant-upload-picture-card-wrapper {
        width: auto !important;
    }
    .ant-upload-list {
        width: auto !important;
        max-width: 100% !important;
    }
`;

export default ProjectCreate;
