import { FC } from 'react';
import { InvoiceDetailStyles } from './styled';
import ImageCarousel from 'components/ImageCarousel';
import { EstimateEnum, QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import styled from 'styled-components';
import useSPEstimateDetail, { Props, ReceivedProps } from './hook';
import CalendarIcon from 'assets/icons/calendar-icon.png';
import querystring from 'query-string'

const TextAreaCustom = styled(TextArea)`
    border: none !important;
    :hover {
        border: none !important;
    }
`;

const SPEstimateLayout: FC<Props> = ({
    invoice,
    project,
    totalBeforeTax,
    totalAfterTax,
    totalTax,
    filesListPdf,
    onUpdateStatus
}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const param = useParams()
    const queryObj = querystring.parse(location.search)
    const parseNamePdf = (file_path: any) => {
        const arrName: any = [];
        const string: any = decodeURIComponent(file_path);
        if (string !== null && string !== undefined) {
            for (let i = string?.length - 1; i > 0; i--) {
                if (string[i] !== '/') {
                    arrName.push(string[i]);
                } else {
                    break;
                }
            }
        }
        return arrName
            .slice()
            .reverse()
            .toString()
            .replaceAll(',', '')
            .split('.')[0];
    };

    return (
        <InvoiceDetailStyles>
            <div className="rounded-2xl py-2 px-1 text-[14px]" style={{ gap: '10px', margin: '14px 16px' }}>
                <div>
                    <p className='font-bold' >基本情報</p>
                    <div className='flex flex-row justify-between' >
                        <p className='text-[#666666]' >会社名: <span className='text-[#000]' > {project?.corporate_name} </span></p>
                        <div
                            className="text-overflow1"
                            style={{
                                whiteSpace:
                                    'nowrap',
                                color: '#727272',
                                backgroundColor: '#1CB4031A',
                                borderRadius:
                                    '15px',
                                padding:
                                    '4px 9px',
                            }}
                        >
                            <p style={{ margin: '0px', lineHeight: '30px' }}>{project?.estimate?.status && EstimateEnum[project?.estimate?.status].title} </p>
                        </div>
                    </div>
                    <div className='flex flex-row' >
                        <img src={CalendarIcon} alt="calendar" style={{ height: '16px' }} /> <p className='text-[#666666] ml-2' >{moment(project?.from_date).format('YYYY年MM月DD日')}</p>
                    </div>
                    {/* <p className='text-[#666666]' >電話番号: <span className='text-[#000]' >{invoice?.company_bank_account}</span></p> */}
                    <p>{project?.address}</p>
                    <hr />
                    <p className='text-[#666666]'>お客様: <span className='text-[#000]' >{project?.quote?.customer?.last_name}{project?.quote?.customer?.first_name}</span></p>
                    <p>{project?.quote?.customer?.address}</p>

                    <button
                        className="modal-create-user-button2 d-flex flex-row mb-[20px]"
                        style={{ marginLeft: '10px', minWidth: '104px', maxWidth: '150px' }}
                        type="button"
                        onClick={() => navigate(`/sp/list-bill/${param?.id}${queryObj.top === 'true' ? '?top=true' : ''}`)}
                    >
                        請求書リスト
                    </button>
                </div>

                <div className="rounded-2xl py-2 px-3 mb-[15px] wrapperBox text-[14px]">
                    <p className='text-[16px] font-bold text-center' >PDFファイル一覧</p>
                    <div className="flex flex-col w-[100%] items-center" >
                        {filesListPdf?.length > 0 &&
                            filesListPdf?.map(
                                (item: any, ind: any) => (
                                    <a
                                        className="text-[#000000] !no-underline w-full"
                                        download
                                    >
                                        <div className="flex w-[100%] bg-[#F8F8F8] rounded-xl p-2">
                                            <div className="flex justify-between w-[100%] items-center">
                                                <a
                                                    href={item?.name}
                                                    className="text-[#000000] !no-underline flex items-center"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={pdfFileIcon}
                                                        alt="pdf file icon"
                                                    />
                                                    <div className="ml-3">
                                                        <div className="text-ellipsis whitespace-nowrap overflow-hidden	max-w-[450px] ">
                                                            {!item?.uid ? parseNamePdf(item?.name,) : item?.name?.substring(0, item?.name?.length - 4,)}
                                                        </div>
                                                        <div className="text-[#0000008C]">
                                                            {item?.file_path ? item?.size : Number(item?.size / 1024,)?.toFixed(1,) + 'KB'}
                                                        </div>
                                                    </div>
                                                </a>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ),
                            )}
                    </div>
                </div>
                {project?.estimate?.estimate_items && project?.estimate?.estimate_items.map((item, index) => (
                    <div className="rounded-2xl py-2 px-3 mb-[15px] wrapperBox text-[14px]">
                        <p className='text-[16px] font-bold text-center' >{item?.name}</p>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>数量</p>
                            <p>{item?.quantity}</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>単位</p>
                            <p>{item?.unit}</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>単価</p>
                            <p>¥{item?.unit_price && item?.unit_price.toLocaleString('en-US')}</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>税率</p>
                            <p>{item?.tax ? item?.tax : '10'}%</p>
                        </div>
                        <div className='flex flex-row justify-between' >
                            <p className='text-[#666666]'>金額</p>
                            <p>¥{(Number(item.quantity) * Number(item.unit_price) + (Number(item.quantity) * Number(item.unit_price) * 10 / 100)).toLocaleString('en-US')}</p>
                        </div>
                    </div>
                ))}

            </div>
            <hr />
            <div style={{ gap: '10px', margin: '14px 16px', background: '#F9F9F9' }} className='px-[16px] pt-[10px]'>
                <div className='flex flex-row justify-between' >
                    <p className='text-[#666666]'>小計</p>
                    <p>¥{totalBeforeTax.toLocaleString('en-US')}</p>
                </div>
                <div className='flex flex-row justify-between' >
                    <p className='text-[#666666]'>割引</p>
                    {/* <p>-¥{invoice?.total_tax.toLocaleString('en-US')}</p> */}
                    <p>-¥0</p>
                </div>
                <div className='flex flex-row justify-between' >
                    <p className='text-[#666666]'>消費税</p>
                    <p>¥{totalTax.toLocaleString('en-US')}</p>
                </div>
                <div className='flex flex-row justify-between text-[20px]' >
                    <p>合計</p>
                    <p>¥{totalAfterTax.toLocaleString('en-US')}</p>
                </div>
            </div>
            {project?.estimate?.status === 'request_to_check' && <div className='bg-[#fff] flex flex-row justify-center w-full' style={{ padding: '16px 24px 16px 24px' }} >
                <div
                    className="d-flex flex-row justify-center text-lg w-full"
                    style={{
                        marginTop: '14px',
                    }}
                >
                    <button
                        className="modal-create-user-button1 d-flex flex-row"
                        style={{ width: '49%', border: '1px solid #D9D9D9', color: '#fff', background: '#FF4D4F' }}
                        type="button"
                        onClick={() => onUpdateStatus('request_to_adjust_amount')}
                    >
                        金額修正希望
                    </button>
                    <button
                        className="modal-create-user-button2 d-flex flex-row"
                        style={{ marginLeft: '10px', width: '49%' }}
                        type="button"
                        onClick={() => onUpdateStatus('approval_request')}
                    >
                        承認
                    </button>
                </div>
            </div>}
        </InvoiceDetailStyles>
    );
};

const SPEstimateDetail: FC<ReceivedProps> = (props) => {
    return <SPEstimateLayout {...useSPEstimateDetail(props)} />;
};

export default SPEstimateDetail;
