export const validate = {
    validate_input: 'を入力してください。',
    validate_furigana: 'フリガナでご入力してください。',
    validate_phone: '電話番号は半角数字で入力くださ,い。',
    validate_fax: 'Faxは半角数字で入力ください。',
    validate_capital: '資本金は半角数字で入力ください。',
    validate_sum_capital: '資本金は8文字以上にしてください',
    invalid_number: '正しい数字を入力してください',
    greater_numbers: '文字まで入力してください',
};
