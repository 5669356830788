import { useState, useEffect } from 'react';
import './clientCompany.css';
import { useNavigate } from 'react-router-dom';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useModal } from 'react-hooks-use-modal';
import CompanyService from '../../services/clientCompanyService';
import 'react-dropdown/style.css';
import { IPageQueries } from 'models';
import CustomPagination from 'components/CustomPagination';
import {
    TextField,
    InputAdornment,
    Box,
    Select,
    useTheme,
    MenuItem,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';

const names = [
    { label: '法人', value: '法人' },
    { label: '個人', value: '個人' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '13px',
        paddingBottom: '13px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TableRowCustom = (props) => {
    const { row, openDelete, setCompanyDelete } = props;
    const navigate = useNavigate();

    return (
        <StyledTableRow>
            <StyledTableCell align="left" scope="row" style={{ width: '10%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.name}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '10%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.business_type}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '10%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.phone}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '10%' }}>
                <div className="text-overflow1">{row.company?.email}</div>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '10%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row.company?.construction_site}
                </div>
            </StyledTableCell>

            <StyledTableCell align="left" style={{ width: '20%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <span> {row.company?.person_in_charge ?? ''}</span> &nbsp;
                </div>
            </StyledTableCell>

            <StyledTableCell align="left" style={{ width: '20%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <span> {row.company?.person_in_charge_phone_number ?? ''}</span>{' '}
                    &nbsp;
                </div>
            </StyledTableCell>

            <StyledTableCell align="left" style={{ width: '20%' }}>
                <div
                    className="text-overflow1"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <span> {row.company?.province ?? ''}</span> &nbsp;
                    <span> {row.company?.company_address ?? ''}</span>
                </div>
            </StyledTableCell>

            <StyledTableCell align="center" style={{ width: '30%' }}>
                <div className="w-full flex gap-1">
                    <button
                        className="button-tb"
                        style={{ width: '72px', margin: '5px' }}
                        onClick={() => navigate(`/companies/detail/${row.id}`)}
                    >
                        詳細
                    </button>
                    <button
                        className="button-tb"
                        style={{ width: '72px', margin: '5px' }}
                        onClick={() => navigate(`/companies/edit/${row.id}`)}
                    >
                        編集
                    </button>
                    <button
                        className="button-tb"
                        style={{
                            width: '72px',
                            margin: '5px',
                            background: '#FF5045',
                        }}
                        onClick={() => {
                            openDelete();
                            setCompanyDelete(row);
                        }}
                    >
                        削除
                    </button>
                </div>
            </StyledTableCell>
        </StyledTableRow>
    );
};

const ClientCompany = (props) => {
    const navigate = useNavigate();

    const [companiesList, setCompanyList] = useState<any>(null);

    const [searchingText, setSearchingText] = useState<any>('');
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchedState, setSearchedState] = useState<string>('');
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const [companyDelete, setCompanyDelete] = useState<any>(null);

    const getListCompany = async ({
        page = 1,
        per_page = 5,
        free_word = '',
        business_type = '',
    }) => {
        try {
            const response = await CompanyService.getListCompanies({
                page,
                per_page,
                free_word,
                business_type,
            });
            if (response !== null && response !== undefined) {
                setCompanyList(response.client_companies);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleSearch = () => {
        setSearchedState(searchingText);
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListCompany({
            page: 1,
            per_page: pageQueries.per_page,
            free_word: searchingText,
            business_type: businessType,
        });
    };

    const deleteCompany = async () => {
        try {
            const response = await CompanyService.deleteCompany(
                companyDelete.id,
            );
            if (response !== null && response !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListCompany({
                    page: 1,
                    per_page: pageQueries.per_page,
                    free_word: searchedState,
                });
                setCompanyDelete(null);
                closeDelete();
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const [winReady, setwinReady] = useState(false);
    useEffect(() => {
        setwinReady(true);
    }, []);

    useEffect(() => {
        getListCompany({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    const [businessType, setBusinessType] = React.useState();

    const handleChangeCorporation = (event) => {
        const {
            target: { value },
        } = event;
        setBusinessType(value);
    };

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate('/companies/create')}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>
                <div className="search-client-company-button d-flex flex-row">
                    <Box
                        sx={{
                            width: '100%',
                            ' .MuiSelect-select': {
                                border: '1px solid #cdd1d5',
                                borderRadius: '8px',
                            },
                            '.MuiInputBase-input': {
                                padding: '6px !important',
                            },
                        }}
                        className="margin-input d-flex flex-column"
                        style={{ width: '292px' }}
                    >
                        <p>法人・個人</p>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={businessType}
                            onChange={handleChangeCorporation}
                        >
                            {names?.map((element, index) => (
                                <MenuItem key={index} value={element.value}>
                                    {element.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            '.input-search': {
                                border: '1px solid #cdd1d5',
                                borderRadius: '8px',
                                marginBottom: '14.5px',
                                paddingLeft: '14px',
                            },
                            '.MuiTextField-root': {
                                border: '1px solid #cdd1d5',
                                width: '100%',
                                borderRadius: '8px',
                                marginRight: '12.5px',
                            },
                            '.MuiInputBase-input': {
                                height: '35px',
                                padding: '0px !important',
                            },
                        }}
                        className="margin-input d-flex flex-column"
                        style={{ width: '292px' }}
                    >
                        <p>キーワード</p>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: '#000' }} />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete="off"
                            id="input-search"
                            type="text"
                            name="free_word"
                            onChange={(e) => {
                                setSearchingText(e.target.value);
                            }}
                        />
                    </Box>

                    <div className="search-client-company-row-button margin-left d-flex flex-column">
                        <button
                            className="search-user-button d-flex flex-column"
                            onClick={handleSearch}
                            style={{
                                width: '150px',
                                height: '36px',
                                marginBottom: '12px',
                            }}
                        >
                            検索
                        </button>
                    </div>
                </div>
                <div
                    className="table-workers table-company d-flex flex-column"
                    style={{ marginTop: '10px' }}
                >
                    <div className="table-content d-flex flex-column">
                        {winReady ? (
                            <>
                                <TableContainer
                                    component={Paper}
                                    table-layout={'auto'}
                                >
                                    <Table
                                        sx={{ minWidth: 1600 }}
                                        aria-label="customized table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '9%' }}
                                                >
                                                    <div>会社名</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="left"
                                                    style={{ width: '15%' }}
                                                >
                                                    <div>法人・個人</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div>電話番号</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '15%' }}
                                                >
                                                    <div>Email</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '15%' }}
                                                >
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        施工場所
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '15%' }}
                                                >
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        担当者名
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '15%' }}
                                                >
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        担当者電話番号
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1 !text-left"
                                                    align="center"
                                                    style={{ width: '12%' }}
                                                >
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        住所
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    align="center"
                                                    style={{
                                                        width: '28%',
                                                    }}
                                                >
                                                    <div>操作</div>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {companiesList !== null &&
                                                companiesList?.length > 0 &&
                                                companiesList.map(
                                                    (row, ind) => (
                                                        <TableRowCustom
                                                            key={ind}
                                                            row={row}
                                                            openDelete={
                                                                openDelete
                                                            }
                                                            setCompanyDelete={
                                                                setCompanyDelete
                                                            }
                                                        ></TableRowCustom>
                                                    ),
                                                )}
                                        </TableBody>
                                    </Table>
                                    {companiesList?.length !== null &&
                                        companiesList?.length === 0 && (
                                            <div className="text-center p-[10px] w-full">
                                                データがありません。
                                            </div>
                                        )}
                                </TableContainer>
                            </>
                        ) : null}
                        <ModalDelete>
                            <div
                                className="modal-create-user d-flex flex-column"
                                style={{ height: '152px' }}
                            >
                                <div className="modal-create-user-title d-flex flex-row">
                                    本当に削除しますか?
                                </div>
                                <div
                                    className="modal-create-user-buttons d-flex flex-row"
                                    style={{ marginTop: '50px' }}
                                >
                                    <button
                                        className="modal-create-user-button1 d-flex flex-row"
                                        onClick={closeDelete}
                                        style={{ width: '48%' }}
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        onClick={deleteCompany}
                                        className="modal-create-user-button2 d-flex flex-row"
                                        style={{ width: '48%' }}
                                    >
                                        はい
                                    </button>
                                </div>
                            </div>
                        </ModalDelete>
                    </div>
                </div>
                {companiesList && companiesList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListCompany({
                                page,
                                per_page: pageQueries.per_page,
                                free_word: searchedState,
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ClientCompany;
