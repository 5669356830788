import React from 'react';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Box, InputAdornment } from '@mui/material';
// import { ALL } from 'utils/constants';
import { Search } from '@mui/icons-material';
import FilterBoxStyle from './FilterBoxStyle';
import { Select } from 'antd';

const FilterBox = ({
    formik,
    positionOptions,
    workerOptions,
    departmentOptions,
    isOpenDepartment,
    setIsOpenDepartment,
    isOpenPosition,
    setIsOpenPosition,
    isOpenWorker,
    setIsOpenWorker,
}) => {
    // const {
    //   positionList = {
    //     data: [],
    //   },
    //   officesList = {
    //     data: [],
    //   },
    // } = useSelector(state => state.global || {});
    return (
        <FilterBoxStyle>
            <div className="grid grid-cols-3 gap-[15px]">
                <div className="">
                    <span className="fieldLabel">部署</span>
                    {/* <Autocomplete
                        multiple
                        onChange={(event, newValue) => {
                            formik.setFieldValue('department_id', newValue);
                        }}
                        className="max-w-[400px]"
                        noOptionsText="該当なし"
                        options={departmentOptions}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        open={isOpenDepartment}
                        onOpen={() => setIsOpenDepartment(true)}
                        onClose={() => setIsOpenDepartment(false)}
                        onFocus={() => setIsOpenDepartment(true)}
                    /> */}
                    <Select
                        options={departmentOptions}
                        showSearch
                        mode="multiple"
                        onChange={(event, newValue) => {
                            formik.setFieldValue('department_id', newValue);
                        }}
                        filterOption={(input, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        // suffixIcon={MoveDownOutlined}
                        className="!w-full !h-[40px]"
                    />
                </div>
                <div className="wrapSelectField ">
                    <span className="fieldLabel">役職</span>
                    <Select
                        options={positionOptions}
                        showSearch
                        mode="multiple"
                        onChange={(event, newValue) => {
                            formik.setFieldValue('position_id', newValue);
                        }}
                        filterOption={(input, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        // suffixIcon={MoveDownOutlined}
                        className="!w-full !h-[40px]"
                    />
                    {/* <Autocomplete
                        onChange={(event, newValue) => {
                            formik.setFieldValue('position_id', newValue);
                        }}
                        multiple
                        className="max-w-[400px]"
                        noOptionsText="該当なし"
                        options={positionOptions}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        open={isOpenPosition}
                        onOpen={() => setIsOpenPosition(true)}
                        onClose={() => setIsOpenPosition(false)}
                        onFocus={() => setIsOpenPosition(true)}
                    /> */}
                </div>
                <div className="wrapSelectField  !mb-2">
                    <span className="fieldLabel">社員</span>
                    <Select
                        options={workerOptions}
                        showSearch
                        // mode="multiple"
                        onChange={(event, newValue) => {
                            formik.setFieldValue('worker_id', newValue);
                        }}
                        filterOption={(input, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        // suffixIcon={MoveDownOutlined}
                        className="!w-full !h-[40px]"
                    />
                    {/* <Autocomplete
                        value={formik.values.worker_id}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('worker_id', newValue);
                        }}
                        className="max-w-[400px]"
                        noOptionsText="該当なし"
                        options={workerOptions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        open={isOpenWorker}
                        onOpen={() => setIsOpenWorker(true)}
                        onClose={() => setIsOpenWorker(false)}
                        onFocus={() => setIsOpenWorker(true)}
                    /> */}
                </div>
                <div className="wrapTextField">
                    <span className="fieldLabel">キーワード</span>
                    <Box
                        sx={{
                            width: '100%',
                            '.MuiTextField-root': {
                                width: '100%',
                                borderRadius: '8px',
                                marginRight: '12.5px',
                            },
                            '.MuiInputBase-input': {
                                padding: '0px !important',
                            },
                        }}
                        className="wrapTextField"
                    >
                        <TextField
                            fullWidth
                            hiddenLabel
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: '#000', fontSize: '25px', marginTop: '15px' }} />
                                    </InputAdornment>
                                ),
                            }}
                            name="free_word"
                            type="text"
                            style={{ background: '#fff', border: '1px solid #d9d9d9', height: '35px', borderRadius: '2px' }}
                            variant="outlined"
                            onChange={formik.handleChange}
                            value={formik.values.free_word}
                        />
                    </Box>
                    {/* <TextField
                        fullWidth
                        hiddenLabel
                        name="free_word"
                        type="text"
                        variant="outlined"
                        margin="dense"
                        onChange={formik.handleChange}
                        value={formik.values.free_word}
                    /> */}
                </div>

                <div className="btnGroup mt-[23px]">
                    <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        className="customBtn"
                        style={{ width: '170px', height: '35px', borderRadius: '2px' }}
                    >
                        検索
                    </button>
                </div>
            </div>
        </FilterBoxStyle>
    );
};

export default FilterBox;
