import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { Dispatch, SetStateAction } from 'react';
import { QuoteStatusEnum, WasteSugorokuIndexEnum2 } from 'utils/enums';
import { convertSugorokuEnumObjectToArray } from 'utils/helper/sugorokuHelper';

const SugorokuEnum = [
    { name: 'request_for_quotation', value: 10, title: '発注' },
    { name: 'quotation', value: 20, title: '発注書' },
    { name: 'send_quotation', value: 30, title: '受注' },
    { name: 'price_negotiation', value: 40, title: '工事' },
    { name: 'contract_writing', value: 50, title: '請求書作成' },
    { name: 'preparation_of_safety_documents', value: 60, title: '入金確認' },
];
interface IProjectStepProps {
    setStep: Dispatch<SetStateAction<number>>;
    setOpenStep: Dispatch<SetStateAction<boolean>>;
    sugorokuList: any;
    readOnly?: boolean;
    status?: any
}
const ProjectSteps = ({
    readOnly,
    sugorokuList,
    setOpenStep,
    setStep,
    status
}: IProjectStepProps) => {
    const handleStep = (step: number, value: number) => () => {
        // if(readOnly) return
        setOpenStep(true);
        setStep(value);
    };
    const wasteSugorokuEnumList = convertSugorokuEnumObjectToArray(WasteSugorokuIndexEnum2);
    return (
        <Box
            sx={{
                width: '100%',
                fontFamily: 'Zen Kaku Gothic Antique',
                mb: '40px',
                '.Mui-active': {
                    color: '#215493 !important',
                    text: {
                        fill: '#fff',
                    },
                },
                '.MuiStepLabel-labelContainer': {
                    '.Mui-active': {
                        color: '#1C1E21 !important',
                    },
                },
                '.MuiSvgIcon-root': {
                    color: '#E3E5E8',
                    width: '34px',
                    height: '34px',
                },
                '.MuiStepLabel-iconContainer': {
                    mt: '-6px',
                },
                '&.MuiStepConnector-line': {
                    borderColor: '#8D95A0',
                },

                '.MuiStepLabel-labelContainer > span': {
                    color: '#58606A',
                    fontWeight: 500,
                    fontSize: '16px',
                },
                text: {
                    fill: '#215493',
                    fontWeight: 700,
                },
            }}
        >
            <Stepper nonLinear alternativeLabel activeStep={-1}>
                {wasteSugorokuEnumList.map((label, index) => {
                    return (
                        <Step
                            active={WasteSugorokuIndexEnum2[
                                status
                            ]?.value >= label?.value}
                            color="red"
                            key={label.value}
                        >
                            <StepButton
                                sx={{ color: '#000000' }}
                            // onClick={handleStep(index, label.value)}
                            >
                                {label.title}
                            </StepButton>
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    );
};

export default ProjectSteps;
