import { useEffect, useState } from 'react';
import './progressStep.css';
import Dialog from '@mui/material/Dialog';

import workersService from 'services/wokersService';
import sugorokuService from 'services/sugorokuService';
import { SugorokuIndexEnum, WasteSugorokuIndexEnum } from 'utils/enums';
import { convertSugorokuArrayToObject } from 'utils/helper/sugorokuHelper';
import { ISugorokuList } from 'models';
import CompanyService from 'services/clientCompanyService';
import {
    convertClientCompaniesToDropdownOptions,
    removeUndefinedCompany,
} from 'utils/helper/clientCompanyHelper';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import ProjectSteps from './components/ProjectSteps';
interface IProps {
    project_id?: number;
    business_type?: boolean;
    readOnly?: boolean;
    status?: String;
}

const ProgressStepCreate = (props: IProps) => {
    const { project_id, business_type, status } = props;

    const [openStep, setOpenStep] = useState(false);
    const [step, setStep] = useState(10);
    const [workerOptions, setWorkerOptions] = useState<Record<string, any>[]>(
        [],
    );
    const [companyOptions, setCompanyOptions] = useState<Record<string, any>[]>(
        [],
    );
    const [sugorokuList, setSugorokuList] = useState<ISugorokuList | null>(
        null,
    );

    const getListWorkers = async () => {
        try {
            const data = await workersService.getListWorkers();
            if (data) {
                setWorkerOptions(convertObjectToDropdownOptions(data.workers));
            }
        } catch (error) {
            // empty
        }
    };

    const fetchSugorokuEventsList = async () => {
        try {
            let data;

            if (business_type) {
                data = await sugorokuService.getListOfWasteTypeSugoroku(
                    project_id || 1,
                );
            } else {
                data = await sugorokuService.getListOfSugoroku(project_id || 1);
            }

            if (data) {
                setSugorokuList(
                    convertSugorokuArrayToObject(data.sugoroku_events),
                );
            }
        } catch (error) {
            // empty
        }
    };

    const fetchAllCompanies = async () => {
        try {
            let clientList;
            let cooperativeList;

            const clientData = await CompanyService.getListCompanies();
            if (clientData) {
                clientList = convertClientCompaniesToDropdownOptions(
                    clientData.client_companies,
                );
            }

            const cooperativeData =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (cooperativeData) {
                cooperativeList = convertClientCompaniesToDropdownOptions(
                    cooperativeData.cooperative_companies,
                );
            }

            const fullList = removeUndefinedCompany(
                clientList.concat(cooperativeList),
            );
            setCompanyOptions(fullList);
        } catch (error) {
            // empty
        }
    };

    const handleFinishUpdateSugorokus = () => {
        fetchSugorokuEventsList();
        setOpenStep(false);
    };

    useEffect(() => {
        // getListWorkers();
        // fetchAllCompanies();
        // fetchSugorokuEventsList();
    }, []);
    return (
        <>
            <div

            >
                {/* {sugorokuList && (
                    <>
                        {business_type ? (
                            <ProjectSteps
                                setStep={setStep}
                                setOpenStep={setOpenStep}
                                sugorokuList={sugorokuList}
                                readOnly={!!props.readOnly}
                            />
                        ) : (
                            <StepDiagram
                                setStep={setStep}
                                setOpenStep={setOpenStep}
                                sugorokuList={sugorokuList}
                                readOnly={!!props.readOnly}
                            />
                        )}
                    </>
                )} */}

                <ProjectSteps
                    setStep={setStep}
                    setOpenStep={setOpenStep}
                    sugorokuList={sugorokuList}
                    readOnly={!!props.readOnly}
                    status={status}
                />
            </div>
        </>
    );
};

export default ProgressStepCreate;
