export const TEXT_ACTION = {
    CREATE: '新規作成',
    CREATE_SUB: '作成',
    EDIT: '編集',
    DETAIL: '詳細',
    REGISTRATION: '登録',
    ISSUES: '発行',
    KEEP: '保存',
    INVOICE: '請求書',
};
