import { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";
import { useLocation, useParams } from "react-router-dom";
import invoiceApi from "services/invoiceApi";
import projectApi from "services/projectApi";
import quoteApi from "services/quoteApi";
import quoteService from "services/quoteService";
import { alertSuccess } from "utils/helper/appHelper";

interface ListData {
    id: number;
    user_name: string;
    avatar_url: string;
    address: string;
    status: string;
    created_at: string;
    quote_type: string;
}

export type ReceivedProps = Record<never, never>;
const useSPEstimateDetail = (props: ReceivedProps) => {
    const [quoteList, setQuoteList] = useState<ListData[]>([]);
    const [data, setData] = useState({})
    const [invoice, setInvoice]: any = useState()
    const [project, setProject]: any = useState()
    const location = useLocation()
    const [paymentDate, setPaymentDate] = useState('')
    let storage = localStorage.getItem('line:accessToken');
    const params = useParams();
    const { id } = params;
    const [totalBeforeTax, setTotalBeforeTax] = useState(0);
    const [totalAfterTax, setTotalAfterTax] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [listType, setListType]: any = useState([]);
    const [filesListPdf, setFilesListPdf]: any = useState([]);
    const [filePdfList, setFilePdfList]: any = useState([]);
    const [totalFile, setTotalFile]: any = useState([]);
    const fetchInvoice = async () => {
        try {

            const res = await invoiceApi.getLineInvoiceDetail(storage, id)
            if (res) {
                setInvoice(res.data.invoice)
            }
        } catch (error) {
            //
        }
    };

    const onUpdateStatus = async (newStatus: string) => {
        try {
            const body = {
                status: newStatus
            }
            const res = await quoteApi.updateSPRepair(storage, body, project?.quote?.id)

            if (res) {
                alertSuccess('ステータスが更新されました。')
                getDetailProject()
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const getDetailProject = async () => {
        if (!id) return;
        try {
            const res = await projectApi.getProjectDetail(
                storage,
                id,
            );
            if (res) {
                setProject(res?.data?.project)
                calTotal(res?.data?.project?.estimate?.estimate_items)
                const dataFile = res?.data?.project?.estimate.files
                const pdfArr: any = [];
                if (dataFile) {
                    const arrName: any = [];
                    for (let i = 0; i < dataFile.length; i++) {
                        arrName.push(dataFile[i]?.file_path);
                        await fetch(dataFile)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    dataFile[i]?.file_path,
                                    {
                                        type: "application/pdf",
                                    },
                                );
                                listType.push('pdf');
                                pdfArr.push(myFile);
                            });
                    }
                }
                setFilesListPdf(pdfArr);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const calTotal = (estimate) => {
        let totalBFTax = 0;
        let totalTax = 0;
        let totalAfterTax = 0;
        if (estimate.length > 0) {
            for (let i = 0; i < estimate.length; i++) {
                let price = estimate[i]?.unit_price

                let quantity = estimate[i]?.quantity
                if (
                    Number(price) > -1 &&
                    Number(10) > -1 &&
                    Number(quantity) > -1
                ) {
                    totalBFTax += Number(price) * Number(quantity);
                    totalTax +=
                        (Number(10) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                    totalAfterTax +=
                        Number(price) * Number(quantity) +
                        (Number(10) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                }
            }
        }
        setTotalBeforeTax(totalBFTax);
        setTotalAfterTax(totalAfterTax);
        setTotalTax(totalTax);
    };

    useEffect(() => {
        if (filesListPdf === null) {
            setTotalFile(filePdfList);
        } else {
            setTotalFile(filesListPdf?.concat(filePdfList));
        }
    }, [filePdfList, filesListPdf]);

    useEffect(() => {
        getDetailProject()
    }, [id])
    return {
        ...props,
        invoice,
        project,
        data,
        totalBeforeTax,
        totalAfterTax,
        totalTax,
        filesListPdf,
        listType,
        onUpdateStatus
    };
};
export type Props = ReturnType<typeof useSPEstimateDetail>;

export default useSPEstimateDetail;
