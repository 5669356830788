import styled from 'styled-components';
import vector from '../../../assets/icons/Vector.png';

const FilterBoxStyle = styled.div`
    padding: 20px 20px 100px;
    width: 100%;
        .ant-select-selector {
    height: 35px !important;
    }
    @media screen and (max-width: 1201px) {
        width: 200px;
    }
    .customBtn {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        background: #195192;
        border-radius: 8px;
        padding: 4px 5px;
    }
    .MuiChip-root {
        height: 20px;
    }
    .MuiChip-label {
        font-size: 14px;
    }
    .filterBoxTop {
        .MuiTextField-root {
            margin-top: 0px;
        }
        .wrapSelectField {
            margin: 0;

            .MuiFormControl-root {
                .MuiFormHelperText-root {
                    margin-left: 0;
                    color: #d32f2f !important;
                }
            }

            &.multipleSelect {
                .MuiOutlinedInput-root.MuiInputBase-root {
                    min-height: 30px;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 12px;
                        width: 13px;
                        height: 13px;
                        background: url('${vector}') no-repeat center;
                        transform: rotate(90deg);
                        background-size: contain;
                        pointer-events: none;
                    }

                    .MuiAutocomplete-tag {
                        height: 24px;
                    }

                    .MuiChip-root {
                        .MuiSvgIcon-root {
                            display: initial;
                            font-size: 16px;
                        }
                    }
                }
            }

            .MuiSelect-select {
                font-weight: 400;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-root {
                min-height: 30px;
                background: #f3f3f3;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 8px;
                    width: 13px;
                    height: 13px;
                    background: url('${vector}') no-repeat center;
                    transform: rotate(90deg);
                    background-size: contain;
                    pointer-events: none;
                }

                fieldset {
                    border: none;
                }

                .MuiSvgIcon-root {
                    display: none;
                }
            }
            .MuiAutocomplete-root {
                .MuiInputBase-root {
                    padding: 0px 15px;
                }
            }
            .MuiAutocomplete-input {
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }
            .MuiOutlinedInput-root {
                .MuiAutocomplete-input {
                    padding: 0;
                }
            }
        }
        .fieldLabel {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            width: 110px;
            color: #1c1e21;
            display: block;
            margin: 0 0 4px;
        }

        .wrapSelectField {
            width: 100%;
        }

        .wrapTextField {
            width: 100%;
            margin-right: 10px;
            .MuiInputBase-input {
                padding: 3.5px 12px !important;
            }

            textarea {
                background: #f3f3f3;
                border-radius: 4px;
                min-height: 60px;
                height: 60px !important;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input.MuiInputBase-input {
                    background: #f3f3f3;
                    border-radius: 4px;
                    border: 0;
                }
            }
        }
    }
`;

export default FilterBoxStyle;
