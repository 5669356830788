import { alertPopup } from 'utils/helper/appHelper';
import Api from './api/cooperativeCompaniesApi';
import pick from 'lodash/pick';

const getListCooperativeCompanies = async (
    params: any = { page: 1, per_page: 0, free_word: '', province_name: '' },
) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            if (user.access_token !== undefined && user.access_token !== null) {
                return await Api()
                    .get('', {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                        params,
                    })
                    .then((response) => {
                        if (response.data) {
                            return response.data;
                        }
                    });
            }
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const getCooperativeCompany = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const createCooperativeCompany = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, [
        'name',
        'email',
        'fax',
        'zipcode',
        'province',
        'company_address',
        'date_of_establishment',
        'capital',
        'number_of_employees',
        'phone',
        'business_content',
        'note',
        'is_client_company',
        'is_cooperative_company',
        'person_in_charge',
        'person_in_charge_phone_number',
        'business_type',
    ]);
    data.parent_company_id = body.parent_company_id?.value;
    if (user.access_token !== undefined && user.access_token !== null) {
        // try {
        return await Api()
            .post('', data, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });

        // } catch (err) {
        //     window.location.href = '/login';
        //     return null
        // }
    } else {
        window.location.href = '/login';
    }
};

const createCooperativeCompanyReview = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, ['stars', 'body']);
    const url = `${body?.cooperative_company_id}/reviews`;
    if (user.access_token !== undefined && user.access_token !== null) {
        // try {
        return await Api()
            .post(url, data, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });

        // } catch (err) {
        //     window.location.href = '/login';
        //     return null
        // }
    } else {
        window.location.href = '/login';
    }
};

const updateCooperativeCompanyReview = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, ['stars', 'body']);
    const url = `${body?.cooperative_company_id}/update_reviews`;
    if (user.access_token !== undefined && user.access_token !== null) {
        // try {
        return await Api()
            .put(url, data, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
        // } catch (err) {
        //     console.log(err)
        //     return null
        // }
    } else {
        window.location.href = '/login';
    }
};

const updateCooperativeCompany = async (body, id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(body, [
        'name',
        'email',
        'fax',
        'address',
        'date_of_establishment',
        'zipcode',
        'province',
        'company_address',
        'capital',
        'number_of_employees',
        'phone',
        'business_content',
        'note',
        'is_client_company',
        'person_in_charge',
        'person_in_charge_phone_number',
        'business_type',
    ]);
    data.parent_company_id = body.parent_company_id?.value || '';
    if (user.access_token !== undefined && user.access_token !== null) {
        // try {
        //     return await Api()
        //         .put(`${id}`, data, {
        //             headers: { Authorization: `Bearer ${user.access_token}` },
        //         })
        //         .then((response) => {
        //             if (response.data) {
        //                 return response.data;
        //             }
        //         });
        // } catch (err) {
        //     alertPopup({ type: 'error', title: err || '' });

        //     console.log('error', err);
        // }
        try {
            const response = await Api().put(`${id}`, data, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            });
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            alertPopup({ type: 'error', title: error || '' });
            throw error; // Ensure the error is thrown
        }
    } else {
        window.location.href = '/login';
    }
};

const deleteCooperativeCompany = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .delete(`${id}`, {
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            // window.location.href = '/login';
            return err;
        }
    } else {
        window.location.href = '/login';
    }
};

const CooperativeCompanyService = {
    getListCooperativeCompanies,
    createCooperativeCompany,
    updateCooperativeCompany,
    deleteCooperativeCompany,
    getCooperativeCompany,
    updateCooperativeCompanyReview,
    createCooperativeCompanyReview,
};

export default CooperativeCompanyService;
