import ProjectImg from 'assets/icons/image-project.jpg'
import { EntityEnum, WasteSugorokuIndexEnum2 } from 'utils/enums';
import { Upload } from 'antd';
import styled from 'styled-components';

const UploadCustom = styled(Upload) <{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list ant-upload-list-picture-card {
    margin-left: 0px !important;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;


const ProjectTabelRow = (props) => {
    return (
        <div className="project-detail-inf-row d-flex flex-row">
            {props.titles.map((title, index) => (
                <>
                    <div className="project-detail-inf-row-title">{title}</div>
                    <div className="project-detail-inf-row-content text-table">
                        {props?.values?.[index]}
                    </div>
                </>
            ))}
        </div>
    );
};

const ProjectTable = ({ currentProject, images }) => {
    return (
        <div className="grid grid-cols-3">
            {/* <img
                style={{
                    height: '282px',
                    width: 'calc(100% - 15px)',
                    borderRadius: '2px'
                }}
                src={ProjectImg}
                alt="project_image"
            /> */}
            <div>
                <UploadCustom
                    locale={{
                        uploading:
                            'アップロード中',
                    }}
                    accept=".jpg, .jpeg, .png, .gif"
                    listType="picture-card"
                    // customRequest={dummyRequest}
                    disabled
                    // disabled={!editable}
                    // onChange={handleChangeFile}
                    // beforeUpload={
                    //     handleBeforeUpload
                    // }
                    fileList={
                        images && images
                    }
                    multiple
                >
                    {/* {isAllowUpload &&
                        uploadButton} */}
                </UploadCustom>
            </div>
            <div className="project-detail-inf !rounded-sm col-span-2 d-flex flex-column">
                <>
                    <ProjectTabelRow
                        // titles={['顧客', '産廃']}
                        titles={['顧客', '工期']}
                        values={[
                            currentProject?.quote?.customer?.last_name && `${currentProject?.quote?.customer?.last_name}${currentProject?.quote?.customer?.first_name}`,
                            // EntityEnum[currentProject.entity]?.title,
                            currentProject?.from_date === null && currentProject?.end_date === null ? '' : `${currentProject?.from_date || ''} ~ ${currentProject?.end_date || ''}`,
                        ]}
                    />
                    <ProjectTabelRow
                        titles={['場所', '見積担当']}
                        values={[
                            currentProject?.address,
                            currentProject?.person_in_charge,
                        ]}
                    />
                    <ProjectTabelRow
                        titles={['管理者', '担当者名']}
                        values={[
                            currentProject?.manager_company,
                            currentProject?.corporate_name,
                        ]}
                    />
                    <ProjectTabelRow
                        titles={['業種', '']}
                        values={[
                            EntityEnum[currentProject.entity]?.title,
                            currentProject?.destination
                                ?.reduce(
                                    (result, item) =>
                                        (result += item + '、'),
                                    '',
                                )
                                .slice(0, -1),
                        ]}
                    />
                    {/* <ProjectTabelRow
                        titles={['協力会社', '担当社員']}
                        values={[
                            currentProject?.manager_company,
                            <div>
                                {currentProject?.assigned_project_workers_attributes &&
                                    currentProject?.assigned_project_workers_attributes?.map(
                                        (worker, index) => (
                                            <span>
                                                {`${worker?.name}${index + 1 <
                                                    currentProject
                                                        ?.assigned_project_workers_attributes
                                                        .length
                                                    ? '、'
                                                    : ''
                                                    }`}
                                            </span>
                                        ),
                                    )}
                            </div>,
                        ]}
                    /> */}
                </>
            </div>
        </div>
    );
};
export default ProjectTable;
