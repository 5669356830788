import { axiosClient } from './quoteApi';

class PrivateMessageApi {

    getUnreadMessageAlert = (idToken: any) => {
        const url = 'line_messages/unread';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    readMessage = (idToken: any) => {
        const url = `line_messages/read`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, {}, config);
    };

}
const privateMessageApi = new PrivateMessageApi();
export default privateMessageApi;
