export const FORMAT = {
    FORMAT_DATE_UPPER: 'YYYY/MM/DD',
    FORMAT_DATE_UPPER_JAPAN: 'YYYY年MM月DD日',
    FORMAT_DATE_UPPER_B: 'YYYY-MM-DD',
    FORMAT_DATE_UPPER_DATE_TIME: 'yyyy/MM/dd HH:mm',
    FORMAT_DATE_UPPER_DATE_PICKER: 'yyyy/MM/dd',
    FORMAT_DATE_UPPER_DATE_TIME_B: 'YYYY/MM/DD HH:mm',
    FORMAT_MONTH_DAY_HOUR: 'MM月DD日HH:mm',
    FORMAT_MONTH_DAY: 'MM月DD日',
    FORMAT_DAY_HOUR: 'DD日HH:mm',
    FORMAT_DAY: 'DD日',
    FORMAT_DATE_LOW: 'YYYY/MM/DD',
    FORMAT_DATE_LOW_SUB: 'yyyy-mm-dd',
    DATE_FORMAT: 'YYYY/MM/DD',
    MONTH_FORMAT: 'YYYY/MM',
};
