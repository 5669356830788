export const formatNumber = (value?: string | number) => {
    if (typeof value === 'number') {
        return value.toLocaleString('en-US');
    } else {
        const valueCustom = value
            ?.replace(/[^\w０-９]/g, '')
            ?.replace(/[^\d０-９.%]/g, '')
            ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
        return valueCustom;
    }
};

export const removeSpecialCharacters = (value: string) => {
    let cleanedValue = value?.replace(/[^0-9]/g, '');
    cleanedValue = cleanedValue?.replace(/^0+/, '');
    return cleanedValue;
};
