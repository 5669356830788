import Api from './api/document';

const getListDocument = async (values?: any) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get('', {
                    params: values,
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response) {
                        return response;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const createDocument = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .post('', body, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const deleteDocument = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .delete(`${id}`, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const deleteFileDocument = async (id?: number, signed_id?: string) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const data: any = { signed_file_ids: [signed_id] };
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .request({
                url: `${id}/delete_files`,
                method: 'delete',
                headers: { Authorization: `Bearer ${user.access_token}` },
                data: data,
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const documentsService = {
    getListDocument,
    createDocument,
    deleteDocument,
    deleteFileDocument,
};

export default documentsService;
