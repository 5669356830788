import Api from './api/invoice';

const getListInvoice = async (values?: any) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get('', {
                    params: values,
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response) {
                        return response;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const getDetailInvoice = async (invoice_id?: number, project_id?: number) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(`${invoice_id}`, {
                    params: { project_id: project_id },
                    headers: { Authorization: `Bearer ${user.access_token}` },
                })
                .then((response) => {
                    if (response) {
                        return response;
                    }
                });
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};

const createInvoice = async (formData) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .post('', formData, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const updateInvoice = async (invoice_id: number, formData) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .put(`${invoice_id}`, formData, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const deleteInvoiceItem = async (
    invoice_id: number,
    bill_item_id: number,
    project_id: number,
) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = { project_id: project_id };
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .delete(`${invoice_id}/bill_items/${bill_item_id}`, {
                params: params,
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const deleteInvoice = async (id) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = {
        id: id,
    };
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .delete(`${id}`, {
                headers: { Authorization: `Bearer ${user.access_token}` },
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const deleteFileInvoice = async (
    id?: number,
    signed_file_id?: string,
    invoice_id?: number,
) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const data: any = { signed_file_id: signed_file_id, id: invoice_id };
    if (user.access_token !== undefined && user.access_token !== null) {
        return await Api()
            .request({
                url: `${id}/delete_files`,
                method: 'delete',
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                data: data,
            })
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } else {
        window.location.href = '/login';
    }
};

const invoiceService = {
    getListInvoice,
    deleteInvoiceItem,
    createInvoice,
    deleteInvoice,
    updateInvoice,
    getDetailInvoice,
    deleteFileInvoice,
};

export default invoiceService;
