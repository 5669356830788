import usePrivateMessage, { Props } from './hook';
import { ReceivedProps } from './type';
import { StylesPrivateMessageSP } from './styled';
import { ActionCableConsumer } from 'react-actioncable-provider';
import {
    Box,
    Grid,
    TextField,
    InputAdornment,
    Button,
    Typography,
    Autocomplete,
} from '@mui/material';
import { Search, ChevronRight } from '@mui/icons-material';
import moment from 'moment';
import ChatBoxPrivateMessage from './chatbox';

const PrivateMessageLayoutSP = ({
    ModalPrivateMessage,
    openPrivateMessage,
    closePrivateMessage,
    isOpenPrivateMessage,
    histories,
    activeMessage,
    handleReadChat,
    listUnread,
    workersOptions,
    listMessage,
    setActiveMesssage,
    fetchChats,
    setListMessage,
    setHistories,
    handleReceived,
    userData,
    loading,
}: Props) => {
    return (
        <StylesPrivateMessageSP>
            <ActionCableConsumer
                channel={{
                    channel: 'ChatStatusChannel',
                    user_id: userData.id,
                }}
                onReceived={handleReceived}
            >
                <ModalPrivateMessage>
                    <Box
                        sx={{
                            width: 'calc(100vw - 32px)',
                            borderRadius: '8px',
                            height: '85vh',
                            '.input-search': {
                                border: '1px solid #cdd1d5',
                                borderRadius: '8px',
                                marginBottom: '14.5px',
                                paddingLeft: '14px',
                            },
                            '.MuiTextField-root': {
                                border: '1px solid #cdd1d5',
                                width: '100%',
                                borderRadius: '8px',
                                marginRight: '12.5px',
                            },
                            '.MuiInputBase-input': {
                                height: '35px',
                                padding: '0px 0px 0px 0px !important',
                            },
                            '.scrollbar-container': {
                                overflow: 'scroll !important',
                                overscrollBehavior: 'contain',
                            },
                            '.scrollbar-container::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                        bgcolor="#fff"
                    >
                        {activeMessage ? (
                            <ChatBoxPrivateMessage
                                handleReadChat={handleReadChat}
                                listMessage={listMessage}
                                setActiveMesssage={setActiveMesssage}
                                activeMessage={activeMessage}
                                loading={loading}
                                fetchChats={fetchChats}
                                setListMessage={setListMessage}
                                setHistories={setHistories}
                                setActiveMessage={setActiveMesssage}
                            />
                        ) : (
                            <Box padding="15px 15px 0px 15px" height="100%">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={workersOptions}
                                    sx={{
                                        padding: '0px',
                                        '.MuiInputBase-root': {
                                            padding: '5px 12px !important',
                                        },
                                        '.MuiAutocomplete-endAdornment': {
                                            display: 'none',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '20px',
                                        },
                                    }}
                                    onChange={(event, newValue: any) => {
                                        const workerMessage = histories?.find(
                                            (history) =>
                                                history?.workers?.[1]?.id ===
                                                newValue.value,
                                        );
                                        if (workerMessage?.id) {
                                            setActiveMesssage(workerMessage);
                                        } else {
                                            setActiveMesssage({
                                                workerId: newValue.value,
                                                workerName: newValue.label,
                                            });
                                        }
                                    }}
                                    noOptionsText="該当なし"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            sx={{
                                                                color: '#000',
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label=""
                                        />
                                    )}
                                />
                                <Box
                                    display="flex"
                                    marginTop="10px"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        sx={{
                                            backgroundColor: '#215493',
                                            width: '100px',
                                            height: '32px',
                                            borderRadius: '8px',
                                        }}
                                        variant="contained"
                                    >
                                        検索
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        overscrollBehavior: 'contain',
                                    }}
                                    overflow="scroll"
                                    height="calc(100% - 82px)"
                                >
                                    {histories?.map((history) => (
                                        <Box
                                            key={history.id}
                                            onClick={() =>
                                                setActiveMesssage(history)
                                            }
                                            borderBottom="1px solid #CDD1D5"
                                            className="cursor-pointer history flex items-center justify-between"
                                            padding="8px"
                                        >
                                            <Box>
                                                <Typography className="!text-[18px]">
                                                    {
                                                        history?.workers?.find(
                                                            (worker) =>
                                                                worker.id !==
                                                                userData.worker
                                                                    .id,
                                                        )?.name
                                                    }
                                                </Typography>
                                                <Typography
                                                    className={`text-xs !text-[#333] ${listUnread
                                                        ?.filter(
                                                            (item) =>
                                                                item.has_unread_chat,
                                                        )
                                                        .find(
                                                            (item) =>
                                                                item.direct_chat_id ===
                                                                history.id,
                                                        ) && '!font-bold'
                                                        }`}
                                                >
                                                    {history?.last_message}
                                                </Typography>
                                                <Typography className="!text-xs !text-[#666]">
                                                    {history?.last_message_published_at
                                                        ? moment(
                                                            history?.last_message_published_at,
                                                        )
                                                            ?.zone('+09:00')
                                                            .format(
                                                                'YYYY/MM/DD HH:mm:ss',
                                                            )
                                                        : ''}
                                                </Typography>
                                            </Box>
                                            <ChevronRight
                                                sx={{ color: '#215493' }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </ModalPrivateMessage>
                {/* <img
                    onClick={() => openPrivateMessage()}
                    src={
                        listUnread?.filter((item) => item?.has_unread_chat)
                            ?.length > 0
                            ? '/img/chat-noti.svg'
                            : '/img/chat.svg'
                    }
                    className="message-icon"
                    alt="chat"
                /> */}
            </ActionCableConsumer>
        </StylesPrivateMessageSP>
    );
};

const PrivateMessageSP = (props: ReceivedProps) => {
    return <PrivateMessageLayoutSP {...usePrivateMessage(props)} />;
};

export default PrivateMessageSP;
