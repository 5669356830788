import { Spin } from 'antd';

const LoadingSpin = () => {
    return (
        <>
            <div className="flex justify-center items-center h-[90vh]">
                <Spin size={'large'} />
            </div>
        </>
    );
};
export default LoadingSpin;
