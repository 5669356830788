import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import LoginVerify from './pages/Login/LoginVerify';
import { Provider, useSelector } from 'react-redux';
import store, { RootState } from '../store';
import './style.scss';
import WebTop from './pages/WebTop';
import SPQuoteForm from 'pages/spQuote/form';
import Header2 from './components/Header2';
import SPQuoteHistory from 'pages/spQuote/history';
import SPContact from 'pages/spContact';
import SPQuoteDetail from 'pages/spQuote/detail';
import SPCustomerInfoForm from 'pages/spCustomerInfo/form';
import SPInvoiceDetail from 'pages/spInvoice';
import SPPaymentForm from 'pages/spPayment/form';
import SPRepairForm from 'pages/spRepair/form';
import SpBill from 'pages/spBill';
import SPBillDetail from 'pages/spBill/spBillDetail';
import SPEstimateDetail from 'pages/spEstimate';

const PrivateRoute = ({ isAuthenticated, children }) => {
    if (!isAuthenticated) {
        window.location.href = `${process.env.REACT_APP_SERVER_API_LINE}/auth/line`
    }
    return children;
}

const LineApp = () => {
    const accessToken = localStorage.getItem('line:accessToken');
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);
    const isAllowPage = accessToken && isAuthenticated

    useEffect(() => {
        if (accessToken) {
            fetch(`${process.env.REACT_APP_SERVER_API_LINE}/auth/verify-current-user`, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then(({ current_user }) => {
                    if (current_user) {
                        localStorage.setItem('line:accessToken', accessToken);
                    } else {
                        localStorage.removeItem('line:accessToken');
                    }
                });
        }
    }, [accessToken, window.performance.navigation.type == performance.navigation.TYPE_RELOAD]);

    return (
        <Provider store={store}>
            <Routes>

                <Route
                    path="/line"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <WebTop />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/quote/create"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPQuoteForm screenType="create" />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/sp/repair/create"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPRepairForm screenType="create" />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/sp/repair/edit/:id"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPRepairForm screenType="edit" />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/sp/quote/payment/:id"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPPaymentForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/quote/history"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPQuoteHistory />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/contact"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPContact />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/quote/detail/:id"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPQuoteDetail />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/list-bill/:id"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SpBill />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/bill/detail/:idBill"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPBillDetail />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/sp/invoice/:id"
                    element={
                        <>
                            <Header2 />
                            <SPInvoiceDetail />
                        </>
                    }
                />
                <Route
                    path="/sp/estimate/:id"
                    element={
                        <>
                            <Header2 />
                            <SPEstimateDetail />
                        </>
                    }
                />
                <Route
                    path="/sp/quote/edit/:id"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPQuoteForm screenType="edit" />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/line/customer-info"
                    element={
                        <PrivateRoute isAuthenticated={isAllowPage}>
                            <Header2 />
                            <SPCustomerInfoForm />
                        </PrivateRoute>
                    }
                />

                {/* <Route path="/line/login" element={<Login />} /> */}
                <Route path="/line/verify" element={<LoginVerify />} />
            </Routes>
        </Provider>
    );
};

export default LineApp;
