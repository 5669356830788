import { Form } from 'antd';
import { TYPE_HISTORY } from 'pages/project/projectPlan/projectTabs/constant';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import quoteApi from 'services/quoteApi';

interface ITab {
    id: number;
    value: 'all' | 'initial' | 'sending' | 'completed';
    text: string;
    active?: boolean;
}

export type ReceivedProps = Record<never, never>;
const useQuoteHistory = (props: ReceivedProps) => {
    const [form] = Form.useForm();
    const [historyData, setHistoryData] = useState<any[]>([]);
    const [searchText, setSearchText]: any = useState();
    const [selectedStatusBefore, setSelectedStatusBefore]: any = useState([]);
    const [selectedStatusAfter, setSelectedStatusAfter]: any = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [type, setType] = useState('typeQuote')
    let storage = localStorage.getItem('line:accessToken');
    const fetchQuoteHistory = async (pageNum?, text?, statusValue?) => {
        setIsLoading(true);
        try {
            const response = await quoteApi.getQuoteHistory(
                statusValue || '',
                storage,
                pageNum || page,
                text,
            );
            if (response) {
                const { data } = response;
                setTotalPage(data?.total_pages);
                let newData = data.quotes
                if (type === TYPE_HISTORY.repair) {
                    const dataFilter = data?.quotes.filter((item) => item?.type === TYPE_HISTORY.repair)
                    newData = dataFilter
                } else {
                    const dataFilter = data?.quotes.filter((item) => item?.type === null)
                    newData = dataFilter
                }
                if (page < data?.total_pages) {
                    setPage((prevPage) => prevPage + 1);
                } else {
                    setHasMore(false);
                    setPage(1);
                }
                if (pageNum) {
                    setHistoryData(newData);
                } else {
                    let isDup = false;
                    for (let i = 0; i < historyData.length; i++) {
                        if (newData[0].id === historyData[i].id) {
                            isDup = true;
                        }
                    }
                    if (!isDup) {
                        setHistoryData((prevItems) => [
                            ...prevItems,
                            ...newData,
                        ]);
                    }
                }
            }
        } catch (error) {
            setHasMore(false);
            //
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeTab = (key: string) => {
        if (key === '1') {
            setType(TYPE_HISTORY.quote)
        } else {
            setType(TYPE_HISTORY.repair)
        }
    };

    useEffect(() => {
        if (page > 1) {
            setPage(1);
        }
        setHasMore(true);
        setHistoryData([]);
        // if (activeTab.value === "initial") {
        //   fetchQuoteHistory("initial");
        // } else {
        //   fetchQuoteHistory();
        // }
        fetchQuoteHistory(1, searchText, selectedStatusAfter?.join(','));
    }, [searchText, selectedStatusAfter, type]);

    return {
        ...props,
        historyData,
        form,
        selectedStatusBefore,
        isLoading,
        setSelectedStatusBefore,
        searchText,
        hasMore,
        setSearchText,
        selectedStatusAfter,
        setSelectedStatusAfter,
        fetchQuoteHistory,
        type, setType,
        onChangeTab
    };
};

export type Props = ReturnType<typeof useQuoteHistory>;

export default useQuoteHistory;
