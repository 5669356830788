import { axiosClient } from './quoteApi';
import pick from 'lodash/pick';

class billOfProjectApi {
    getBillOfProject = (idToken: any, body: any) => {
        let paramsData = pick(body, [
            'page',
            'per_page',
            'free_text',
            'bill_start_date',
            'bill_end_date',
            'paid_start_date',
            'paid_end_date',
        ]);
        const params = new URLSearchParams(paramsData);
        const url = `/bills/projects`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
            params: params,
        };
        return axiosClient.get(url, config);
    };

    deleteBillOfProject = (idToken: any, invoiceId) => {
        const url = `/bills/projects/${invoiceId}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.delete(url, config);
    };
}
const billOfProject = new billOfProjectApi();
export default billOfProject;
