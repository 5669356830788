import { message, Upload, UploadProps, UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import pdfImg from '../../../../../../assets/icons/img_pdf.jpg';
import { StyledModalInvoice } from '../styled';
import IconRemove from 'assets/icons/ic-bg-remove.png';
import invoiceService from 'services/invoiceService';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import clsx from 'clsx';

const { Dragger } = Upload;

interface UploadPdfProps {
    filePdfList: any;
    setFilePdfList: (data: any) => void;
    isDisabled?: boolean;
    isDetail?: boolean;
    invoice_id?: number;
    isDisabledField?: boolean;
}

const UploadPdf: React.FC<UploadPdfProps> = ({
    filePdfList,
    setFilePdfList,
    isDisabled,
    invoice_id,
    isDetail,
    isDisabledField,
}) => {
    const [listType, setListType]: any = useState([]);

    const [currentRecordFile, setCurrentRecordFile] = useState({});

    const [isOpenDeleteFile, setIsOpenDeleteFile] = useState(false);

    const dragProps: UploadProps = {
        name: 'file',
        beforeUpload(file: RcFile) {
            if (file.type === 'application/pdf') {
                listType.push('pdf');
            }
            const isLE10MB = file.size / 1024 / 1024 <= 10;
            if (!isLE10MB) {
                message.error('ファイルは10MBを超えてはいけません。');
            }

            if (isLE10MB) {
                setFilePdfList((prevFilePdfList: UploadFile[] = []) => {
                    return [...prevFilePdfList, file];
                });
            }
            return isLE10MB || Upload.LIST_IGNORE;
        },
        onChange(info: any) {
            // const filteredPdfAfterRemove = filePdfList?.filter((bItem) =>
            //     info?.fileList?.some((aItem) => aItem.uid === bItem.uid),
            // );
            // setFilePdfList([...filePdfList, filteredPdfAfterRemove]);
        },
        customRequest({ file, onSuccess }) {
            setTimeout(() => {
                if (onSuccess) {
                    onSuccess('ok');
                }
            }, 0);
        },
    };

    const showOpenDeleteFile = () => {
        setIsOpenDeleteFile(true);
    };

    const handleCloseDeleteFile = () => {
        setIsOpenDeleteFile(false);
    };

    const handleDeleteFileInvoice = async (
        id?: number,
        signed_file_id?: string,
    ) => {
        try {
            const data = await invoiceService.deleteFileInvoice(
                id,
                signed_file_id,
                invoice_id,
            );
            if (data) {
                alertSuccessPopup({
                    title: '削除が完了しました。',
                    confirmButtonText: '閉じる',
                });
                handleCloseDeleteFile();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    const handleRemoveFile = (file: {
        uid?: string;
        id?: number;
        signed_id?: string;
    }) => {
        setFilePdfList((prevFilePdfList: { uid?: string; id?: number }[]) => {
            return prevFilePdfList.filter(
                (item) => item.uid !== file.uid || item.id !== file.id,
            );
        });
        if (file.id) {
            handleDeleteFileInvoice(invoice_id, file?.signed_id);
        }
        handleCloseDeleteFile();
    };
    return (
        <>
            <div className="w-[50%] h-fit">
                {!isDetail && (
                    <Dragger
                        {...dragProps}
                        multiple={true}
                        accept=".pdf"
                        showUploadList={false}
                        className="w-full h-fit"
                        name="file_list"
                    >
                        <div className="flex p-2 items-center justify-between h-fit">
                            <div className="flex items-center">
                                <div className="mr-2">
                                    <img
                                        src={cloudUploadIcon}
                                        alt="cloud upload icon"
                                    />
                                </div>
                                <div className="text-left">
                                    <p className="ant-upload-text text-sm">
                                        ファイルを選択してください
                                    </p>
                                    <p className="ant-upload-hint text-xs">
                                        10MB以下のPDF、を対応します。
                                    </p>
                                </div>
                            </div>
                            <div className="text-[#215493] !text-sm border-solid border-[1px] border-[#215493] rounded flex justify-center items-center w-[60px] h-[46px]">
                                選択
                            </div>
                        </div>
                    </Dragger>
                )}
                {filePdfList?.length > 0 && (
                    <div className="w-full mt-4">
                        <div className="grid grid-cols-3 max-md:grid-cols-2 w-full gap-[10px]">
                            {filePdfList.map((file: any, index: number) => {
                                const size = !file?.id
                                    ? Math.round(file.size / 1024) + 'KB'
                                    : file?.size;
                                const file_name = !file?.id
                                    ? file?.name
                                    : file?.file_name;
                                return (
                                    <a
                                        target="blank"
                                        href={file?.file_path}
                                        className="no-underline"
                                    >
                                        <div
                                            key={index}
                                            className="flex items-center gap-[6px] w-full py-2 px-3 bg-[#f8f8f8] rounded-lg"
                                        >
                                            <div>
                                                <img src={pdfImg} />
                                            </div>
                                            <div>
                                                <div className="text-[10px] font-normal line-clamp-1 max-w-[70px] text-[black]">
                                                    {file_name?.replace(
                                                        '.pdf',
                                                        '',
                                                    ) + '. PDF'}
                                                </div>
                                                <div className="text-[8px] text-[#0000008C] line-clamp-1 max-w-[70px] font-normal">
                                                    {size}
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    className={clsx(
                                                        'text-[10px] text-white w-9 rounded-[3px] h-[19px] bg-[#ff5045]',
                                                        {
                                                            'cursor-no-drop':
                                                                isDetail ||
                                                                (isDisabledField &&
                                                                    file?.id),
                                                        },
                                                    )}
                                                    onClick={(event) => {
                                                        event?.preventDefault();
                                                        setCurrentRecordFile(
                                                            file,
                                                        );
                                                        showOpenDeleteFile();
                                                    }}
                                                    type="button"
                                                    disabled={
                                                        isDetail ||
                                                        (isDisabledField &&
                                                            file?.id)
                                                    }
                                                >
                                                    削除
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <StyledModalInvoice
                className="!p-0"
                open={isOpenDeleteFile}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleCloseDeleteFile}
                centered
                width={462}
            >
                <div
                    className="modal-create-user !w-full d-flex flex-column"
                    style={{ height: '243px' }}
                >
                    <img src={IconRemove} />
                    <div className="modal-create-user-title d-flex flex-row mt-4 font-semibold text-2xl">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row !border-[#e4e4e4] !rounded-[3px] !h-9"
                            onClick={handleCloseDeleteFile}
                            type="button"
                        >
                            いいえ
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row !bg-[#d83535] !rounded-[3px] !h-9"
                            onClick={() => {
                                handleRemoveFile(currentRecordFile);
                            }}
                            type="button"
                        >
                            はい
                        </button>
                    </div>
                </div>
            </StyledModalInvoice>
        </>
    );
};
export default UploadPdf;
