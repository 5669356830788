import axios from 'axios';
import queryString from 'query-string';
import { alertPopup } from 'utils/helper/appHelper';
const quoteUrl = process.env.REACT_APP_SERVER_API;
const lineToken = localStorage.getItem('line:accessToken');

export const axiosClient = axios.create({
    baseURL: quoteUrl,
    headers: {
        'content-type': 'application/json',
        'Device-Type': 'Webapp',
        version: 9999,
        Authorization: `Bearer ${lineToken}`,
    },
    // paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        // Handle errors
        let errorMessage = error;
        if (error.response) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('line:accessToken');
                window.location.href = `${process.env.REACT_APP_SERVER_API_LINE}/auth/line`;
            }
            errorMessage =
                error.response.data?.message || error.response.data?.messages;
        }
        const err = new Error(errorMessage);
        if (err.message.includes('Network Error')) {
            err.message = 'ネットワークエラー';
            alertPopup({
                type: 'error',
                title: 'ネットワークエラー',
                confirmButtonText: 'キャンセル',
            });
        }
        err.name = '';
        throw err;
    },
);

class ProjectApi {
    createProject = (idToken: any, body: any) => {
        const url = 'projects';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.post(url, body, config);
    };

    createProjectEstimated = (idToken: any, body: any) => {
        const url = 'estimates';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.post(url, body, config);
    };

    getDetailProjectEstimated = (idToken: any, id: any, project_id) => {
        const url = `estimates/${id}?project_id=${project_id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.get(url, config);
    };

    editProjectEstimated = (idToken: any, body: any, id) => {
        const url = `estimates/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.put(url, body, config);
    };

    deleteProjectEstimated = (idToken: any, id, item_id, project_id) => {
        const url = `estimates/${id}/estimate_items/${item_id}?project_id=${project_id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.delete(url, config);
    };

    deleteFileEstimated = (idToken: any, id, file_id) => {
        const url = `estimates/${id}/delete_files?signed_file_id=${file_id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.delete(url, config);
    };

    createProjectOrders = (idToken: any, body: any) => {
        const url = 'orders';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.post(url, body, config);
    };

    getDetailProjectOrders = (idToken: any, id: any, project_id) => {
        const url = `orders/${id}?project_id=${project_id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.get(url, config);
    };

    editProjectOrders = (idToken: any, body: any, id) => {
        const url = `orders/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.put(url, body, config);
    };

    editProject = (idToken: any, body: any, id) => {
        const url = `projects/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.put(url, body, config);
    };

    getProject = (idToken: any, params: any) => {
        const qs = queryString.stringify(params);
        const url = `projects?${qs}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.get(url, config);
    };

    getProjectDetail = (idToken: any, id: any) => {
        const url = `projects/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.get(url, config);
    };

}
const projectApi = new ProjectApi();
export default projectApi;
