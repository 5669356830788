import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import WorkingTimeService from 'services/workingTimesService';
import moment from 'moment';
import { alertError } from 'utils/helper/appHelper';
import departmentsService from 'services/departmentsService';
import PositionsService from 'services/positionService';
import workersService from 'services/wokersService';

const useWorkingTimesChart = (props: any) => {
    const [workingTimeData, setWorkingTimeData] = useState({});
    const [departmentOptions, setDepartmentOptions] = useState<any>([]);
    const [positionOptions, setPositionOptions] = useState<any>([]);
    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [isOpenDepartment, setIsOpenDepartment] = useState(false);
    const [isOpenWorker, setIsOpenWorker] = useState(false);
    const [isOpenPosition, setIsOpenPosition] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            year: (() => {
                const currentTime = new Date();
                return currentTime.getFullYear();
            })(),
            month: (() => {
                const currentTime = new Date();
                return currentTime.getMonth() + 1;
            })(),
            data_type: 'attendance',
            time_range: 'weekly',
            week_number: '1',
            free_word: '',
            department_id: [],
            position_id: [],
            worker_id: {
                name: '',
                id: '',
            },
        },
        onSubmit: (values) => fetchWorkingTime(values),
    });

    const getWeekStartDays = (values) => {
        const startOfMonth = moment(`${values?.year}/${values?.month}`).startOf(
            'month',
        );
        const endOfMonth = startOfMonth.clone().endOf('month');
        if (values?.time_range === 'monthly') {
            return {
                start_date: startOfMonth.format('YYYY-MM-DD'),
                end_date: endOfMonth.format('YYYY-MM-DD'),
            };
        } else {
            let currentWeek = startOfMonth
                .clone()
                .add(values.week_number, 'week')
                .startOf('week');
            if (currentWeek.isSameOrBefore(endOfMonth)) {
                const result = currentWeek?.clone();
                return {
                    start_date: result
                        .startOf('week')
                        .add(1, 'day')
                        .format('YYYY-MM-DD'),
                    end_date: result
                        .endOf('week')
                        .add(1, 'day')
                        .format('YYYY-MM-DD'),
                };
            }
        }
    };

    const fetchWorkingTime = async (values: any = {}) => {
        const data = getWeekStartDays(values);
        // setLoading(true);
        if (!data) {
            setWorkingTimeData([]);
            setLoading(false);
            return;
        }
        try {
            const params = {
                ...data,
                attribute_department_ids:
                    values?.department_id?.map(
                        (department) => department.value,
                    ) || [],
                attribute_position_ids:
                    values?.position_id?.map(
                        (department) => department.value,
                    ) || [],
                worker_id: values?.worker_id?.value || '',
                free_word: values.free_word,
            };
            const { working_time_managements }: any =
                await WorkingTimeService.getWorkingTimeWorker(params);
            setWorkingTimeData(
                working_time_managements.reduce(
                    (a, v) => ({
                        ...a,
                        [v.name]: v.total_working_times.toString(),
                    }),
                    {},
                ),
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const { departments } =
                await departmentsService.getListDepartments();
            setDepartmentOptions(
                departments?.map((department) => ({
                    name: department?.name,
                    label: department?.name,
                    value: department?.id,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchPositions = async () => {
        try {
            const { positions } = await PositionsService.getListPositions();
            setPositionOptions(
                positions.map((item) => ({
                    name: item?.name,
                    label: item?.name,
                    value: item?.id,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkerOptions = async () => {
        try {
            const { workers } = await workersService.getListWorkers();
            setWorkerOptions(
                workers.map((item) => ({
                    name: item?.name,
                    label: item?.name,
                    value: item?.id,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         console.log('Latitude is :', position.coords.latitude);
    //         console.log('Longitude is :', position.coords.longitude);
    //     });
    // }, []);

    useEffect(() => {
        fetchDepartments();
        fetchPositions();
        fetchWorkerOptions();
    }, []);

    useEffect(() => {
        fetchWorkingTime(formik.values);
    }, []);
    return {
        ...props,
        formik,
        workingTimeData,
        positionOptions,
        workerOptions,
        departmentOptions,
        loading,
        isOpenDepartment,
        setIsOpenDepartment,
        isOpenPosition,
        setIsOpenPosition,
        isOpenWorker,
        setIsOpenWorker,
    };
};

export type Props = ReturnType<typeof useWorkingTimesChart>;

export default useWorkingTimesChart;
