import { StylesWorkingTimes, StylesDialog6Months } from './styled';
import useWorkingTimes, { Props } from './hook';
import {
    Autocomplete,
    Button, Dialog,
    DialogContent,
} from '@mui/material';
import { useModal } from 'react-hooks-use-modal';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import plus from '../../assets/icons/Plus.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Controller } from 'react-hook-form';
import Dropdown from 'react-dropdown';
import SelectSearch from 'react-select-search';
import { handleFilter } from 'utils/helper/filterDropdown';
import { TextField, InputAdornment, Box } from '@mui/material';
import { MoveDownOutlined, Search } from '@mui/icons-material';
import RedTrash from 'assets/icons/Trash.svg';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import EyeIcon from 'assets/icons/Eye.svg';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { Worker, Viewer, LocalizationMap } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
import jp_JP from '@react-pdf-viewer/locales/lib/jp_JP.json';
import { REACT_APP_WORKER_URL } from 'pages/project/projectPlan/projectTabs/constant';
import CustomPagination from 'components/CustomPagination';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const WorkingTimesLayout = ({
    formik,
    workingTimeData,
    positionOptions,
    workerOptions,
    departmentOptions,
    loading,
    isOpenDepartment,
    setIsOpenDepartment,
    isOpenPosition,
    setIsOpenPosition,
    isOpenWorker,
    setIsOpenWorker,
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
    getValues,
    errors,
    onSearch,
    navigate,
    workTimeList, setWorkTimeList,
    openDelete,
    closeDelete,
    isOpenDelete,
    handleOpenDelete,
    handleDelete,
    ModalDelete,
    total,
    isShowPopup,
    setIsShowPopup,
    month,
    setMonth,
    getPdfWorkingTime,
    idWorker,
    setIdWorker,
    srcPDF,
    setSrcPdf,
    isErrorDate,
    setIsErrorDate,
    isShowPopup6Months,
    setIsShowPopup6Months,
    startMonth,
    setStartMonth,
    setIdWorker6Months,
    getPdfWorkingTime6Months,
    srcPDF6Months,
    setSrcPdf6Months,
    disabledBtnSubmit6Months,
    setDisabledBtnSubmit6Month,
    isErrorStartMonth,
    setIsErrorStartMonth,
    isErrorWorker6Months,
    setIsErrorWorker6Month,
    isErrorWorker,
    setIsErrorWorker,
    disabledBtnSubmit1Month,
    setDisabledBtnSubmit1Month,
    pageQueries,
    setPageQueries,
    totalCount,
    page,
    statusPrev,
    statusNext,
    setPagePrev,
    setPageNext,
    totalPage,
    setPage
}: Props) => {
    // const isValidDateFilter = isValidDate(formik?.values?.start_date, formik?.values?.end_date);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const renderContentPreview = (src) => {
        if (!src) return;
        return <Worker workerUrl={REACT_APP_WORKER_URL}>
            <Viewer
                fileUrl={src}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
                localization={jp_JP as unknown as LocalizationMap}
                defaultScale={SpecialZoomLevel.PageWidth}
            />
        </Worker>
    }
    return (
        <StylesWorkingTimes className="container-worker d-flex flex-row">
            <StylesDialog6Months
                onClose={() => { setIsShowPopup(false), setIsErrorDate(true), setMonth(''), setIdWorker(0), setIsErrorWorker(true) }}
                sx={{
                    width: '100%',
                    '.MuiPaper-root': {
                        width: "unset",
                        height: "unset",
                        margin: '0px',
                        minHeight: '250px',
                        minWidth: '350px'
                    },

                    '.rpv-core__inner-container': {
                        paddingBottom: '30px !important',
                    },
                }}
                open={isShowPopup}
            >
                <DialogContent>
                    <div className='filterBoxTop'>
                        <span>社員<span className='text-[#f44336]'>*</span></span>
                        <div className='wrapSelectField'>
                            <Autocomplete
                                className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] bg-[#f3f3f3]"
                                noOptionsText="該当なし"
                                onChange={(event, newValue: any) => {
                                    if (newValue?.value) {
                                        setIdWorker(newValue?.value);
                                        setIsErrorWorker(false)
                                    } else {
                                        setIsErrorWorker(0);
                                        setIsErrorWorker(true)
                                    }

                                }}
                                options={workerOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            //clearIcon={<CloseIcon fontSize="small" />}
                            />
                        </div>
                    </div>
                    <div>
                        <span>期間<span className='text-[#f44336]'>*</span></span>
                        <DatePicker
                            className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] px-[12px] py-[4px] bg-[#f3f3f3]"
                            value={month}
                            picker="month"
                            onChange={(val) => {
                                if (val) {
                                    setMonth(val)
                                    setIsErrorDate(false)
                                } else {
                                    setMonth(null)
                                    setIsErrorDate(true)
                                }
                            }}
                            format='YYYY/MM'
                            autoComplete="off"
                        />
                    </div>

                    {/*{isErrorDate && <p className='flex justify-end text-[#f44336] mb-0'>必須です。</p>}*/}
                    <div className='flex items-center justify-end mt-[8px]'>
                        <button
                            type="submit"
                            className="bg-main px-[30px] py-[4px] rounded-[6px] text-white h-[31px] mt-[5px]"
                            onClick={() => { getPdfWorkingTime(idWorker, new Date(month)?.getMonth() + 1, new Date(month)?.getFullYear()) }}
                            disabled={isErrorWorker || isErrorDate || disabledBtnSubmit1Month}
                        >
                            OK
                        </button>
                    </div>
                </DialogContent>
            </StylesDialog6Months>
            <StylesDialog6Months
                onClose={() => { setIsShowPopup6Months(false), setStartMonth(''), setIdWorker6Months(0), setIsErrorStartMonth(true), setIsErrorWorker6Month(true) }}
                sx={{
                    width: '100%',
                    '.MuiPaper-root': {
                        width: "unset",
                        margin: '0px',
                        minWidth: '350px'
                    },

                    '.rpv-core__inner-container': {
                        paddingBottom: '30px !important',
                    },
                }}
                open={isShowPopup6Months}
            >
                <DialogContent>
                    <div className='filterBoxTop'>
                        <span>社員<span className='text-[#f44336]'>*</span></span>
                        <div className='wrapSelectField'>
                            <Autocomplete
                                className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] bg-[#f3f3f3]"
                                noOptionsText="該当なし"
                                onChange={(event, newValue: any) => {
                                    if (newValue?.value) {
                                        setIdWorker6Months(newValue?.value);
                                        setIsErrorWorker6Month(false)
                                    } else {
                                        setIdWorker6Months(0);
                                        setIsErrorWorker6Month(true)
                                    }

                                }}
                                options={workerOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <span>期間<span className='text-[#f44336]'>*</span></span>
                        <DatePicker
                            className="w-full border-[1px] border-[#f3f3f3] rounded-[6px] px-[12px] py-[4px] bg-[#f3f3f3]"
                            picker="month"
                            value={startMonth}
                            onChange={(val) => {
                                if (val) {
                                    setStartMonth(val);
                                    setIsErrorStartMonth(false)
                                } else {
                                    setStartMonth('');
                                    setIsErrorStartMonth(true)
                                }
                            }}
                            format='YYYY/MM'
                            autoComplete="off"
                        />
                    </div>
                    <div className='flex items-center justify-end mt-[12px]'>
                        <button
                            type="submit"
                            className="bg-main px-[30px] py-[4px] rounded-[6px] text-white h-[31px] mt-[5px]"
                            onClick={getPdfWorkingTime6Months}
                            disabled={disabledBtnSubmit6Months || isErrorStartMonth || isErrorWorker6Months}
                        >
                            OK
                        </button>
                    </div>
                </DialogContent>
            </StylesDialog6Months>
            {srcPDF && (
                <Dialog
                    onClose={() => setSrcPdf(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                            minWidth: '75vw'
                        },

                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!srcPDF}
                >
                    <DialogContent>
                        {
                            renderContentPreview(srcPDF)
                        }
                    </DialogContent>
                </Dialog>
            )}
            {srcPDF6Months && (
                <Dialog
                    onClose={() => setSrcPdf6Months(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: "100%",
                            height: "100%",
                            margin: '0px',
                            minWidth: '75vw'
                        },

                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!srcPDF6Months}
                >
                    <DialogContent>
                        {
                            renderContentPreview(srcPDF6Months)
                        }
                    </DialogContent>
                </Dialog>
            )}
            <div className="worker-content d-flex flex-column">
                <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button !rounded-[4px] d-flex flex-row"
                        onClick={() => { setIsShowPopup6Months(true) }}
                    >
                        出勤簿 PDF
                    </button>
                    <button
                        onClick={() => { setIsShowPopup(true) }}
                        className="create-user-button !rounded-[4px] mx-[15px] d-flex flex-row"
                    >
                        PDF
                    </button>
                    <button
                        className="create-user-button !rounded-[4px] d-flex flex-row"
                        onClick={() => navigate('/working_times_management/create')}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="search-workers d-flex flex-column">
                        <div className="search-workers-row d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>部署</p>
                                    <Controller
                                        name="department_id"
                                        control={control}
                                        render={({ field }) => (
                                            // <Dropdown
                                            //     className="dropdown-create-user1 height36"
                                            //     controlClassName="dropdown-control-user height36"
                                            //     options={departmentOptions}
                                            //     {...field}
                                            //     placeholder=""
                                            // />
                                            <Select
                                                options={departmentOptions}
                                                showSearch
                                                mode="multiple"
                                                {...register(
                                                    'department_id'
                                                )}
                                                {...field}
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                suffixIcon={MoveDownOutlined}
                                                className="!w-full !h-[40px]"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>役職</p>
                                    <Controller
                                        name="position_id"
                                        control={control}
                                        render={({ field }) => (
                                            // <Dropdown
                                            //     className="dropdown-create-user1 height36"
                                            //     controlClassName="dropdown-control-user height36"
                                            //     options={positionOptions}
                                            //     {...field}
                                            //     placeholder=""
                                            // />
                                            <Select
                                                options={positionOptions}
                                                showSearch
                                                mode="multiple"
                                                {...register(
                                                    'position_id'
                                                )}
                                                {...field}
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                className="!w-full !h-[40px]"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>社員</p>
                                    <Controller
                                        name="worker_id"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="wrapSelectField !w-[400px]">
                                                <Select
                                                    options={workerOptions}
                                                    showSearch
                                                    {...register(
                                                        'worker_id'
                                                    )}
                                                    {...field}
                                                    filterOption={(input, option: any) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    className="!w-full !h-[40px]"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-workers-row d-flex flex-row">

                            <div className="search-workers-row-dropdown w-[98%] d-flex flex-column">
                                <Box
                                    sx={{
                                        width: '98%',
                                        '.input-search': {
                                            border: '1px solid #cdd1d5',
                                            borderRadius: '8px',
                                            marginBottom: '14.5px',
                                            paddingLeft: '14px',
                                        },
                                        '.MuiTextField-root': {
                                            border: '1px solid #cdd1d5',
                                            width: '98%',
                                            borderRadius: '8px',
                                            marginRight: '12.5px',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '35px',
                                            padding: '0px !important',
                                        },
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: 0,
                                            marginBottom: '4px',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#344054',
                                        }}
                                    >
                                        キーワード
                                    </p>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        sx={{ color: '#000' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        name="free_word"
                                        style={{ borderRadius: '2px', width: '98%' }}
                                        onChange={(e) => {
                                            setValue(
                                                'free_word',
                                                e.target.value,
                                            );
                                        }}
                                    />
                                </Box>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <p style={{ marginBottom: 0 }}>作成日</p>

                                <div className="flex flex-row mt-[2px] !w-full flex-nowrap">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <div className='w-[50%]' >
                                                <DatePicker
                                                    {...register(
                                                        'date',
                                                    )}
                                                    {...field}
                                                    format={'YYYY年MM月DD日'}
                                                    // onBlur={handleValidateWhitespace}
                                                    value={
                                                        field.value
                                                            ? moment(field.value)
                                                            : null
                                                    }
                                                    placeholder="yyyy年mm月dd日"
                                                    style={{
                                                        width: '100%',
                                                        height: '35px',
                                                    }}
                                                />
                                                <br />
                                                {errors.date && (
                                                    <span className="error">
                                                        {errors.date.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                    <div className="w-[5%] text-center" >
                                        ~
                                    </div>
                                    <Controller
                                        name="end_date"
                                        control={control}
                                        render={({ field }) => (
                                            <div className='w-[50%]'>
                                                <DatePicker
                                                    {...register(
                                                        'end_date',
                                                    )}
                                                    {...field}
                                                    format={'YYYY年MM月DD日'}
                                                    // onBlur={handleValidateWhitespace}
                                                    value={
                                                        field.value
                                                            ? moment(field.value)
                                                            : null
                                                    }
                                                    placeholder="yyyy年mm月dd日"
                                                    style={{
                                                        width: '100%',
                                                        height: '35px',
                                                    }}
                                                />
                                                <br />
                                                {errors.end_date && (
                                                    <span className="error">
                                                        {errors.end_date.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="w-[33.33%] mt-[25px] margin-left d-flex flex-column">
                                <button
                                    className="search-user-button d-flex flex-column"
                                    style={{
                                        width: '150px',
                                        height: '36px',
                                        marginBottom: '12px',
                                        alignItems: 'flex start'
                                    }}
                                >
                                    検索
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* <div className='table-workers d-flex flex-column'> */}
                <div className="table-content d-flex flex-column">
                    <TableContainer component={Paper} table-layout={'auto'}>
                        <Table
                            sx={{ minWidth: 1200 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div>名前</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '15%' }}
                                    >
                                        <div
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            日付
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            出勤時間
                                        </div>
                                    </StyledTableCell>
                                    {/* <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '20%' }}
                                    >
                                        <div>位置</div>
                                    </StyledTableCell> */}
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '20%' }}
                                    >
                                        <div>退勤時間</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{
                                            width: '20%',
                                        }}
                                    >
                                        <div>合計労働時間</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{
                                            width: '10%',
                                        }}
                                    >
                                        <div>操作</div>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workTimeList !== null &&
                                    workTimeList.map((row, ind) => {
                                        return (
                                            <StyledTableRow key={ind}>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.worker_name}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    {/* {row.kana} */}
                                                    <div className="text-overflow1">
                                                        {moment(row.submit_date).format('YYYY-MM-DD')}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%', whiteSpace: 'nowrap' }}
                                                >
                                                    <span className={`vehicle-name ${row?.is_manual_checkin ? 'text-[#f44336]' : ''}`}>
                                                        {moment(row?.check_in_time)?.format('YYYY/MM/DD h:mm a')}
                                                    </span>
                                                    {/* <div className="text-overflow1">
                                                        {moment(row.start_time).format('HH:mm')}
                                                    </div> */}
                                                </StyledTableCell>
                                                {/* <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.checkin_location}
                                                    </div>
                                                </StyledTableCell> */}
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    {/* <div className="text-overflow1">
                                                        {moment(row.end_time).format('HH:mm')}
                                                    </div> */}
                                                    <span className={`vehicle-name ${row?.is_manual_checkout ? 'text-[#f44336]' : ''}`}>
                                                        {moment(row?.check_out_time)?.format('YYYY/MM/DD h:mm a')}
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        <span className="vehicle-name">{row.total_time || '0'}</span>
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '25%' }}
                                                >
                                                    <div className="flex justify-start items-start gap-[10px]">
                                                        <button
                                                            onClick={() =>
                                                                navigate(
                                                                    `detail/${row?.uid}?worker_id=${row?.worker_id}`,
                                                                    {
                                                                        state: {
                                                                            submit_date: row?.submit_date,
                                                                            checkin_item_id: row?.checkin_item_id,
                                                                            checkout_item_id: row?.checkout_item_id,
                                                                            worker_id: row?.worker_id
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                            className="search-user-button !w-[72px] d-flex flex-column height36"
                                                            type="button"
                                                        >
                                                            詳細
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                navigate(
                                                                    `edit/${row?.uid}?worker_id=${row?.worker_id}`,
                                                                    {
                                                                        state: {
                                                                            submit_date: row?.submit_date,
                                                                            checkin_item_id: row?.checkin_item_id,
                                                                            checkout_item_id: row?.checkout_item_id,
                                                                            worker_id: row?.worker_id
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                            className="search-user-button !w-[72px] d-flex flex-column height36"
                                                            type="button"
                                                        >
                                                            編集
                                                        </button>
                                                        <button
                                                            onClick={() => handleOpenDelete(row)}
                                                            className="search-user-button !bg-[#FF5045] !w-[72px] d-flex flex-column height36"
                                                            type="button"
                                                        >
                                                            削除
                                                        </button>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <div className="flex justify-center">
                        <CustomPagination
                            totalCount={totalCount || 0}
                            pageQueries={pageQueries}
                            setPageQueries={setPageQueries}
                            fetchList={(page) => console.log(page)}
                        />
                    </div> */}
                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]': statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                    <ModalDelete>
                        <div
                            className="modal-create-user d-flex flex-column"
                            style={{ height: '152px' }}
                        >
                            <div className="modal-create-user-title d-flex flex-row">
                                本当に削除しますか?
                            </div>
                            <div
                                className="modal-create-user-buttons d-flex flex-row"
                                style={{ marginTop: '50px' }}
                            >
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeDelete}
                                >
                                    キャンセル
                                </button>
                                <button
                                    onClick={handleDelete}
                                    className="modal-create-user-button2 d-flex flex-row"
                                >
                                    はい
                                </button>
                            </div>
                        </div>
                    </ModalDelete>
                    {/* <WorkerErrorPopup
                        isOpen={!!deleteError}
                        message={deleteError}
                        onClose={() => setDeleteError(null)}
                    /> */}
                </div>
                {/* </div> */}
            </div>
        </StylesWorkingTimes>
    );
};

const WorkingTimes = (props: any) => {
    return <WorkingTimesLayout {...useWorkingTimes(props)} />;
};

export default WorkingTimes;
