import { Form } from 'antd';
import { CustomSearch, RoundSearch } from 'pages/spQuote/history';
import FilterSearch from 'assets/menuIcons/filterSearch';
import { useEffect, useState } from 'react';
import { LIST_BILL_SP, STATUS_INVOICE } from 'utils/constants';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';
import { useNavigate, useParams } from 'react-router-dom';
import billLineService from 'services/billLineService';
import { alertError } from 'utils/helper/appHelper';
import LoadingSpin from 'components/LoadingSpin';

interface SpBillProps {}
interface BillItemProps {
    invoice_name?: string;
    created_at?: string;
    status?: string;
    id?: number;
}

const BillItem: React.FC<BillItemProps> = ({
    invoice_name,
    created_at,
    id,
    status,
}) => {
    const statusFormat = STATUS_INVOICE?.find(
        (item: any) => item.value === status,
    );
    const navigate = useNavigate();
    return (
        <>
            <div
                key={id}
                className="flex justify-between items-center cursor-pointer"
                onClick={() => {
                    navigate(
                        '/sp/bill/detail/:idBill'?.replace(
                            ':idBill',
                            id ? id.toString() : '',
                        ),
                    );
                }}
            >
                <div>
                    <div className="text-sm font-medium">{invoice_name}</div>
                    <div className="text-xs text-[#534e54] font-normal pt-1">
                        {created_at
                            ? dayjs(created_at).format(
                                  FORMAT.FORMAT_DATE_UPPER_JAPAN,
                              )
                            : ''}
                    </div>
                </div>
                {statusFormat && (
                    <div
                        className="rounded-2xl w-[112px] flex justify-center items-center h-6 text-xs font-normal"
                        style={{
                            color: statusFormat?.color,
                            backgroundColor: statusFormat?.bg,
                        }}
                    >
                        {statusFormat?.label}
                    </div>
                )}
            </div>
            <hr className="text-[#d9d9d9] border-t-[1.5px] !my-3" />
        </>
    );
};

const SpBill: React.FC<SpBillProps> = ({}) => {
    const { id } = useParams();

    let storage = localStorage.getItem('line:accessToken');

    const [form] = Form.useForm();

    const [count, setCount] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [isActiveSearch, setIsActiveSearch] = useState(false);

    const [listBill, setListBill] = useState<any>([]);

    const getListBillLine = async () => {
        setIsLoading(true);
        const dataFormat = {
            bill_search: form?.getFieldValue('search'),
            project_id: Number(id),
        };
        try {
            const response = await billLineService.getListBillLine(
                storage,
                dataFormat,
            );
            if (response !== null && response !== undefined) {
                const response_data = response?.data;
                const dataBill = response_data?.bills?.map(
                    (item: any, index: number) => {
                        return {
                            ...item,
                            invoice_name: item?.name ?? '',
                        };
                    },
                );
                setIsLoading(false);
                setListBill(dataBill);
                setIsActiveSearch(false);
            }
        } catch (err) {
            alertError(err);
        }
    };

    const sortPaid = listBill?.sort((a: any, b: any) => {
        if (a?.status === b?.status) {
            return (
                new Date(b?.created_at).getTime() -
                new Date(a?.created_at).getTime()
            );
        }
        return a.status === 'paid' ? -1 : 1;
    });

    const sortUnpaid = listBill?.sort((a: any, b: any) => {
        if (a?.status === b?.status) {
            return (
                new Date(b?.created_at)?.getTime() -
                new Date(a?.created_at)?.getTime()
            );
        }
        return a.status === 'unpaid' ? -1 : 1;
    });

    useEffect(() => {
        getListBillLine();
    }, [isActiveSearch]);

    useEffect(() => {
        if (count !== 0) {
            count % 2 === 0 ? setListBill(sortUnpaid) : setListBill(sortPaid);
        }
    }, [count]);
    return (
        <>
            <div className="px-[10px] rounded-[10px] pt-[10px] flex gap-[10px]">
                <Form form={form} className="w-[100%]">
                    <CustomSearch>
                        <Form.Item name="search">
                            <RoundSearch
                                placeholder="検索"
                                className="w-[301px] !rounded-[10px] success"
                                onSearch={(data: any) => {
                                    setIsActiveSearch(true);
                                    form.setFieldsValue({
                                        search: data?.trim(),
                                    });
                                }}
                            />
                        </Form.Item>
                    </CustomSearch>
                </Form>
                <div
                    className="w-fit"
                    onClick={() => {
                        setCount(count + 1);
                    }}
                >
                    <FilterSearch />
                </div>
            </div>
            {isLoading ? (
                <LoadingSpin />
            ) : (
                <>
                    {listBill?.length > 0 ? (
                        <div className="px-[10px]">
                            {listBill?.map((item, index) => (
                                <BillItem
                                    invoice_name={item?.invoice_name}
                                    created_at={item?.created_at}
                                    status={item?.status}
                                    id={item?.id}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="text-center">データがありません。</div>
                    )}
                </>
            )}
        </>
    );
};

export default SpBill;
