import styled from "styled-components";

export const QuoteHistoryStyles = styled.div`
    // padding: 12px 16px;
    .ant-tabs-nav {
width: 100% !important;
}

.ant-tabs-tab {
display: block; // centers text inside tabs
flex: 1;
text-align: center;
}

.ant-tabs-nav > div:nth-of-type(1) {
display: unset !important;
width: 100% !important;
}

    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    .ant-table-container table > thead > tr:last-child th:last-child {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
    .ant-table-thead {
        .ant-table-cell {
            background-color: #c7dcf5;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &::before {
                display: none;
            }
        }
    }
    .dataText {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ant-space-item {
        span {
            margin: 0px 4px !important;
        }
    }
    .statusFilterWrapper {
      border-bottom: 2px solid rgba(0, 0, 0, 0.15);
      .filterBtn {
        color: rgba(114, 114, 114, 1);
        &.active {
          color: rgba(71, 89, 255, 1);
          border-bottom: 2px solid rgba(71, 89, 255, 1);
        }
      }
    }
`;