import Api from './api/company';
import pick from 'lodash/pick';
import { axiosClient } from './projectApi';

const getListCompanies = async (values?: any) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            if (user.access_token !== undefined && user.access_token !== null) {
                return await Api()
                    .get('', {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                        params: { per_page: values?.per_page },
                    })
                    .then((response) => {
                        if (response.data) {
                            return response.data;
                        }
                    });
            }
        } catch (err) {
            window.location.href = '/login';
            return null;
        }
    } else {
        window.location.href = '/login';
    }
};


const getListProvinces = async (

) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const url = `/cooperative_companies/provinces`;
    const config: any = {
        headers: { Authorization: `Bearer ${user.access_token}` },
    };
    return axiosClient.get(url, config);
};


const CompanyService = {
    getListCompanies,
    getListProvinces
};

export default CompanyService;
