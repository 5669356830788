import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomPagination from 'components/CustomPagination';
import moment from 'moment';
import { useLogicEstimate } from 'pages/project/projectPlan/Estimations/hooks';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';
import calendarIcon from '../../assets/icons/calendar.png';
import estimatesService from '../../services/estimatesService';
import workersService from 'services/wokersService';
import projectsService from 'services/projectsService';
import './worker.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Estimate = (props) => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    const { statusOptions: estimateStatus } = useLogicEstimate();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [estimatesList, setEstimatesList] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 20,
    });
    const [searchedState, setSearchedState] = useState({
        cooperative_company_id: '',
        department_id: undefined,
        free_word: '',
        gender_type: undefined,
        position_id: undefined,
    });

    const [departmentOption, setDepartmentOption] = useState<any>([]);
    const [estimatesDelete, setEstimatesDelete] = useState<any>(null);

    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const fetchListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers({
                page: 1,
                per_page: 0,
            });
            setWorkerOptions(
                response?.workers.map((worker) => ({
                    label: worker.name,
                    value: worker.id,
                })),
            );
        } catch (error) { }
    };

    const fetchListProjects = async () => {
        try {
            const response = await projectsService.getListProjects({
                page: 1,
                per_page: 0,
            });
            setDepartmentOption(
                response?.projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                })),
            );
        } catch (error) {
            console.log(error);
        }
    };
    const onSearch = (data) => {
        setSearchedState({ ...data });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListEstimates({
            page: 1,
            per_page: pageQueries.per_page,
            data: {
                ...data,
                project_id: data?.project_id?.value || '',
                assigned_worker_id: data?.assigned_worker_id?.value || '',
                approver_worker_id: data?.approver_worker_id?.value || '',
                status: data?.status?.value || '',
            },
        });
    };

    const renderStatusItem = (statusProps = '') => {
        const item = estimateStatus.find((i) => i.value === statusProps);
        return item?.label || '';
    };

    const getListEstimates = async ({ page = 1, per_page = 5, data }: any) => {
        try {
            const response = await estimatesService.getListEstimates({
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setEstimatesList(response.estimates);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const deleteEstimate = async () => {
        try {
            const response = await estimatesService.deleteEstimate(
                estimatesDelete?.project?.id,
                estimatesDelete?.id,
            );
            if (response !== null && response !== undefined) {
                if (pageQueries.page !== 1) {
                    setPageQueries((prevState) => ({ ...prevState, page: 1 }));
                }
                getListEstimates({
                    page: 1,
                    per_page: pageQueries.per_page,
                    data: { ...searchedState },
                });
                setEstimatesDelete(null);
                closeDelete();
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        reset({
            cooperative_company_id: '',
        });
    }, []);

    useEffect(() => {
        getListEstimates({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    const handleEndAtChange = (date: any) => {
        setEndDate(date);
    };
    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };

    useEffect(() => {
        fetchListWorkers();
        fetchListProjects();
    }, []);

    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                {/* <div
                    className="create-button d-flex flex-row"
                    style={{ height: '53px' }}
                >
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate('/projects/create')}
                    >
                        <img width={'16px'} height={'16px'} src={plus}></img>
                        新規作成
                    </button>
                </div> */}
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="search-workers d-flex flex-column">
                        <div className="search-workers-row d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>案件名</p>
                                    <Controller
                                        name="project_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...departmentOption,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>見積担当者</p>
                                    <Controller
                                        name="assigned_worker_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...workerOptions,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>承認担当者</p>
                                    <Controller
                                        name="approver_worker_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...workerOptions,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-workers-row d-flex flex-row">
                            <div
                                className="input-add-user d-flex flex-column"
                                style={{ width: '33.33%' }}
                            >
                                <p style={{ marginTop: '1px' }}>作成日</p>
                                <div className="datepicker-bill">
                                    <div className="target-datepicker-wrap d-flex flex-row">
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '50%',
                                            }}
                                        >
                                            <Controller
                                                name="start_at"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={startDate}
                                                        {...field}
                                                        id="start_at"
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            handleStartAtChange(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsStart
                                                        maxDate={endDate}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <label htmlFor="start_at">
                                                <img
                                                    className="input-date-img"
                                                    width={'16px'}
                                                    height={'16px'}
                                                    src={calendarIcon}
                                                ></img>
                                            </label>
                                        </div>
                                        <span className="symbol">~</span>
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '50%',
                                            }}
                                        >
                                            <Controller
                                                name="end_at"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={endDate}
                                                        {...field}
                                                        id="end_at"
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            handleEndAtChange(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsEnd
                                                        minDate={startDate}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <label htmlFor="end_at">
                                                <img
                                                    className="input-date-img"
                                                    width={'16px'}
                                                    height={'16px'}
                                                    src={calendarIcon}
                                                ></img>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div
                                    className="d-flex flex-column input-add-user"
                                    style={{ width: '50%' }}
                                >
                                    <p style={{ marginTop: 0 }}>状態</p>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...estimateStatus,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-button margin-left d-flex flex-column">
                                <button
                                    className="search-user-button d-flex flex-column"
                                    type="submit"
                                    style={{
                                        width: '150px',
                                        height: '36px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    検索
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="table-content d-flex flex-column">
                    <TableContainer component={Paper} table-layout={'auto'}>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div>作成日</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            更新日
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            案件名
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div>見積担当者</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div>承認担当者</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{ width: '10%' }}
                                    >
                                        <div>ステータス</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1"
                                        align="center"
                                        style={{
                                            width: '30%',
                                            minWidth: '300px',
                                        }}
                                    >
                                        <div>操作</div>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {estimatesList !== null &&
                                    estimatesList.map((row, ind) => {
                                        return (
                                            <StyledTableRow key={ind}>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.created_at
                                                            ? moment(
                                                                row.created_at,
                                                            ).format(
                                                                'YYYY/MM/DD',
                                                            )
                                                            : ''}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.updated_at
                                                            ? moment(
                                                                row.updated_at,
                                                            ).format(
                                                                'YYYY/MM/DD',
                                                            )
                                                            : ''}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row?.project?.name}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {
                                                            row?.assigned_worker
                                                                ?.name
                                                        }
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {
                                                            row?.approver_worker
                                                                ?.name
                                                        }
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {renderStatusItem(
                                                            row?.status,
                                                        )}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="center"
                                                    style={{ width: '30%' }}
                                                >
                                                    <button
                                                        className="button-tb"
                                                        style={{
                                                            width: '72px',
                                                            margin: '5px',
                                                        }}
                                                        onClick={() =>
                                                            navigate(
                                                                `/projects/estimation/${row?.project?.id}/edit/${row?.id}?type=detail&from=list`,
                                                            )
                                                        }
                                                    // onClick={() => { navigate('/workers/detail', { state: { id: row.id } }) }}
                                                    >
                                                        詳細
                                                    </button>
                                                    <button
                                                        className="button-tb"
                                                        style={{
                                                            width: '72px',
                                                            margin: '5px',
                                                        }}
                                                        onClick={() =>
                                                            navigate(
                                                                `/projects/estimation/${row?.project?.id}/edit/${row?.id}?from=list`,
                                                            )
                                                        }
                                                    // onClick={() => { navigate(`/workers/edit/${row.id}`) }}
                                                    >
                                                        編集
                                                    </button>
                                                    <button
                                                        className="button-tb"
                                                        style={{
                                                            width: '72px',
                                                            margin: '5px',
                                                            background:
                                                                '#FF5045',
                                                        }}
                                                        onClick={() => {
                                                            openDelete();
                                                            setEstimatesDelete(
                                                                row,
                                                            );
                                                        }}
                                                    >
                                                        削除
                                                    </button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ModalDelete>
                        <div
                            className="modal-create-user d-flex flex-column"
                            style={{ height: '152px' }}
                        >
                            <div className="modal-create-user-title d-flex flex-row">
                                本当に削除しますか?
                            </div>
                            <div
                                className="modal-create-user-buttons d-flex flex-row"
                                style={{ marginTop: '50px' }}
                            >
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeDelete}
                                >
                                    キャンセル
                                </button>
                                <button
                                    onClick={deleteEstimate}
                                    className="modal-create-user-button2 d-flex flex-row"
                                >
                                    はい
                                </button>
                            </div>
                        </div>
                    </ModalDelete>
                </div>
                {/* </div> */}
                {estimatesList && estimatesList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListEstimates({
                                page,
                                per_page: pageQueries.per_page,
                                data: { ...searchedState },
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default Estimate;
