import styled from 'styled-components';
import vector from '../../assets/icons/Vector.png';
import { Dialog } from '@mui/material';

export const StylesWorkingTimes = styled.div`
        .ant-picker-dropdown {
    z-index: 99999 !important;
}
    .ant-select-selector {
    height: 35px !important;
    }
           .select-search {
            .select-search__input {
                border: 1px solid #cdd1d5;
                box-shadow: none;

                &:hover {
                    border-color: #215493;
                }
            }

            .select-search__select {
                .select-search__options {
                    padding-left: 0 !important;
                    .select-search__row {
                        .select-search__option.is-selected {
                            background: #215493 !important;;
                        }
                    }
                }
            }
        }
`;

export const StylesDialog6Months = styled(Dialog)`
    .MuiDialogContent-root {
        min-height: 310px;
    }
        .ant-picker-dropdown {
    z-index: 99999 !important;
}
    .filterBoxTop {
        .wrapSelectField {
            margin: 0 0 12px;
            width: 300px;
            max-width: 100%;

            .MuiFormControl-root {
                .MuiFormHelperText-root {
                    margin-left: 0;
                    color: #d32f2f !important;
                }
            }

            &.multipleSelect {
                .MuiOutlinedInput-root.MuiInputBase-root {
                    height: 30px;


                    .MuiAutocomplete-tag {
                        height: 24px;
                    }

                    .MuiChip-root {
                        .MuiSvgIcon-root {
                            display: initial;
                            font-size: 16px;
                        }
                    }
                }
            }

            .MuiSelect-select {
                font-weight: 400;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-root {
                min-height: 30px;
                background: #f3f3f3;
                position: relative;

                fieldset {
                    border: none;
                }

                .MuiSvgIcon-root {
                    display: none;
                }
            }
            .MuiAutocomplete-root {
                .MuiInputBase-root {
                    padding: 0px 15px;
                }
            }
            .MuiAutocomplete-input {
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
                padding-right: 16px !important;
            }
            .MuiOutlinedInput-root {
                .MuiAutocomplete-input {
                    padding: 0;
                }
            }
        }
        .fieldLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #1c1e21;
            display: block;
            margin: 0 0 4px;
        }

        .wrapSelectField {
            width: 100%;
        }

        .wrapTextField {
            .MuiInputBase-input {
                padding: 3.5px 0px !important;
            }

            textarea {
                background: #f3f3f3;
                border-radius: 4px;
                min-height: 60px;
                height: 60px !important;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input.MuiInputBase-input {
                    background: #f3f3f3;
                    border-radius: 4px;
                    border: 0;
                }
            }
        }
    }
    .MuiAutocomplete-clearIndicator {
        display: inline-block !important;
    }
    button:disabled,
    button[disabled]{
        opacity: 0.6;
    }
    .MuiSvgIcon-root {
        display: block !important;
    }
`;

