import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModalUpload } from '../modal-upload/styled';
import pdfImg from '../../../assets/icons/img_pdf.jpg';
import clsx from 'clsx';

interface File {
    file_path: string;
    file_name: string;
}


const ModalListFile = (props) => {
    const { isOpenListFile, currentRecord, setIsOpenListFile, filePdfList } =
        props;

    const handleClose = () => {
        setIsOpenListFile(false);
    };

    const handleDownloadFilePdf = (filePath?: any, fileName?: any) => {
        if (!filePath || !fileName) {
            console.error('File path or file name is missing');
            return;
        }

        fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    };

    const handleDownloadAllFilePdf = (files: File[]) => {
        files.forEach(({ file_path, file_name }) => {
            handleDownloadFilePdf(file_path, file_name);
        });
    };

    return (
        <>
            <StyledModalUpload
                className="!p-0"
                open={isOpenListFile}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleClose}
                centered
                width={330}
            >
                <Box
                    sx={{
                        background: '#215493',
                        margin: 0,
                        color: 'white',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                        p: 2,
                        fontSize: '16px',
                    }}
                    id="modal-modal-title"
                    component="h2"
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>アップロード</span>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                    </div>
                </Box>
                <Box
                    id="modal-modal-description"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: '15px',
                        width: '100%',
                    }}
                >
                    {currentRecord?.files?.map((file: any, index: number) => {
                        const name = file?.file_name ?? '';
                        return (
                            <div
                                key={index}
                                className={clsx(
                                    'flex items-center justify-between py-2 px-3 bg-[#f8f8f8] w-full rounded-lg max-w-[300px]',
                                    {
                                        'mt-3': index !== 0,
                                    },
                                )}
                            >
                                <div className="flex items-center gap-2">
                                    <div>
                                        <img src={pdfImg} />
                                    </div>
                                    <div>
                                        <div className="text-[10px] font-normal line-clamp-1 w-fit max-w-[139px]">
                                            {name?.replace('.pdf', '')}. PDF
                                        </div>
                                        <div className="text-[8px] text-[#0000008C] font-normal">
                                            {file.size}KB
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className="text-[10px] text-white w-[76px] rounded-[3px] h-[19px] bg-[#215493]"
                                        onClick={() => {
                                            handleDownloadFilePdf(
                                                file?.file_path,
                                                file?.file_name,
                                            );
                                        }}
                                        type="button"
                                    >
                                        ダウンロード
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    <div className="buttonGroup gap-3 mb-3">
                        <button
                            className="cancelBtn !h-10 !py-0"
                            type="button"
                            onClick={handleClose}
                        >
                            戻る
                        </button>
                        <button
                            className="registerBtn !px-1 !h-10 !py-0 !text-sm"
                            type="submit"
                            onClick={()=>{
                                handleDownloadAllFilePdf(currentRecord?.files)
                            }}
                        >
                            すべてダウンロード
                        </button>
                    </div>
                </Box>
            </StyledModalUpload>
        </>
    );
};
export default ModalListFile;
