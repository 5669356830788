import {
    Button,
    Drawer,
    Upload,
    UploadProps,
    message,
    Spin,
    Input,
    DatePicker,
    ConfigProvider,
    Modal,
} from 'antd';
import {
    FocusEventHandler,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
    useRef,
} from 'react';
import jaJP from 'antd/es/locale/ja_JP';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AddOutlined, CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { Controller, useForm } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import { QuoteFormStyles } from '../form/styled';
import styled from 'styled-components';
import { UploadChangeParam, RcFile, UploadFile } from 'antd/lib/upload';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import wordFileIcon from 'assets/icons/word-file-image.svg';
import excelFileIcon from 'assets/icons/excel-file-image.svg';
import quoteValidation from 'utils/validation/registers/quote';
import quoteApi from 'services/quoteApi';
import {
    alertSuccess,
    alertSuccessPopup,
    validateZipcode,
} from 'utils/helper/appHelper';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { validZipCode } from 'utils/validation/registers/validator';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { fullWidthNumConvert } from 'pages/invoice';
import InputMask from 'react-input-mask-format';
import { MAX_LENGTH } from 'pages/project/projectPlan/projectTabs/constant';
import repairValidation from 'utils/validation/registers/repair';

const { Dragger } = Upload;
const { TextArea } = Input;
const digit = /[0-9０-９]/;
const mask = [digit, digit, digit, '-', digit, digit, digit, digit];
const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

export interface RepairRequestController {
    view: (id: number) => void;
}

const ModalQuoteCustom = styled(Drawer)`
    .ant-drawer-body {
        padding: 15px !important;
    }
    .ant-drawer-right > .ant-drawer-content-wrapper {
        margin: auto !important;
        width: 50% !important;
    }
`;

const ModalQuoteCustoms = styled(Modal)`
    .ant-modal-header {
        padding: 0px !important;
    }
    .ant-modal-content {
        min-height: 700px !important;
    }
`;

const UploadCustom = styled(Upload) <{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;

interface IProps { }
const RepairRequestController = forwardRef<RepairRequestController, IProps>(
    (props, ref) => {
        const {
            control,
            setValue,
            register,
            handleSubmit,
            formState: { errors },
            clearErrors,
            watch,
            getValues,
            setError,
            reset,
            resetField,
        } = useForm();
        const { RangePicker } = DatePicker;
        const [companies, setCompanies]: any = useState([]);
        const [isCheckSend, setIsCheckSend] = useState(false);
        const [isAllowUpload, setAllowUpload] = useState(true);
        const [fileLength, setFileLength] = useState<number>(0);
        const [indexError, setIndexError] = useState(0);
        const [loading, setLoading] = useState<boolean>(true);
        const [pdfFiles, setPdfFiles] = useState<any[] | null>(null);
        const [searchParams, setSearchParams] = useSearchParams();
        const id = searchParams.get('id');
        const editable = searchParams.get('editable'); // determine wether that we can edit or view-only
        const isRepair = searchParams.get('isRepair'); // determine wether that we can edit or view-only
        const navigate = useNavigate();
        const [listFile, setListFile]: any = useState([]);
        const [filesListPdf, setFilesListPdf]: any = useState([]);
        let storage = localStorage.getItem('user');
        const user = storage !== null ? JSON.parse(storage) : null;
        const [isLoading, setIsLoading] = useState(false);
        const [pdfList, setPdfList]: any = useState([]);
        const [filePdfList, setFilePdfList]: any = useState([]);
        const [totalFile, setTotalFile]: any = useState([]);
        const [totalImageSize, setTotalImageSize] = useState<number>(0);
        const { t } = useTranslation();
        const findZipcodeTimer = useRef<NodeJS.Timeout>();
        const UPLOAD_LIMIT = 100;
        const [listType, setListType]: any = useState([]);
        const [project, setProject]: any = useState({})
        const [sizeUploadLimitError, setSizeUploadLimitError] =
            useState<boolean>(false);
        useEffect(() => {
            if (sizeUploadLimitError) {
                message.error(
                    `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
                );
                setSizeUploadLimitError(false);
            }
        }, [sizeUploadLimitError]);
        const getDetail = async () => {
            setLoading(true);
            try {
                const res = await quoteApi.getSPQuoteDetail(user, id);
                if (res) {
                    const data = res.data.quote_repair;
                    if (data?.project) {
                        setProject(data?.project)
                    } else {
                        setProject(null)
                    }
                    setValue('company_name', data?.company_name);
                    setValue('content', data?.content);
                    setValue('construction_site', data?.construction_site);
                    setValue('address', data?.address);
                    setValue('person_in_charge', data?.person_in_charge);
                    setValue('property_name', data?.property_name);
                    setAllowUpload(data.images.length < 100);
                    const fileArr: any = [];
                    const pdfArr: any = [];
                    let imageSize = 0;
                    if (data?.images.length > 0) {
                        for (let i = 0; i < data?.images.length; i++) {
                            await fetch(data?.images[i].image_path)
                                .then((res) => res.blob())
                                .then((myBlob) => {
                                    const myFile: any = new File(
                                        [myBlob],
                                        'image.jpeg',
                                        { type: myBlob.type },
                                    );
                                    fileArr.push({
                                        uid: `-${i + 1}`,
                                        name: myFile.name,
                                        url: data?.images[i].image_path,
                                        status: 'done',
                                        originFileObj: myFile,
                                        lastModified: myFile.lastModified,
                                        lastModifiedDate:
                                            myFile.lastModifiedDate,
                                        type: myFile.type,
                                        size: myFile.size,
                                    });
                                    imageSize += myFile.size;
                                });
                        }
                    }
                    if (data?.files) {
                        const arrName: any = [];
                        for (let i = 0; i < data?.files.length; i++) {
                            arrName.push(data?.files[i]?.file_path);
                            await fetch(data?.files)
                                .then((res) => res.blob())
                                .then((myBlob) => {
                                    const myFile: any = new File(
                                        [myBlob],
                                        data?.files[i]?.file_path,
                                        {
                                            type: myBlob.type,
                                        },
                                    );
                                    if (myFile.name.includes('.pdf')) {
                                        listType.push('pdf');
                                    } else if (myFile.name.includes('.xls')) {
                                        listType.push('xls');
                                    } else if (myFile.name.includes('.doc')) {
                                        listType.push('doc');
                                    }
                                    pdfArr.push(myFile);
                                });
                        }
                    }
                    setValue('images', fileArr);
                    setTotalImageSize((prev) => prev + imageSize);
                    setListFile(fileArr);
                    setFileLength(fileArr.length);
                    setPdfFiles(data?.file_url);
                    setFilesListPdf(pdfArr);
                    setPdfList(pdfArr);
                    data?.images?.length > 0 &&
                        setAllowUpload(data?.images?.length < 100);
                }
            } catch (error) {
                //
            } finally {
                setLoading(false);
            }
        };
        const wordLimit = 5000;
        const dummyRequest: UploadProps['customRequest'] = async ({
            file,
            onSuccess,
        }) => {
            setTimeout(() => {
                if (onSuccess) {
                    onSuccess('ok');
                }
            }, 0);
        };

        const parsePostalCode = (zipCode: string) => {
            if (!zipCode) {
                setError('zipCode', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
                return;
            }

            const isZipcodeFormat = validateZipcode(zipCode);
            if (!isZipcodeFormat) {
                setError('zipCode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }

            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }

            let foundAddress: string;
            const postalCode = require('japan-postal-code');
            postalCode.get(zipCode, function (address) {
                foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
            });

            findZipcodeTimer.current = setTimeout(() => {
                if (foundAddress) {
                    setValue('address', foundAddress);
                    clearErrors('address');
                    clearErrors('zipCode');
                } else {
                    setValue('address', '');
                    setError('zipCode', {
                        type: 'validate',
                        message:
                            '入力された郵便番号に該当する住所が存在しません。',
                    });
                }
            }, 1000);
        };

        const parseNamePdf = (file_path: any) => {
            const arrName: any = [];
            const string: any = decodeURIComponent(file_path);
            if (string !== null && string !== undefined) {
                for (let i = string?.length - 1; i > 0; i--) {
                    if (string[i] !== '/') {
                        arrName.push(string[i]);
                    } else {
                        break;
                    }
                }
            }
            return arrName
                .slice()
                .reverse()
                .toString()
                .replaceAll(',', '')
                .split('.')[0];
        };

        const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
            const loadFile = 100;
            setIndexError(0);
            const filteredPdfAfterUid = info?.fileList.filter((bItem) =>
                listFile?.some((aItem) => aItem.uid === bItem.uid),
            );
            const filteredPdfAfterRedundant = info?.fileList.filter(
                (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
            );
            const filteredPdfAfterReMove: any =
                loadFile < filteredPdfAfterRedundant?.length &&
                filteredPdfAfterRedundant?.slice(
                    0,
                    loadFile > 1 ? loadFile : 1,
                );

            if (info.file.status === 'done') {
                setTotalImageSize(
                    (prevSize) => prevSize + (info.file.size as number),
                );
            }
            if (info.file.status === 'removed') {
                setTotalImageSize(
                    (prevSize) => prevSize - (info.file.size as number),
                );
            }
            if (loadFile >= info?.fileList?.length) {
                setAllowUpload(info.fileList.length < 100);
                setValue('images', info.fileList);
                setFileLength(info?.fileList?.length);
                setListFile(info?.fileList);
            } else {
                setIndexError((index) => index + 1);
            }
            clearErrors('images');
        };

        const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
            const uploadingSizes = fileList.reduce(
                (accu: number, curr: RcFile) => {
                    return accu + curr.size;
                },
                0,
            );
            setIndexError(0);
            const accumulatedImageSize = totalImageSize + uploadingSizes;
            const isImage =
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif';
            if (!isImage) {
                message.error(
                    'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
                );
                return Upload.LIST_IGNORE;
            }

            const isUnderLimit =
                accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
            if (!isUnderLimit) {
                setSizeUploadLimitError(true);
                return Upload.LIST_IGNORE;
            }

            return isImage && isUnderLimit;
        };

        const uploadButton = (
            <div className="text-[#5392F0] flex flex-col items-center">
                <img
                    src={uploadIcon}
                    className="w-[21px] h-[21px]"
                    alt="upload image icon"
                />
                <div>写真 ({fileLength > 0 ? fileLength : 0}/100)</div>
            </div>
        );

        const dragProps: UploadProps = {
            name: 'file',
            beforeUpload(file: RcFile) {
                // const isPDF = file.type === 'application/pdf';
                // if (!isPDF) {
                //     message.error('PDF形式でアップロードしてください。');
                // }
                if (file.type === 'application/pdf') {
                    listType.push('pdf');
                } else if (file.name.includes('xls')) {
                    listType.push('xls');
                } else if (file.name.includes('doc')) {
                    listType.push('doc');
                }
                const isLE10MB = file.size / 1024 / 1024 <= 10;
                if (!isLE10MB) {
                    message.error('ファイルは10MBを超えてはいけません。');
                }

                if (isLE10MB) {
                    setFilePdfList((filePdfList) => {
                        return [...filePdfList, file];
                    });
                }
                return isLE10MB || Upload.LIST_IGNORE;
            },
            onChange(info) {
                const filteredPdfAfterRemove = filePdfList.filter((bItem) =>
                    info?.fileList?.some((aItem) => aItem.uid === bItem.uid),
                );
                setFilePdfList(filteredPdfAfterRemove);
                setValue('file', info.file.originFileObj);
                setPdfList(info.fileList);
            },
            onDrop(e) {
                //
            },
            customRequest({ file, onSuccess }) {
                setTimeout(() => {
                    if (onSuccess) {
                        onSuccess('ok');
                    }
                }, 0);
            },
        };

        const fetchData = () => {
            // fetch detail then set into data
        };
        const handleClose = () => {
            clearErrors();
            setTotalImageSize(0);
            navigate('');
            setTotalFile([]);
            setFilePdfList([]);
            setListFile([]);
            setFileLength(0);
            setListType([]);
            reset();
            resetField('company_coop');
            setValue('company_coop', '');
        };

        const submitData = async (value: any, evt: any) => {
            // evt.preventDefault()
            const isAllLE100MB = totalImageSize < 1024 * 1024 * 100;

            evt.preventDefault();
            setIsLoading(true);
            let formData = new FormData();

            formData.append('person_in_charge', value?.person_in_charge);
            formData.append('address', value?.address);
            formData.append('content', value?.content);
            for (let i = 0; i < value?.images.length; i++) {
                formData.append('images[]', value?.images[i].originFileObj);
            }

            formData.append('company_name', value?.company_name);
            formData.append('property_name', value?.property_name);
            formData.append('construction_site', value.construction_site);

            if (!isAllLE100MB) {
                message.error('画像の合計サイズは 100MB を超えてはなりません');
                setIsLoading(false);
            } else {
                try {
                    const res = await quoteApi.updateQuote(user, formData, id);
                    if (res) {
                        alertSuccess('修繕依頼を作成しました。');
                        navigate(`/quote/list`);
                        setIsLoading(false);
                        setTotalImageSize(0);
                    }
                } catch (error) {
                    console.log('error', error.message);
                    setIsLoading(false);
                    // alertError(error.message);
                }
            }
        };

        useEffect(() => {
            getListCompany();
            if (id) {
                getDetail();
            }
        }, [id]);

        useEffect(() => {
            indexError === 1 &&
                message.error('ファイルアップロードは100ファイルまでです。');
        }, [indexError]);

        const handleValidateWhitespace: FocusEventHandler<
            HTMLInputElement | HTMLTextAreaElement
        > = (e) => {
            const onlyContainWhitespaces = validateFullWhitespace(
                e.target.value,
            );
            if (onlyContainWhitespaces) {
                setValue(e.target.name, '');
            }
        };

        const getListCompany = async () => {
            try {
                const res = await quoteApi.geAlltCompanies(user);
                const arrTmp: any = [];
                for (
                    let i = 0;
                    i < res.data.cooperative_companies.length;
                    i++
                ) {
                    arrTmp.push({
                        value: res.data.cooperative_companies[i].company.id,
                        name: res.data.cooperative_companies[i].company.name,
                    });
                }
                setCompanies(arrTmp);
            } catch (error) {
                console.log('error', error);
            }
        };
        return (
            <ModalQuoteCustoms
                width={600}
                title={
                    <div className="flex justify-between items-center bg-[#215493] text-[white] py-[20px] px-[15px]">
                        <span>{editable ? '編集' : '詳細'}</span>
                        <Button
                            shape="circle"
                            type="text"
                            onClick={handleClose}
                        >
                            <CloseOutlined style={{ color: 'white' }} />
                        </Button>
                    </div>
                }
                closable={false}
                destroyOnClose
                footer={null}
                open={Boolean(id) && isRepair === 'true'}
                onCancel={handleClose}
            // headerStyle={{ background: '#215493' }}
            >
                <ConfigProvider locale={jaJP}>
                    {isLoading && (
                        <div
                            style={{
                                width: '100vw',
                                height: '100vh',
                                top: 0,
                                left: 0,
                                zIndex: 9999,
                            }}
                            className="position-fixed d-flex align-items-center justify-content-center"
                        >
                            <Spin className="w-[100px] h-[100px]" />
                        </div>
                    )}
                    <QuoteFormStyles>
                        <div className="quote-form-container">
                            {loading ? (
                                <div
                                    style={{
                                        width: '100vw',
                                        height: '100vh',
                                        top: 0,
                                        left: 0,
                                        zIndex: 9999,
                                    }}
                                    className="position-fixed d-flex align-items-center justify-content-center"
                                >
                                    <Spin className="w-[100px] h-[100px]" />
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit(submitData)}>
                                    <div className="w-full flex flex-col gap-y-[14px]">
                                        <button
                                            className="modal-create-user-button1 d-flex flex-row !rounded-[2px] w-[160px]"
                                            type="button"
                                            onClick={() => {
                                                if (project?.id) {
                                                    navigate(`/projects/detail/${id}?project_id=${project?.id}`)
                                                } else {
                                                    navigate(`/projects/create/${id}`)
                                                }
                                            }}
                                        >
                                            <AddOutlined />
                                            新規作成
                                        </button>
                                        <div className="flex flex-col w-full">
                                            <div className="flex items-center mb-3">
                                                <div className="mr-4 text-base">
                                                    写真アップロード
                                                </div>
                                                <img
                                                    src={warningIcon}
                                                    alt="warning circle icon"
                                                />
                                                <div className="text-[#808080] pl-[3px]">
                                                    {' '}
                                                    1:1 スケール画像
                                                </div>
                                            </div>
                                            <div>
                                                <UploadCustom
                                                    locale={{
                                                        uploading:
                                                            'アップロード中',
                                                    }}
                                                    accept=".jpg, .jpeg, .png, .gif"
                                                    listType="picture-card"
                                                    customRequest={dummyRequest}
                                                    {...register('images', {
                                                        required:
                                                            '画像は最低1枚以上アップロードしてください。',
                                                    })}
                                                    disabled={!editable}
                                                    onChange={handleChangeFile}
                                                    beforeUpload={
                                                        handleBeforeUpload
                                                    }
                                                    fileList={
                                                        listFile && listFile
                                                    }
                                                    multiple
                                                    $isFull={
                                                        typeof fileLength ===
                                                        'number' &&
                                                        fileLength >= 100
                                                    }
                                                >
                                                    {isAllowUpload &&
                                                        uploadButton}
                                                </UploadCustom>
                                            </div>
                                            {errors.images && (
                                                <span className="error">
                                                    {errors.images.message?.toString()}
                                                </span>
                                            )}
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <Controller
                                                name="person_in_charge"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            担当者名
                                                        </p>
                                                        <input
                                                            autoComplete="off"
                                                            type="text"
                                                            className="textBox"
                                                            {...register(
                                                                'person_in_charge',
                                                                repairValidation().person_in_charge(),
                                                            )}
                                                            onBlur={
                                                                handleValidateWhitespace
                                                            }
                                                            maxLength={
                                                                MAX_LENGTH
                                                            }
                                                        ></input>
                                                        {errors.person_in_charge && (
                                                            <span className="error !text-[14px]">
                                                                {errors.person_in_charge.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <Controller
                                                name="company_name"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            法人名
                                                        </p>
                                                        <input
                                                            autoComplete="off"
                                                            type="text"
                                                            className="textBox"
                                                            {...register(
                                                                'company_name',
                                                                repairValidation().company_name(),
                                                            )}
                                                            onBlur={
                                                                handleValidateWhitespace
                                                            }
                                                            maxLength={
                                                                MAX_LENGTH
                                                            }
                                                        ></input>
                                                        {errors.company_name && (
                                                            <span className="error !text-[14px]">
                                                                {errors.company_name.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <Controller
                                                name="property_name"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            物件名
                                                        </p>
                                                        <input
                                                            autoComplete="off"
                                                            type="text"
                                                            className="textBox"
                                                            {...register(
                                                                'property_name',
                                                                repairValidation().property_name(),
                                                            )}
                                                            onBlur={
                                                                handleValidateWhitespace
                                                            }
                                                            maxLength={
                                                                MAX_LENGTH
                                                            }
                                                        ></input>
                                                        {errors.property_name && (
                                                            <span className="error !text-[14px]">
                                                                {errors.property_name.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <Controller
                                                name="construction_site"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            施工場所
                                                        </p>
                                                        <input
                                                            autoComplete="off"
                                                            type="text"
                                                            className="textBox"
                                                            {...register(
                                                                'construction_site',
                                                                repairValidation().construction_site(),
                                                            )}
                                                            onBlur={
                                                                handleValidateWhitespace
                                                            }
                                                            maxLength={
                                                                MAX_LENGTH
                                                            }
                                                        ></input>
                                                        {errors.construction_site && (
                                                            <span className="error !text-[14px]">
                                                                {errors.construction_site.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <Controller
                                                name="address"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            住所
                                                        </p>
                                                        <input
                                                            autoComplete="off"
                                                            type="text"
                                                            className="textBox"
                                                            {...register(
                                                                'address',
                                                                repairValidation().address(),
                                                            )}
                                                            onBlur={
                                                                handleValidateWhitespace
                                                            }
                                                            maxLength={
                                                                MAX_LENGTH
                                                            }
                                                        ></input>
                                                        {errors.address && (
                                                            <span className="error !text-[14px]">
                                                                {errors.address.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Controller
                                                name="content"
                                                rules={{
                                                    required: true,
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            修繕内容
                                                        </p>
                                                        <textarea
                                                            autoComplete="off"
                                                            className="border-[1px] border-solid border-[#cdd1d5] p-[10px]"
                                                            rows={4}
                                                            {...register(
                                                                'content',
                                                                repairValidation().content(),
                                                            )}
                                                            {...field}
                                                            onBlur={
                                                                handleValidateWhitespace
                                                            }
                                                        />
                                                        {errors.content && (
                                                            <span className="error !text-[14px]">
                                                                {errors.content.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {editable && (
                                        <div
                                            className="d-flex flex-row"
                                            style={{
                                                marginTop: '14px',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <button
                                                className="modal-create-user-button1 d-flex flex-row"
                                                style={{ width: '167.5px' }}
                                                type="button"
                                                onClick={handleClose}
                                            >
                                                キャンセル
                                            </button>
                                            <button
                                                className="modal-create-user-button2 d-flex flex-row"
                                                style={{
                                                    marginLeft: '10px',
                                                    width: '167.5px',
                                                }}
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                保存
                                            </button>
                                        </div>
                                        // <div
                                        //     className="text-lg flex flex-col gap-[10px]"
                                        //     style={{
                                        //         marginTop: '14px',
                                        //     }}
                                        // >
                                        //     <button
                                        //         className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[100%]"
                                        //         type="submit"
                                        //     >
                                        //         保存
                                        //     </button>
                                        //     <button
                                        //         className="modal-create-user-button1 d-flex flex-row !rounded-[2px] !w-[100%]"
                                        //         type="button"
                                        //         onClick={handleClose}
                                        //     >
                                        //         キャンセル
                                        //     </button>
                                        // </div>
                                    )}
                                </form>
                            )}
                        </div>
                    </QuoteFormStyles>
                </ConfigProvider>
            </ModalQuoteCustoms>
        );
    },
);
const StyleMaskInput = styled(InputMask)({
    padding: '5px',
    border: '1px solid rgb(205, 209, 213)',
    width: '70%',
});
export default RepairRequestController;
