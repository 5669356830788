import { param } from 'jquery';
import Api from './api/billLineApi';
import { axiosClient } from './quoteApi';

const getListBillLine = async (
    idToken: any,
    values?: any,
) => {
    const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/bills`;
    const config: any = {
        headers: { Authorization: `Bearer ${idToken}` },
        params: values,
    };
    return axiosClient.get(url, config);
};

const getDetailBillLine = async (idToken: any, idBill?: number) => {
    const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/bills/${idBill}`;
    const config: any = {
        headers: { Authorization: `Bearer ${idToken}` },
    };
    return axiosClient.get(url, config);
};

const updateStatus = async (idToken: any, idBill?: number) => {
    const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/bills/${idBill}/payment_success`;
    const config: any = {
        headers: { Authorization: `Bearer ${idToken}` },
    };
    return axiosClient.put(url, config);
};

const billLineService = {
    getListBillLine,
    getDetailBillLine,
    updateStatus,
};

export default billLineService;
