import { useState, useEffect } from 'react';
import moment from 'moment';
import './detailPlan.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import 'react-step-progress/dist/index.css';
import plus from '../../../assets/icons/Plus.png';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import projectsService from 'services/projectsService';
import { getTextPercentPlan } from 'utils/helper/planHelper';
import { formatDateTime } from 'utils/helper/dateHelper';
import { alertError } from 'utils/helper/appHelper';
import plansService from 'services/plansService';
import WorkingReportList from './workingReport/WorkingReportList';
import WorkingLog from '../workingLog';
import PlanCreate from './components/planCreate';
import PlanUpdate from './components/planUpdate';
import workersService from 'services/wokersService';
import ChartLayout from './components/chart';
import Billings from './Billings';
import $ from 'jquery';
import ProgressStep from './progressStep';
import { IPageQueries } from 'models';
import CustomPagination from 'components/CustomPagination';
import CreateWorkingLogDialog from '../workingLog/components/CreateWorkingLog';
import ProjectTable from './components/projectTable';
import ProjectTabs from './components/projectTabs';
import {
    ProjectBillTab,
    ProjectManifestsTab,
    ProjectOrderItemsTab,
} from './projectTabs';
import ProjectDetailChats from './Chats';
import ProjectService from 'services/projectServiceSP';
import BudgetList from './budgetAction/BudgetList';
import EstimationList from './Estimations/EstimationList';
import ProgressStepCreate from '../projectCreate/progressStepCreate';
import projectApi from 'services/projectApi';
import BluePen from 'assets/icons/blue-pen.png';
import Perform from './Perform';
import clsx from 'clsx';
import Invoices from './Invoices';
import { LIST_TAB_PROJECT } from 'utils/constants';

export const determineStatus = (project: any, idAllPaid: boolean) => {
    if (
        !project?.estimate &&
        !project?.order &&
        !project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'order_placement';
    } else if (
        project?.estimate &&
        !project?.order &&
        !project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'order_sheet';
    } else if (
        project?.estimate &&
        project?.order &&
        !project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'order_receipt';
    } else if (
        project?.estimate &&
        project?.order &&
        project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'construction';
    } else if (
        project?.estimate &&
        project?.order &&
        project?.implement &&
        project?.bills?.length > 0
    ) {
        return idAllPaid ? 'payment_confirmation' : 'invoice_creation';
    }
};

const WorkingReport = (props) => {
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);
    const [tabChart, setTabChart] = useState(1);
    const [isChangeProject, setChangeProject] = useState(false);
    let { id } = useParams();
    let [searchParams] = useSearchParams();

    const [currentProject, setCurrentProject] = useState<any>(null);
    const [currentStatus, setCurrentStatus] = useState<any>(null);

    const [opentCreateModal, setOpentCreateModal] = useState<any>(false);
    const [opentUpdateModal, setOpentUpdateModal] = useState<any>(false);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [currentPlan, setCurrentPlan] = useState<any>(null);
    const [workersList, setWorkersList] = useState<any>(null);
    const [plans, setPlans] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    // list chats
    const [chats, setChats] = useState<any>(null);
    const [refresh, setRefresh] = useState<any>(null);

    const [openCreateWorkingLog, setOpenCreateWorkingLog] = useState(false);
    const [accessSugoroku, setAccessSugoroku] = useState<boolean>(false);

    const listStatus = ['見積', '受注', '着工', '請求', '入金', '完了'];

    const [images, setImages] = useState([]);
    const checkSugorokuPermission = async () => {
        try {
            const { access_sugoroku }: any =
                await ProjectService.checkPermissions(Number(id));
            setAccessSugoroku(access_sugoroku);
        } catch (error) {}
    };

    const determineTab = (project: any) => {
        if (
            project?.estimate &&
            !project?.order &&
            !project?.implement &&
            project?.bills?.length === 0
        ) {
            return 1;
        } else if (
            (project?.estimate &&
                project?.order &&
                !project?.implement &&
                project?.bills?.length === 0) ||
            (project?.estimate &&
                project?.order &&
                project?.implement &&
                (project?.implement?.status?.includes('implementing') ||
                    project?.implement?.status?.includes('send_implement')) &&
                project?.bills?.length === 0)
        ) {
            return 2;
        } else if (
            project?.estimate &&
            project?.order &&
            project?.implement &&
            project?.implement?.status?.includes('finish_implement')
        ) {
            return 3;
        } else {
            return 0;
        }
    };

    const getDetailProject = async () => {
        if (!id) return;
        try {
            const res = await projectApi.getProjectDetail(
                user?.access_token,
                id,
            );
            const project = res?.data?.project;

            const fileArr: any = [];
            let imageSize = 0;
            if (project?.images.length > 0 || project?.images !== null) {
                for (let i = 0; i < project?.images.length; i++) {
                    await fetch(project?.images[i].image_path)
                        .then((res) => res.blob())
                        .then((myBlob) => {
                            const myFile: any = new File(
                                [myBlob],
                                'image.jpeg',
                                { type: myBlob.type },
                            );
                            fileArr.push({
                                uid: `-${i + 1}`,
                                name: myFile.name,
                                url: project?.images[i].image_path,
                                status: 'done',
                                originFileObj: myFile,
                                lastModified: myFile.lastModified,
                                lastModifiedDate: myFile.lastModifiedDate,
                                type: myFile.type,
                                size: myFile.size,
                            });
                            imageSize += myFile.size;
                        });
                }
            }
            setImages(fileArr);
            // setValue('images', fileArr);
            const isAllPaid =
                project?.bills?.length > 0
                    ? project?.bills?.every(
                          (item: any) => item.status === 'paid',
                      )
                    : false;

            const tabIndex: any = determineTab(project);
            const statusFormat = determineStatus(project, isAllPaid);
            const project_format = {
                ...res?.data?.project,
                status: statusFormat,
            };
            setTab(tabIndex);
            setCurrentProject(project_format);
        } catch (error) {            
            console.error(error);
        }
    };

    const getListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers();
            if (response !== null && response !== undefined) {
                setWorkersList(response.workers);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListPlan = async (
        project_id: number,
        page = 1,
        per_page = 0,
        data = {},
    ) => {
        try {
            if (!project_id) return;
            const response = await plansService.getListPlan(project_id, {
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setPlans(response.plans);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const deletePlan = async () => {
        await plansService
            .deletePlan(currentPlan.id, currentProject.id)
            .then((data) => {
                setChangeProject(!isChangeProject);
            })
            .catch((err) => {
                const response = err?.response;
                if (!response || typeof response === 'undefined') {
                    navigate('login');
                    return;
                }
                alertError(response.data?.error);
            })
            .finally(() => {
                setCurrentPlan(null);
                closeDelete();
            });
    };

    const getListChat = async (id: number) => {
        try {
            if (!id) return;
            const response = await projectsService.getListChat(id, {
                page: 1,
                per_page: 0,
            });
            if (response !== null && response !== undefined) {
                setChats(response.chats);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleCreatedPlan = () => {
        // closeCreatePlan();
        setOpentCreateModal(false);
        setChangeProject(!isChangeProject);
    };

    const handleDeletePlan = (row) => {
        setCurrentPlan(row);
        openDelete();
    };

    const handleDetailPlan = (row) => {
        setCurrentPlan(row);
        setOpenCreateWorkingLog(true);
    };

    const handleEditPlan = (row) => {
        setCurrentPlan(row);
        // openUpdatePlan();
        setOpentUpdateModal(true);
    };

    const handleUpdatedPlan = () => {
        // closeUpdatePlan();
        setOpentUpdateModal(false);
        setChangeProject(!isChangeProject);
    };

    const handleCreatedWorkingLog = () => {
        setOpenCreateWorkingLog(false);
        setChangeProject(!isChangeProject);
    };

    // useEffect(() => {
    //     getListWorkers();

    //     const tabParam = Number(searchParams.get('tab'));
    //     if (tabParam || tabParam === 0) {
    //         setTab(tabParam);
    //     }
    // }, []);

    useEffect(() => {
        if (id) {
            getDetailProject();
        }
    }, [id]);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    return (
        <div className="container-project2 d-flex flex-row">
            <div className="project-content-border2 d-flex flex-column">
                {/* <div
                    className="worker-create-title d-flex flex-row"
                    style={{
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    {currentProject?.name}
                    <button
                        className="create-user-button d-flex flex-row"
                        onClick={() => navigate(`/projects/edit/${id}`)}
                        tabIndex={1}
                    >
                        編集
                    </button>
                </div> */}
                <ProgressStepCreate status={currentProject?.status} />
                <div className="flex flex-row mb-[20px]">
                    <div className="text-[20px] font-bold">
                        {currentProject?.property_name}
                    </div>
                    <div className="min-w-[80px]">
                        <div
                            onClick={() =>
                                navigate(
                                    `/projects/edit/${currentProject?.quote?.id}?project_id=${currentProject?.id}`,
                                )
                            }
                            className="flex flex-row justify-end ml-[10px] gap-[5px] mb-[2px] text-[14px] cursor-pointer"
                        >
                            <img
                                src={BluePen}
                                alt=""
                                height="14px"
                                width="17px"
                                className="py-[3px]"
                            />
                            <div className="text-[14px] text-[#215493] mt-[4px] !mb-0">
                                編集
                            </div>
                        </div>
                    </div>
                </div>
                {currentProject && (
                    <ProjectTable
                        currentProject={currentProject}
                        images={images}
                    />
                )}
                {currentProject?.business_type == '産廃' ? (
                    <ProjectTabs
                        titles={[
                            '委託内容',
                            'マニフェスト',
                            // '運行予定',
                            '請求',
                            'チャット',
                        ]}
                        contents={[
                            <ProjectOrderItemsTab />,
                            <ProjectManifestsTab />,
                            <Box
                                sx={{
                                    mt: '24px',
                                    th: {
                                        fontFamily: 'Zen Kaku Gothic Antique',
                                    },
                                }}
                            >
                                <Billings
                                    project_id={id}
                                    project={currentProject}
                                />
                            </Box>,
                            <ProjectDetailChats
                                currentProject={currentProject}
                                chats={chats}
                                setRefresh={setRefresh}
                            />,
                        ]}
                    />
                ) : (
                    //need to define each component for content and pass by props
                    <>
                        <div className="tab-project !text-sm d-flex flex-row">
                            {LIST_TAB_PROJECT?.map(
                                (tabCustom: any, index: number) => {
                                    const tabValue = tabCustom?.value;

                                    const lastIndex = index === 3;

                                    return (
                                        <>
                                            <div
                                                onClick={() => {
                                                    // if (
                                                    //     (getCurrentData(
                                                    //         tabValue,
                                                    //     ) &&
                                                    //         !getCurrentData(
                                                    //             tabValue + 1,
                                                    //         )) ||
                                                    //     (!getCurrentData(
                                                    //         tabValue,
                                                    //     ) &&
                                                    //         getCurrentData(
                                                    //             tabValue - 1,
                                                    //         ) &&
                                                    //         !lastIndex) ||
                                                    //     (!getCurrentData(
                                                    //         tabValue,
                                                    //     ) &&
                                                    //         getCurrentData(
                                                    //             tabValue - 1,
                                                    //         ) &&
                                                    //         lastIndex &&
                                                    //         currentProject?.implement?.status?.includes(
                                                    //             'finish_implement',
                                                    //         ))
                                                    // ) {
                                                    // }
                                                    setTab(tabValue);
                                                }}
                                                className={clsx(
                                                    '!text-sm !font-medium',
                                                    {
                                                        'tab-active':
                                                            tab === tabValue,
                                                        // '!cursor-no-drop':
                                                        //     (!getCurrentData(
                                                        //         tabValue,
                                                        //     ) &&
                                                        //         !getCurrentData(
                                                        //             tabValue -
                                                        //                 1,
                                                        //         ) &&
                                                        //         tab !==
                                                        //             tabValue) ||
                                                        //     (getCurrentData(
                                                        //         tabValue,
                                                        //     ) &&
                                                        //         getCurrentData(
                                                        //             tabValue +
                                                        //                 1,
                                                        //         ) &&
                                                        //         tab !==
                                                        //             tabValue) ||
                                                        //     (lastIndex &&
                                                        //         (currentProject?.implement?.status?.includes(
                                                        //             'implementing',
                                                        //         ) ||
                                                        //             currentProject?.implement?.status?.includes(
                                                        //                 'send_implement',
                                                        //             ))) ||
                                                        //     (currentProject?.implement?.status?.includes(
                                                        //         'finish_implement',
                                                        //     ) &&
                                                        //         currentProject
                                                        //             ?.bills
                                                        //             ?.length >
                                                        //             0 &&
                                                        //         index === 2),
                                                    },
                                                )}
                                            >
                                                {tabCustom?.label}
                                            </div>
                                        </>
                                    );
                                },
                            )}
                        </div>
                        {tab === 0 && (
                            <BudgetList
                                project_id={id}
                                getProjectDetail={getDetailProject}
                                project={currentProject}
                                setTab={setTab}
                            />
                        )}
                        {tab === 1 && (
                            <EstimationList
                                project_id={id}
                                getDetailProject={getDetailProject}
                                project={currentProject}
                                setTab={setTab}
                            />
                        )}
                        {tab === 2 && (
                            <Perform
                                project_id={id}
                                getDetailProject={getDetailProject}
                                project={currentProject}
                                implement_id={currentProject?.implement?.id}
                                setTab={setTab}
                            />
                        )}
                        {tab === 3 && (
                            <Invoices
                                getDetailProject={getDetailProject}
                                project_id={id}
                                project={currentProject}
                            />
                        )}

                        {/* {![1, 2, 4, 5, 6, 0].includes(tab) && (
                            <>
                                <div className="table-chart-project  d-flex flex-row">
                                    <div className="option-table-chart-project  d-flex flex-row">
                                        <div
                                            className={
                                                tabChart == 1
                                                    ? 'chart-button active-chart d-flex flex-row'
                                                    : 'chart-button d-flex flex-row'
                                            }
                                            onClick={() => setTabChart(1)}
                                        >
                                            ガントチャート
                                        </div>
                                        <div
                                            className={
                                                tabChart == 0
                                                    ? 'table-button active-chart d-flex flex-row'
                                                    : 'table-button d-flex flex-row'
                                            }
                                            onClick={() => setTabChart(0)}
                                        >
                                            工程一覧
                                        </div>
                                    </div>
                                    <div
                                        className="create-button d-flex flex-row"
                                        style={{ alignItems: 'center' }}
                                    >
                                        <button
                                            className="create-user-button d-flex flex-row"
                                            onClick={() =>
                                                setOpentCreateModal(true)
                                            }
                                        >
                                            <img
                                                width={'16px'}
                                                height={'16px'}
                                                src={plus}
                                            ></img>
                                            新規作成
                                        </button>
                                        <ModalCreatePlan>
                                            <PlanCreate
                                                project_id={id}
                                                onClose={handleCreatedPlan}
                                                onCancel={closeCreatePlan}
                                            />
                                        </ModalCreatePlan>
                                        <BootstrapDialog
                                            open={opentCreateModal}
                                            onClose={() =>
                                                setOpentCreateModal(false)
                                            }
                                            className="customBootstrapDialog projectPlanDialog"
                                            aria-labelledby="customized-dialog-title"
                                        >
                                            <PlanCreate
                                                project_id={id}
                                                onClose={handleCreatedPlan}
                                                onCancel={() =>
                                                    setOpentCreateModal(false)
                                                }
                                            />
                                        </BootstrapDialog>
                                        <Modal
                                            isOpen={opentCreateModal}
                                            onRequestClose={() =>
                                                setOpentCreateModal(false)
                                            }
                                            style={customStyles}
                                            contentLabel="create modal"
                                        >
                                            <PlanCreate
                                                project_id={id}
                                                onClose={handleCreatedPlan}
                                                onCancel={() =>
                                                    setOpentCreateModal(false)
                                                }
                                            />
                                        </Modal>
                                        <BootstrapDialog
                                            open={opentUpdateModal}
                                            onClose={() =>
                                                setOpentUpdateModal(false)
                                            }
                                            className="customBootstrapDialog projectPlanDialog"
                                            aria-labelledby="customized-dialog-title"
                                        >
                                            <PlanUpdate
                                                id={currentPlan?.id}
                                                project_id={id}
                                                originPlan={currentPlan}
                                                setChangeProject={
                                                    setChangeProject
                                                }
                                                isChangeProject={
                                                    isChangeProject
                                                }
                                                onClose={handleUpdatedPlan}
                                                onCancel={() =>
                                                    setOpentUpdateModal(false)
                                                }
                                            />
                                        </BootstrapDialog>
                                        <Modal
                                            isOpen={opentUpdateModal}
                                            onRequestClose={() =>
                                                setOpentUpdateModal(false)
                                            }
                                            style={customStyles}
                                            contentLabel="update modal"
                                        >
                                            <PlanUpdate
                                                id={currentPlan?.id}
                                                project_id={id}
                                                originPlan={currentPlan}
                                                setChangeProject={
                                                    setChangeProject
                                                }
                                                isChangeProject={
                                                    isChangeProject
                                                }
                                                onClose={handleUpdatedPlan}
                                                onCancel={() =>
                                                    setOpentUpdateModal(false)
                                                }
                                            />
                                        </Modal>
                                        <CreateWorkingLogDialog
                                            open={openCreateWorkingLog}
                                            setOpen={setOpenCreateWorkingLog}
                                            project_id={id}
                                            onFinishCreate={() =>
                                                handleCreatedWorkingLog()
                                            }
                                            plans={currentProject?.plans}
                                            workers={workersList}
                                            currentPlan={currentPlan}
                                        />

                                        <ModalDelete>
                                            <div
                                                className="modal-create-user d-flex flex-column"
                                                style={{ height: '152px' }}
                                            >
                                                <div className="modal-create-user-title d-flex flex-row">
                                                    本当に削除しますか?
                                                </div>
                                                <div
                                                    className="modal-create-user-buttons d-flex flex-row"
                                                    style={{
                                                        marginTop: '50px',
                                                    }}
                                                >
                                                    <button
                                                        className="modal-create-user-button1 d-flex flex-row"
                                                        onClick={closeDelete}
                                                    >
                                                        キャンセル
                                                    </button>
                                                    <button
                                                        className="modal-create-user-button2 d-flex flex-row"
                                                        onClick={deletePlan}
                                                    >
                                                        はい
                                                    </button>
                                                </div>
                                            </div>
                                        </ModalDelete>
                                    </div>
                                </div>
                                {tabChart == 0 ? (
                                    <div className="table-workers d-flex flex-column">
                                        <div className="table-content d-flex flex-column">
                                            <TableContainer
                                                component={Paper}
                                                table-layout={'auto'}
                                            >
                                                <Table
                                                    sx={{ minWidth: 700 }}
                                                    aria-label="customized table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div></div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                >
                                                                    工程
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                >
                                                                    開始予定
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div>
                                                                    開始実績
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div>
                                                                    完了予定
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div>
                                                                    完了実績
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div>進捗</div>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow1"
                                                                align="center"
                                                                style={{
                                                                    width: '30%',
                                                                }}
                                                            >
                                                                <div>操作</div>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {plans !== null &&
                                                            plans?.map(
                                                                (row, ind) => (
                                                                    <StyledTableRow
                                                                        key={
                                                                            ind
                                                                        }
                                                                    >
                                                                        <StyledTableCell
                                                                            align="left"
                                                                            component="th"
                                                                            scope="row"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-overflow1"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                }}
                                                                            >
                                                                                {(pageQueries.page -
                                                                                    1) *
                                                                                    pageQueries.per_page +
                                                                                    (ind +
                                                                                        1)}
                                                                            </div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="left"
                                                                            component="th"
                                                                            scope="row"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-overflow1"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    row.name
                                                                                }
                                                                            </div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="left"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-overflow1"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                }}
                                                                            >
                                                                                {formatDateTime(
                                                                                    row.start_at,
                                                                                    'YYYY/MM/DD',
                                                                                )}
                                                                            </div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="left"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-overflow1"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                }}
                                                                            >
                                                                                {formatDateTime(
                                                                                    row.start_at_actual,
                                                                                    'YYYY/MM/DD',
                                                                                )}
                                                                            </div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="left"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-overflow1"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                }}
                                                                            >
                                                                                {formatDateTime(
                                                                                    row.end_at,
                                                                                    'YYYY/MM/DD',
                                                                                )}
                                                                            </div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="left"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div className="text-overflow1">
                                                                                {formatDateTime(
                                                                                    row.end_at_actual,
                                                                                    'YYYY/MM/DD',
                                                                                )}
                                                                            </div>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-overflow1"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                }}
                                                                            >
                                                                                {getTextPercentPlan(
                                                                                    row.progress_percentage,
                                                                                )}
                                                                            </div>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{
                                                                                width: '30%',
                                                                                minWidth:
                                                                                    '300px',
                                                                            }}
                                                                        >
                                                                            <button
                                                                                className="button-tb"
                                                                                style={{
                                                                                    width: '72px',
                                                                                    margin: '5px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleDetailPlan(
                                                                                        row,
                                                                                    )
                                                                                }
                                                                            >
                                                                                進捗
                                                                            </button>
                                                                            <button
                                                                                className="button-tb"
                                                                                style={{
                                                                                    width: '72px',
                                                                                    margin: '5px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEditPlan(
                                                                                        row,
                                                                                    )
                                                                                }
                                                                            >
                                                                                編集
                                                                            </button>
                                                                            <button
                                                                                className="button-tb"
                                                                                style={{
                                                                                    width: '72px',
                                                                                    margin: '5px',
                                                                                    backgroundColor:
                                                                                        '#FF5045',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleDeletePlan(
                                                                                        row,
                                                                                    )
                                                                                }
                                                                            >
                                                                                削除
                                                                            </button>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ),
                                                            )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        {plans && plans.length > 0 && (
                                            <CustomPagination
                                                totalCount={totalCount}
                                                pageQueries={pageQueries}
                                                setPageQueries={setPageQueries}
                                                fetchList={(page) =>
                                                    getListPlan(
                                                        Number(id),
                                                        page,
                                                        pageQueries.per_page,
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="chart-container d-flex1 flex-row1">
                                        <ChartLayout
                                            handleEditPlan={handleEditPlan}
                                            handleDetailPlan={handleDetailPlan}
                                            handleDeletePlan={handleDeletePlan}
                                            tabChart={tabChart}
                                            project_id={Number(id)}
                                            isChangeProject={isChangeProject}
                                        />
                                    </div>
                                )}
                            </>
                        )} */}
                    </>
                )}
            </div>
        </div>
    );
};

export default WorkingReport;
