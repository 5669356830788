import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModalUpload } from '../modal-upload/styled';
import pdfImg from '../../../assets/icons/img_pdf.jpg';
import clsx from 'clsx';
import documentsService from 'services/documentService';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import { IParams } from '..';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@mui/icons-material';

interface File {
    file_path?: string;
    file_name?: string;
    id?: number;
    signed_id?: string;
}

const ModalDelete = (props) => {
    const {
        isOpenDeleteListFile,
        currentRecord,
        setCurrentRecord,
        setIsOpenDeleteListFile,
        params,
        setParams,
        documentList,
        getListDocument,
    } = props;


    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const [currentFile, setCurrentFile] = useState<File>({});

    const showOpenDelete = () => {
        setIsOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setIsOpenDelete(false);
        setCurrentFile({});
    };

    const handleClose = () => {
        setIsOpenDeleteListFile(false);
        setCurrentFile({})
        setCurrentRecord({})
    };

    const handleDeleteFileDocument = async (
        id?: number,
        signed_id?: string,
    ) => {
        try {
            const data = await documentsService.deleteFileDocument(
                id,
                signed_id,
            );
            if (data) {
                alertSuccessPopup({
                    title: '資料の削除が完了しました。',
                    confirmButtonText: '閉じる',
                });
                getListDocument();
                handleCloseDelete();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    const handleDeleteDocument = async (id: number) => {
        try {
            const data = await documentsService.deleteDocument(id);
            if (data) {
                params?.page !== 1 &&
                    documentList?.length === 1 &&
                    setParams((params: IParams) => {
                        return { ...params, page: (params?.page ?? 1) - 1 };
                    });
                alertSuccessPopup({
                    title: '資料の削除が完了しました。',
                    confirmButtonText: '閉じる',
                });
                handleClose();
                handleCloseDelete();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    useEffect(() => {
        if (isOpenDeleteListFile) {
            const currentRecordFormat = documentList.find(
                (item: { id?: number }, index: number) =>
                    item.id === currentRecord?.id,
            );
            setCurrentRecord(currentRecordFormat);
        }
    }, [isOpenDeleteListFile, documentList]);
    return (
        <>
            <StyledModalUpload
                className="!p-0"
                open={isOpenDeleteListFile}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleClose}
                centered
                width={330}
            >
                <Box
                    sx={{
                        background: '#215493',
                        margin: 0,
                        color: 'white',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                        p: 2,
                        fontSize: '16px',
                    }}
                    id="modal-modal-title"
                    component="h2"
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>削除</span>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                    </div>
                </Box>
                <Box
                    id="modal-modal-description"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: '15px',
                        width: '100%',
                    }}
                >
                    {currentRecord?.files?.map((file: any, index: number) => {
                        const name = file?.file_name ?? '';
                        return (
                            <div
                                key={index}
                                className={clsx(
                                    'flex items-center justify-between  py-2 px-3 bg-[#f8f8f8] w-full rounded-lg max-w-[300px]',
                                    {
                                        'mt-3': index !== 0,
                                    },
                                )}
                            >
                                <div className="flex items-center gap-2">
                                    <div>
                                        <img src={pdfImg} />
                                    </div>
                                    <div>
                                        <div className="text-[10px] font-normal line-clamp-1 w-fit max-w-[139px]">
                                            {name?.replace('.pdf', '')}. PDF
                                        </div>
                                        <div className="text-[8px] text-[#0000008C] font-normal">
                                            {file.size}KB
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className="text-[10px] text-white w-9 rounded-[3px] h-[19px] bg-[#ff5045]"
                                        onClick={() => {
                                            showOpenDelete();
                                            setCurrentFile(file);
                                        }}
                                        type="button"
                                    >
                                        削除
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    <div className="buttonGroup mb-3">
                        <button
                            className="cancelBtn !h-10 !py-0"
                            type="button"
                            onClick={handleClose}
                        >
                            戻る
                        </button>
                        <button
                            className="registerBtn !px-1 !h-10 !py-0 !text-sm !bg-[#ff5045]"
                            type="submit"
                            onClick={() => {
                                handleDeleteDocument(currentRecord?.id);
                            }}
                        >
                            すべて削除
                        </button>
                    </div>
                </Box>
            </StyledModalUpload>
            <StyledModalUpload
                className="!p-0"
                open={isOpenDelete}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleCloseDelete}
                centered
                width={300}
            >
                <div className="d-flex w-full flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]">
                    <div className="flex justify-end pt-3">
                        <CloseOutlined
                            onClick={() => {
                                handleCloseDelete();
                            }}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-2 pb-3">
                        <div className="text-[#3A3B3C] font-bold text-sm pt-[5px]">
                            本当に削除しますか?
                        </div>
                        <div className="flex gap-[10px] w-full mt-6">
                            <button
                                className="modal-create-user-button1 d-flex flex-row !w-full !h-[36px] !rounded-[3px]"
                                onClick={() => {
                                    handleCloseDelete();
                                }}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={() => {
                                    if (currentRecord?.files?.length > 1) {
                                        handleDeleteFileDocument(
                                            currentRecord?.id,
                                            currentFile?.signed_id,
                                        );
                                    } else {
                                        handleDeleteDocument(currentRecord?.id);
                                    }
                                }}
                                className="modal-create-user-button2 d-flex flex-row !w-full !h-[36px] !rounded-[3px]"
                            >
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            </StyledModalUpload>
        </>
    );
};
export default ModalDelete;
