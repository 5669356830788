// Lib
import { required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createProjectValidation = (t: TypeOfT) => ({
    name: () => ({
        required: required('project_name', t),
    }),
    business_type: () => ({
        required: required('business_type', t),
    }),
    client_company_id: () => ({
        required: required('client_company_id', t),
    }),
    manager_worker_id: () => ({
        required: required('manager_worker_id', t),
    }),
    estimate_assigned_worker_id: () => ({
        required: required('estimate_assigned_worker_id', t),
    }),
    start_at: () => ({
        required: required('start_at', t),
    }),
    end_at: () => ({
        required: required('end_at', t),
    }),
    location_address: () => ({
        required: required('location_address', t),
    }),
    location_zipcode: () => ({
        required: required('location_zipcode', t),
    }),
    status: () => ({
        required: required('status', t),
    }),
    construction_site: () => ({
        required: '施工場所を入力してください。',
    }),
    company_name: () => ({
        required: '法人名を入力してください。',
    }),
    person_in_charge: () => ({
        required: '担当者名を入力してください。',
    }),
    content: () => ({
        required: '修繕内容を入力してください。',
    }),
    address: () => ({
        required: '住所を入力してください。',
    }),
    type: () => ({
        required: 'サービスを選択してください。',
    }),
    property_name: () => ({
        required: '物件名を選択してください。',
    }),
    note: () => ({
        required: '公共・法人・個人を選択してください。',
    }),
    start_date: () => ({
        required: '開始を選択してください。',
    }),
    end_date: () => ({
        required: '終了を選択してください。',
    }),
});

export default createProjectValidation;
