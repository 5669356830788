import { TypeOfT } from "utils/type/translations/Translation.type";
import { validZipCode } from "../validator";

const repairValidation = (t?: TypeOfT) => ({
    construction_site: () => ({
        required: '施工場所住所を入力してください。',
    }),
    company_name: () => ({
        required: '法人名を入力してください。',
    }),
    zipCode: () => ({
        required: '現場：郵便番号を入力してください。',
        validate: {
            regex: validZipCode('zipcode', t!),
        }
    }),
    person_in_charge: () => ({
        required: '担当者名を入力してください。',
    }),
    content: () => ({
        required: '修繕内容を入力してください。',
    }),
    address: () => ({
        required: '住所を入力してください。',
    }),
    type: () => ({
        required: 'サービスを選択してください。',
    }),
    property_name: () => ({
        required: '物件名を選択してください。',
    }),
    company_id: () => ({
        required: '協力会社を選択してください。',
    }),
    desired_date: () => ({
        required: '希望工事期間を選択してください。',
    }),
});

export default repairValidation;
