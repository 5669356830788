import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Calendar,
    ClipboardText,
    NotePad,
    UsersFour,
    User,
    WreckingBusinessManagement,
    CaretUp,
} from 'assets/menuIcons';
import { RootState } from '../../store/index';
import './sidebar.css';
import { isAdmin } from 'utils/helper/workerHelper';
import SettingIcon from 'assets/menuIcons/Setting';
import InvoiceIcon from 'assets/menuIcons/InvoiceIcon';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import clsx from 'clsx';

declare let window: any;

const SideBar = (props) => {
    const { setChangeTab, isChangeTab, collapsed, setCollapsed } = props;
    const navigate = useNavigate();

    const { userData } = useSelector((state: RootState) => state.users);

    const [hide, setHide] = useState(false);

    const [path, setPath] = useState('');

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [isShowSupMenu, setShowSupMenu] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const isAllow = isAdmin(userData);

    useEffect(() => {
        let pathTemp = window.location.pathname;
        setPath(pathTemp);
        if (pathTemp == '/login') {
            setHide(true);
        }
    });

    useEffect(() => {
        const handleResize = () => setWindowHeight(window.innerHeight);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const menuItemProps = {
        background: '#7A98BE',
        color: '#FFFFFF',
    };

    const svgProps = {
        strokeColor: '#FFFFFF',
    };

    return (
        <>
            <div
                className={clsx('sidebar relative', {
                    '!min-w-[30px]': collapsed,
                })}
                style={{
                    display: hide ? 'none' : '',
                }}
            >
                <div>
                    {!collapsed && (
                        <div className="header-sidebar text-[28px] font-bold !ml-[20px] !pb-0">
                            {process.env.REACT_APP_PROJECT_TITLE}
                        </div>
                    )}
                    <div className={clsx('flex items-center justify-between')}>
                        {!collapsed && (
                            <div className="menu-list">
                                {/* Users */}
                                <>
                                    {isAllow && (
                                        <p
                                            className="menu-item d-flex flex-row"
                                            onClick={() => {
                                                navigate('/users');
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path === '/users' &&
                                                    menuItemProps),
                                            }}
                                        >
                                            <User
                                                {...(path === '/users' &&
                                                    svgProps)}
                                            />
                                            アカウント
                                        </p>
                                    )}
                                </>
                                {/* <p className="menu-item d-flex flex-row">
                    <img width={'20px'} height={'18px'} src={iconSearch}/>
                    案件検索
                </p> */}

                                {/* calendar */}
                                <p
                                    className="menu-item d-flex flex-row"
                                    onClick={() => {
                                        navigate('/calendar');
                                        setChangeTab(!isChangeTab);
                                    }}
                                    style={{
                                        ...(path === '/calendar' &&
                                            menuItemProps),
                                    }}
                                >
                                    <Calendar
                                        {...(path === '/calendar' && svgProps)}
                                    />
                                    カレンダー
                                </p>

                                {/* Suggestion */}
                                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/suggestions');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...([
                            '/suggestions',
                            '/suggestions/create',
                            '/suggestion/detail',
                        ].includes(path) && menuItemProps),
                    }}
                >
                    <BsMailbox2
                        size={20}
                        color={
                            [
                                '/suggestions',
                                '/suggestions/create',
                                '/suggestion/detail',
                            ].includes(path)
                                ? '#FFFFFF'
                                : '#CDD1D5'
                        }
                    />
                    目安箱
                </p> */}

                                {/* Chat Rooms */}
                                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/chats');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...([
                            '/chats',
                            '/chats/create',
                            '/chats/detail',
                        ].includes(path) && menuItemProps),
                    }}
                >
                    <IoChatbubblesOutline
                        size={20}
                        color={
                            [
                                '/chats',
                                '/chats/create',
                                '/chats/detail',
                            ].includes(path)
                                ? '#FFFFFF'
                                : '#CDD1D5'
                        }
                    />
                    チャットルーム
                </p> */}

                                {/* Create Project */}
                                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/projects/create');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/projects/create' && menuItemProps),
                    }}
                >
                    <FolderPlus
                        {...(path === '/projects/create' && svgProps)}
                    />
                    案件作成
                </p> */}

                                {/* Project list */}
                                <p
                                    className="menu-item d-flex flex-row"
                                    onClick={() => {
                                        navigate('/projects');
                                        setChangeTab(!isChangeTab);
                                    }}
                                    style={{
                                        ...(path === '/projects' ||
                                            (path.startsWith('/projects') &&
                                                path !== '/projects/create')
                                            ? menuItemProps
                                            : {}),
                                    }}
                                >
                                    <ClipboardText
                                        {...((path === '/projects' ||
                                            (path.startsWith('/projects') &&
                                                path !== '/projects/create')) &&
                                            svgProps)}
                                    />
                                    案件
                                </p>
                                {/* 
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/meeting-minutes');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/meeting-minutes' ||
                            path === '/meeting-minutes/detail' ||
                            path === '/meeting-minutes/create' ||
                            path === '/meeting-minutes/edit'
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <ClipboardText
                        {...((path === '/meeting-minutes' ||
                            (path.startsWith('/meeting-minutes') &&
                                path !== '/meeting-minutes/create')) &&
                            svgProps)}
                    />
                    議事録
                </p>
                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/weekly-report');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/weekly-report' ||
                        (path.startsWith('/weekly-report') &&
                            path !== '/weekly-report/create')
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <ClipboardText
                        {...((path === '/weekly-report' ||
                            (path.startsWith('/weekly-report') &&
                                path !== '/weekly-report/create')) &&
                            svgProps)}
                    />
                    週報
                </p> */}
                                {isAllow && (
                                    <>
                                        {/* <p
                            className="menu-item d-flex flex-row"
                            onClick={() => {
                                navigate('/bills');
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === '/bills' && menuItemProps),
                            }}
                        >
                            <Note {...(path === '/bills' && svgProps)} />
                            請求
                        </p>
                        <p
                            className="menu-item d-flex flex-row"
                            onClick={() => {
                                navigate('/estimates');
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === '/estimates' && menuItemProps),
                            }}
                        >
                            <Note {...(path === '/estimates' && svgProps)} />
                            見積
                        </p> */}
                                        {/* Budget */}
                                        {/* <p
                            className="menu-item d-flex flex-row"
                            onClick={() => {
                                navigate('/budgets');
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === '/budgets' && menuItemProps),
                            }}
                        >
                            <NotePad {...(path === '/budgets' && svgProps)} />
                            予実管理
                        </p> */}
                                    </>
                                )}

                                {/* Worker list */}
                                <p
                                    className="menu-item d-flex flex-row"
                                    onClick={() => {
                                        navigate('/workers');
                                        setChangeTab(!isChangeTab);
                                    }}
                                    style={{
                                        ...(path === '/workers' ||
                                            path === '/workers/detail' ||
                                            path === '/workers/create' ||
                                            path === '/workers/edit'
                                            ? menuItemProps
                                            : {}),
                                    }}
                                >
                                    <UsersFour
                                        {...((path === '/workers' ||
                                            path === '/workers/detail' ||
                                            path === '/workers/create' ||
                                            path === '/workers/edit') &&
                                            svgProps)}
                                    />
                                    社員
                                </p>

                                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/internal-ojt');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/internal-ojt' ||
                        path === '/internal-ojt/detail' ||
                        path === '/internal-ojt/create' ||
                        path === '/internal-ojt/edit'
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <img src="/img/ojt.svg" className="w-[24px]" />
                    各種マニュアル
                </p>
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/inspection-records');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/inspection-records'
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <img src="/img/car.svg" className="w-[24px]" />
                    車両点検簿
                </p> */}
                                {/* {isAllow && (
                    <p
                        className="menu-item d-flex flex-row"
                        onClick={() => {
                            navigate('/working_times');
                            setChangeTab(!isChangeTab);
                        }}
                        style={{
                            ...(path === '/working_times' ||
                                path === '/working_times/detail' ||
                                path === '/working_times/create' ||
                                path === '/working_times/edit'
                                ? menuItemProps
                                : {}),
                        }}
                    >
                        <img
                            src="/img/alarm.svg"
                            className="w-[18px] ml-[2px] mr-[4px]"
                        />
                        勤務時間
                    </p>
                )} */}
                                {isAllow && (
                                    <p
                                        className="menu-item d-flex flex-row"
                                        onClick={() => {
                                            navigate(
                                                '/working_times_management',
                                            );
                                            setChangeTab(!isChangeTab);
                                        }}
                                        style={{
                                            ...(path ===
                                                '/working_times_management' ||
                                                path ===
                                                '/working_times_management/detail' ||
                                                path ===
                                                '/working_times_management/create' ||
                                                path ===
                                                '/working_times_management/edit'
                                                ? menuItemProps
                                                : {}),
                                        }}
                                    >
                                        <ClipboardText
                                            {...((path ===
                                                '/working_times_management' ||
                                                (path.startsWith(
                                                    '/working_times_management',
                                                ) &&
                                                    path !==
                                                    '/working_times_management/create')) &&
                                                svgProps)}
                                        />
                                        勤怠管理
                                    </p>
                                )}

                                {isAllow && (
                                    <p
                                        className="menu-item d-flex flex-row"
                                        onClick={() => {
                                            navigate('/work-time-chart');
                                            setChangeTab(!isChangeTab);
                                        }}
                                        style={{
                                            ...(path === '/work-time-chart' ||
                                                path ===
                                                '/work-time-chart/detail' ||
                                                path ===
                                                '/work-time-chart/create' ||
                                                path === '/work-time-chart/edit'
                                                ? menuItemProps
                                                : {}),
                                        }}
                                    >
                                        <img
                                            src="/img/alarm.svg"
                                            className="w-[18px] ml-[2px] mr-[4px]"
                                        />
                                        勤務時間
                                    </p>
                                )}

                                <div
                                    className="sub-menu menu-item d-flex flex-row"
                                    style={{
                                        justifyContent: 'space-between',
                                    }}
                                    onClick={() =>
                                        setShowSupMenu(!isShowSupMenu)
                                    }
                                >
                                    <p
                                        className="d-flex flex-row"
                                        style={{
                                            gap: 12,
                                            ...(isShowSupMenu && {
                                                color: '#FFFFFF',
                                            }),
                                        }}
                                    >
                                        <WreckingBusinessManagement
                                            {...(isShowSupMenu && svgProps)}
                                        />
                                        マスタ管理
                                    </p>
                                    <div
                                        className={`arrow-icon ${isShowSupMenu ? 'open' : ''
                                            }`}
                                    >
                                        <CaretUp
                                            {...(isShowSupMenu && svgProps)}
                                        />
                                    </div>
                                </div>
                                {isShowSupMenu && (
                                    <div
                                        className="dropdown-container"
                                        style={{ paddingBottom: '6px' }}
                                    >
                                        <p
                                            className="menu-item"
                                            onClick={() => {
                                                navigate('/companies');
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path === '/companies' ||
                                                    path?.includes(
                                                        '/companies/detail',
                                                    ) ||
                                                    path?.includes(
                                                        '/companies/create',
                                                    ) ||
                                                    path?.includes(
                                                        '/companies/edit',
                                                    )
                                                    ? menuItemProps
                                                    : {}),
                                            }}
                                        >
                                            顧客会社
                                        </p>
                                        <p
                                            className="menu-item"
                                            onClick={() => {
                                                navigate(
                                                    '/cooperative-companies',
                                                );
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path ===
                                                    '/cooperative-companies' ||
                                                    path?.includes(
                                                        '/cooperative-companies/detail',
                                                    ) ||
                                                    path?.includes(
                                                        '/cooperative-companies/create',
                                                    ) ||
                                                    path?.includes(
                                                        '/cooperative-companies/edit',
                                                    )
                                                    ? menuItemProps
                                                    : {}),
                                            }}
                                        >
                                            協力会社
                                        </p>
                                        <p
                                            className="menu-item"
                                            onClick={() => {
                                                navigate('/news');
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path === '/news'
                                                    ? menuItemProps
                                                    : {}),
                                            }}
                                        >
                                            お知らせ
                                        </p>
                                        {/* <p className="menu-item">リスクアセスメント</p> */}
                                        <p
                                            className="menu-item"
                                            onClick={() => {
                                                navigate('/departments');
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path === '/departments' &&
                                                    menuItemProps),
                                            }}
                                        >
                                            部署
                                        </p>
                                        <p
                                            className="menu-item"
                                            onClick={() => {
                                                navigate('/positions');
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path === '/positions' &&
                                                    menuItemProps),
                                            }}
                                        >
                                            役職
                                        </p>
                                        <p
                                            className="menu-item"
                                            onClick={() => {
                                                navigate('/document');
                                                setChangeTab(!isChangeTab);
                                            }}
                                            style={{
                                                ...(path === '/document' &&
                                                    menuItemProps),
                                            }}
                                        >
                                            資料管理
                                        </p>
                                    </div>
                                )}
                                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/setting');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/setting' || path.startsWith('/setting')
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <FileArrowDown
                        {...((path === '/setting' ||
                            path.startsWith('/setting')) &&
                            svgProps)}
                    />
                    設定
                </p>
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/stock_notes');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/stock_notes' ||
                        path === '/stock_notes/detail' ||
                        path === '/stock_notes/create' ||
                        path === '/stock_notes/edit'
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <Note {...(path === '/stock_notes' && svgProps)} />
                    納品書一覧
                </p> */}

                                {/* Users */}
                                <p
                                    className="menu-item d-flex flex-row"
                                    onClick={() => {
                                        navigate('/quote/list');
                                        setChangeTab(!isChangeTab);
                                    }}
                                    style={{
                                        ...((path.includes('/quote/list') ||
                                            path === '/') &&
                                            !path.includes(
                                                '/quote/list/invoice',
                                            ) &&
                                            menuItemProps),
                                    }}
                                >
                                    <NotePad
                                        {...((path.includes('/quote/list') ||
                                            path === '/') &&
                                            svgProps)}
                                    />
                                    見積管理
                                </p>
                                <p
                                    className="menu-item d-flex flex-row"
                                    onClick={() => {
                                        navigate('/invoices');
                                        setChangeTab(!isChangeTab);
                                    }}
                                    style={{
                                        ...(path.includes('/invoice') &&
                                            menuItemProps),
                                    }}
                                >
                                    <InvoiceIcon
                                        {...(path === '/invoices' && svgProps)}
                                    />
                                    請求管理
                                </p>
                                <p
                                    className="menu-item d-flex flex-row"
                                    onClick={() => {
                                        navigate('/company-info-setting');
                                        setChangeTab(!isChangeTab);
                                    }}
                                    style={{
                                        ...(path.includes(
                                            '/company-info-setting',
                                        ) && menuItemProps),
                                    }}
                                >
                                    <SettingIcon
                                        {...(path.includes(
                                            '/company-info-setting',
                                        ) && svgProps)}
                                    />
                                    設定
                                </p>

                                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/invoice/list');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/invoice/list' && menuItemProps),
                    }}
                >
                    <NotePad {...(path === '/invoice/list' && svgProps)}/>
                    請求書発行
                </p> */}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="flex justify-center items-center"
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: !collapsed ? '250px' : '10px',
                        transform: 'translateY(-50%)',
                        height: `${windowHeight}px`,
                    }}
                >
                    <div
                        onClick={toggleCollapsed}
                        className="rounded-xl cursor-pointer border-[1px] border-solid border-[#ffffff] border-r-0 h-[150px] w-[20px] flex justify-center items-center"
                    >
                        <div className="text-[white] ">
                            {collapsed ? <RightOutlined /> : <LeftOutlined />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SideBar;
