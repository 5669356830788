import dayjs from 'dayjs';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import workersService from 'services/wokersService';
import WorkingTimeService from 'services/workingTimesService';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import { ObjectToFormData } from 'utils/helper/helpers';

interface IParamItemPair {
    submit_date: Date | string;
    worker_id: number | string;
    checkin_item_id: number;
    checkout_item_id?: number;
}

interface IItemPair {
    check_in_time?: string | Date;
    check_out_time?: string | Date;
    checkin_item_id?: number;
    checkin_location?: string;
    checkout_item_id?: number;
    checkout_location?: string;
    submit_date?: string | Date;
    worker_name?: string;
}

const useWorkingTimesManagementsCreate = (props: any) => {
    const [workerOptions, setWorkerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [startTimeDetail, setStartTimeDetail] = useState<string | Date | undefined>();
    const [endTimeDetail, setEndTimeDetail] = useState<string | Date | undefined>();
    const [isErrorWorker, setIsErrorWorker] = useState(false);
    const [isErrorSubmitDate, setIsErrorSubmitDate] = useState(false);
    const [isErrorStartDate, setIsErrorStartDate] = useState(false);
    const [isErrorEndDate, setIsErrorEndDate] = useState(false);
    const [itemPairDetail, setItemPairDetail] = useState<IItemPair>();
    const location = useLocation();
    const locationState = (location.state as IParamItemPair);

    const [searchParams] = useSearchParams();
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const { id } = useParams();
    const fetchWorkers = async () => {
        try {
            const { workers } = await workersService.getListWorkers();
            if (workers) {
                setWorkerOptions(
                    workers?.map((worker) => ({
                        label: worker?.name,
                        value: worker?.id,
                    })),
                );
            }
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => createWorkingTime(values),
    });

    const createWorkingTime = async (params) => {
        try {
            if (!id) {
                const formData = ObjectToFormData({ ...params, worker_id: params?.worker_id?.value });
                await WorkingTimeService.createWorkingTimes(formData);
            } else {
                const formData = ObjectToFormData({ ...params, worker_id: params?.worker_id?.value, id: id });
                await WorkingTimeService.updateWorkingTimes(id, formData);
            }
            navigate('/working_times_management');
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const onSubmit = async (value) => {
        if (!value?.worker_id) {
            setIsErrorWorker(true);
        }
        if (!value?.submit_date) {
            setIsErrorSubmitDate(true)
        }
        if (!value?.start_time) {
            setIsErrorStartDate(true)
        }
        if (!value?.end_time) {
            setIsErrorEndDate(true)
        }
        if (!value?.worker_id || !value?.start_time || !value?.end_time) return;
        if (new Date(value?.start_time) > new Date(value?.end_time)) return;
        const today = new Date().setHours(0, 0, 0, 0)
        const body = {
            checkin_location: value?.checkin_location,
            checkout_location: value?.checkout_location,
            worker_id: value?.worker_id,
            submit_date: new Date(value?.submit_date),
            start_time: new Date(value?.start_time),
            end_time: new Date(value?.end_time),
        }
        if (new Date(value?.start_time).getTime() > new Date(value?.end_time).getTime()) {
            alertError('開始時間が終了時間より後にはできません。')
            return
        }
        if ((new Date(value?.submit_date).getDate() !== new Date(value?.start_time).getDate()) ||
            (new Date(value?.submit_date).getMonth() !== new Date(value?.start_time).getMonth()) ||
            (new Date(value?.submit_date).getFullYear() !== new Date(value?.start_time).getFullYear()) ||
            (new Date(value?.submit_date).getDate() !== new Date(value?.end_time).getDate()) ||
            (new Date(value?.submit_date).getMonth() !== new Date(value?.end_time).getMonth()) ||
            (new Date(value?.submit_date).getFullYear() !== new Date(value?.end_time).getFullYear())

        ) {
            alertError('開始時間が終了時間より後にはできません。')
            return
        }
        try {
            if (!id) {
                const res = await WorkingTimeService.createWorkingTimes(body);
                if (res) {
                    alertSuccess('勤怠を登録しました。')
                    navigate('/working_times_management');
                } else {
                    alertError('操作に失敗しました。')
                }
            } else {
                let newParams = { ...value, submit_date: moment(new Date(value?.start_time)).format('YYYY-MM-DD') }
                if (startTimeDetail && new Date(value?.start_time).getTime() == new Date(startTimeDetail).getTime()) {
                    delete newParams?.start_time
                } else if (endTimeDetail && new Date(value?.end_time).getTime() == new Date(endTimeDetail).getTime()) {
                    delete newParams?.end_time
                }

                const formData = ObjectToFormData({ ...newParams, worker_id: value?.worker_id, id: id, set_checkout: !itemPairDetail?.check_out_time ? true : false });

                const cloneParamsCheckin = { ...newParams }
                delete cloneParamsCheckin?.end_time
                const cloneParamsCheckout = { ...newParams, set_checkout: !itemPairDetail?.check_out_time ? true : false }
                delete cloneParamsCheckout?.start_time

                if ((endTimeDetail && new Date(value?.end_time).getTime() !== new Date(endTimeDetail).getTime() && startTimeDetail &&
                    new Date(value?.start_time).getTime() !== new Date(startTimeDetail).getTime()) ||
                    (startTimeDetail && !endTimeDetail && new Date(value?.start_time).getTime()
                        !== new Date(startTimeDetail).getTime() && value?.end_time)) {
                    const res = await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkin_item_id,
                        ObjectToFormData({ ...cloneParamsCheckin, worker_id: value?.worker_id?.value, id: id }));
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkout_item_id,
                        ObjectToFormData({ ...cloneParamsCheckout, worker_id: value?.worker_id?.value, id: id }));
                    if (res) {
                        alertSuccess('勤怠を更新しました。')
                        navigate('/working_times_management');
                    } else {
                        alertError('操作に失敗しました。')
                    }
                } else if (startTimeDetail && new Date(value?.start_time).getTime() !== new Date(startTimeDetail).getTime()) {
                    const res = await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkin_item_id, formData);
                    if (res) {
                        alertSuccess('勤怠を更新しました。')
                        navigate('/working_times_management');
                    } else {
                        alertError('操作に失敗しました。')
                    }
                } else if ((endTimeDetail && new Date(value?.end_time).getTime() !== new Date(endTimeDetail).getTime()) ||
                    (startTimeDetail && !endTimeDetail && new Date(value?.start_time).getTime() === new Date(startTimeDetail).getTime())) {
                    const res = await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkout_item_id, formData);
                    if (res) {
                        alertSuccess('勤怠を更新しました。')
                        navigate('/working_times_management');
                    } else {
                        alertError('操作に失敗しました。')
                    }
                } else {
                    const res = await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkin_item_id,
                        ObjectToFormData({ ...cloneParamsCheckin, worker_id: value?.worker_id?.value, id: id }));
                    await WorkingTimeService.updateWorkingTimeItemPC(itemPairDetail?.checkout_item_id,
                        ObjectToFormData({ ...cloneParamsCheckout, worker_id: value?.worker_id?.value, id: id }));
                    if (res) {
                        alertSuccess('勤怠を更新しました。')
                        navigate('/working_times_management');
                    } else {
                        alertError('操作に失敗しました。')
                    }
                }

                // const res = await WorkingTimeService.updateWorkingTimes(id, body);
                // if (res) {
                //     alertSuccess('勤怠を更新しました。')
                //     navigate('/working_times_management');
                // } else {
                //     alertError('操作に失敗しました。')
                // }
            }
        } catch (error) {
            alertError('操作に失敗しました。')
        }
    }

    const fetchWorkingTimeDetail = async () => {
        if (!id || !locationState?.checkin_item_id) return;
        let params = {
            //worker_id: locationState?.worker_id,
            //submit_date: locationState?.submit_date,
            checkin_item_id: locationState?.checkin_item_id,
            checkout_item_id: locationState?.checkout_item_id
        }
        if (!locationState?.checkout_item_id) delete params.checkout_item_id;
        try {
            setLoading(true)
            // const { working_time_management }: any =
            //     await WorkingTimeService.getWorkingTimeDetail(id, {
            //         worker_id: searchParams?.get('worker_id'),
            //     });
            const res = await WorkingTimeService.getWorkingTimeItemPair(params);
            if (!(res as { result: IItemPair[] })?.result) return;
            const working_time_management = (res as { result: IItemPair[] })?.result[0];
            setValue('checkin_location', working_time_management?.checkin_location)
            setValue('checkout_location', working_time_management?.checkout_location)
            // setValue('start_time', dayjs(working_time_management?.start_time))
            // setValue('worker_id', working_time_management?.worker?.id)
            // setValue('end_time', dayjs(working_time_management?.end_time))
            setValue('start_time', dayjs(working_time_management?.check_in_time))
            setValue('worker_id', locationState?.worker_id)
            setValue('end_time', dayjs(working_time_management?.check_out_time))
            setValue('submit_date', dayjs(working_time_management?.submit_date))
            setLoading(false)
            setStartTimeDetail(working_time_management?.check_in_time)
            setEndTimeDetail(working_time_management?.check_out_time)
            setItemPairDetail(working_time_management)
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    useEffect(() => {
        fetchWorkers();
    }, []);

    useEffect(() => {
        fetchWorkingTimeDetail();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        workerOptions,
        loading,
        control,
        setValue,
        register,
        handleSubmit,
        errors,
        clearErrors,
        watch,
        getValues,
        setError,
        onSubmit,
        isErrorSubmitDate,
        isErrorWorker,
        isErrorStartDate,
        isErrorEndDate
    };
};

export type Props = ReturnType<typeof useWorkingTimesManagementsCreate>;

export default useWorkingTimesManagementsCreate;
