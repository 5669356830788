import {
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import clsx from 'clsx';
import { LeftOutlinedCustom } from 'pages/invoices';
import { textTrim } from 'utils/helper/appHelper';
import { RightOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { IParams, typeSelect } from '..';
import dayjs from 'dayjs';
import ModalListFile from '../modal-list-file';
import ModalDelete from '../modal-delete';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c7dcf5',
        color: '#020202',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderBottom: 'none',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TableRowCustom = (props) => {
    const { row, setCurrentRecord, showOpenListFile, showOpenDeleteListFile } =
        props;

    const navigate = useNavigate();
    const data_type_format = row?.data_type
        ? typeSelect?.find((item) => item?.value === row?.data_type)?.label
        : '';
    return (
        <StyledTableRow>
            <StyledTableCell align="left" component="th" scope="row">
                <div className="text-sm">
                    {row?.name ? textTrim(row?.name, 20) : ''}
                </div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className="text-sm">
                    {row?.created_at
                        ? dayjs(row?.created_at).format('YYYY/MM/DD')
                        : ''}
                </div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div
                    className="text-overflow1 text-sm"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {data_type_format}
                </div>
            </StyledTableCell>
            <StyledTableCell align="center">
                <div
                    className="text-[#4759ff] text-sm"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {row?.files?.map((item: any, index?: number) => (
                        <div
                            key={index}
                            className={clsx('w-full flex justify-center', {
                                'mt-1': index !== 0,
                            })}
                        >
                            <a
                                className="no-underline"
                                target="blank"
                                href={item.file_path}
                            >
                                {item?.file_name
                                    ? textTrim(item?.file_name, 45)
                                    : ''}
                            </a>
                        </div>
                    ))}
                </div>
            </StyledTableCell>

            <StyledTableCell align="center">
                <div className="flex gap-2 w-full justify-center">
                    <button
                        className="button-tb !text-sm"
                        style={{ width: '104px', padding: 0 }}
                        onClick={() => {
                            setCurrentRecord(row);
                            showOpenListFile();
                        }}
                    >
                        ダウンロード
                    </button>
                    <button
                        className="button-tb !text-sm"
                        style={{
                            width: '72px',
                            backgroundColor: '#ff5045',
                        }}
                        onClick={() => {
                            showOpenDeleteListFile();
                            setCurrentRecord(row);
                        }}
                    >
                        削除
                    </button>
                </div>
            </StyledTableCell>
        </StyledTableRow>
    );
};

const TableDocument = (props) => {
    const {
        documentList,
        totalPage,
        page,
        setParams,
        setCurrentRecord,
        setIsOpenListFile,
        isOpenListFile,
        currentRecord,
        isOpenDeleteListFile,
        setIsOpenDeleteListFile,
        params,
        getListDocument,
    } = props;
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);

    const setPage = (newPage?: any) => {
        setParams((prev: IParams) => ({
            ...prev,
            page: newPage,
        }));
        setStatusPrev(newPage < page);
        setStatusNext(newPage > page);
    };

    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
        }
    };

    const showOpenListFile = () => {
        setIsOpenListFile(true);
    };

    const showOpenDeleteListFile = () => {
        setIsOpenDeleteListFile(true);
    };
    return (
        <>
            <div className="d-flex flex-column mt-4">
                <TableContainer component={Paper} table-layout={'auto'}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    className="text-overflow1"
                                    align="left"
                                    style={{ width: '10%' }}
                                >
                                    <div>案件名</div>
                                </StyledTableCell>
                                <StyledTableCell
                                    className="text-overflow1"
                                    align="left"
                                    style={{ width: '20%' }}
                                >
                                    <div>アップロード日</div>
                                </StyledTableCell>
                                <StyledTableCell
                                    className="text-overflow1"
                                    align="center"
                                    style={{ width: '10%' }}
                                >
                                    <div>Type</div>
                                </StyledTableCell>
                                <StyledTableCell
                                    className="text-overflow1"
                                    align="center"
                                    style={{ width: '40%' }}
                                >
                                    <div>ファイル名</div>
                                </StyledTableCell>
                                <StyledTableCell
                                    className="text-overflow1"
                                    align="center"
                                    style={{
                                        width: '20%',
                                    }}
                                >
                                    <div>操作</div>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documentList !== null &&
                                documentList.map((row, ind) => (
                                    <TableRowCustom
                                        key={ind}
                                        row={row}
                                        className="!bg-[red]"
                                        setCurrentRecord={setCurrentRecord}
                                        showOpenListFile={showOpenListFile}
                                        showOpenDeleteListFile={
                                            showOpenDeleteListFile
                                        }
                                    />
                                ))}
                        </TableBody>
                    </Table>
                    {documentList?.length === 0 && (
                        <div className="text-center p-[10px] w-full">
                            データがありません。
                        </div>
                    )}
                </TableContainer>
                {documentList && documentList.length > 0 && (
                    <>
                        <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px] !shadow-none">
                            <p className="font-normal text-[14px] text-[#666666]">
                                ページ {page} / {totalPage}
                            </p>
                            <div className="flex gap-[25px]">
                                <LeftOutlinedCustom
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black]':
                                                statusPrev && page !== 1,
                                            '!text-[#D3D3D3]': page === 1,
                                        },
                                    )}
                                    onClick={setPagePrev}
                                    disabled
                                />

                                <RightOutlined
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black] inline-flex':
                                                statusNext &&
                                                page !== totalPage,
                                            '!text-[#D3D3D3]':
                                                page === totalPage,
                                        },
                                    )}
                                    onClick={setPageNext}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <ModalListFile
                isOpenListFile={isOpenListFile}
                setIsOpenListFile={setIsOpenListFile}
                currentRecord={currentRecord}
            />
            <ModalDelete
                isOpenDeleteListFile={isOpenDeleteListFile}
                setIsOpenDeleteListFile={setIsOpenDeleteListFile}
                currentRecord={currentRecord}
                params={params}
                setParams={setParams}
                documentList={documentList}
                getListDocument={getListDocument}
                setCurrentRecord={setCurrentRecord}
            />
        </>
    );
};
export default TableDocument;
