// Lib
import { max, min, required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const workTimeManagementValidation = (t?: TypeOfT) => ({
    worker_id: () => ({
        required: '名前を入力してください。',
    }),
    submit_date: () => ({
        required: '日付を入力してください。',
    }),
    start_time: () => ({
        required: '出勤時間を入力してください。',
    }),
    end_time: () => ({
        required: '退勤時間を入力してください。',
    }),
    checkin_location: () => ({
        required: '位置を入力してください。',
    }),
    checkout_location: () => ({
        required: '位置を入力してください。',
    }),
});

export default workTimeManagementValidation;