import styled from 'styled-components';

export const StylesPrivateMessage = styled.div`
    .message-icon {
        position: fixed;
        right: 24px;
        bottom: 24px;
        z-index: 9999;
               width: 60px;
        height: 60px;
        cursor: pointer;
    }
    .private-message {
        background-color: #fff;
        border-radius: 8px;
        padding: 24px;
    }
`;
