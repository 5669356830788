import moment, { Moment } from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WorkingTimeService from 'services/workingTimesService';
import { RootState } from 'store';
import { ObjectToFormData } from 'utils/helper/helpers';
import { alertError } from 'utils/helper/appHelper';
import { usePosition } from 'use-position';
import { DatePickerProps } from 'antd';
interface IWorkingTime {
    id?: number;
    check_in_time?: string | Date | null;
    check_out_time?: string | Date | null;
    checkin_location?: string | null;
    checkout_location?: string | null;
    worker_id?: number;
    checkin_item_id?: number;
    checkout_item_id?: number;
    id_in?: number;
    id_out?: number;
    is_create?: boolean;
    is_manual_checkin?: boolean;
    is_manual_checkout?: boolean;
}

interface IWorkingTimeManagement {
    working_time_items?: IWorkingTime[];
}

interface IDataItemUpdate {
    worker_id?: number;
    start_time?: Date | string | null;
    end_time?: Date | string | null;
}
const useTimeKeeping = (props: any) => {
    const [workingTime, setWorkingTime] = useState<any>();
    const watch = true;
    const [isShowEditForm, setIsShowEditForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState<IWorkingTime>();
    const [workingTimeItemsBefore, setWorkingTimeItemsBefore] = useState<IWorkingTime[]>();

    const { latitude, longitude } =
        usePosition(watch);
    const { userData }: any = useSelector((state: RootState) => state.users);

    const onChangeStartTime: DatePickerProps['onChange'] = (date, dateString) => {
        setSelectedItem({ ...selectedItem, check_in_time: dateString, id_in: selectedItem?.checkin_item_id })
    };
    const onChangeEndTime: DatePickerProps['onChange'] = (date, dateString) => {
        setSelectedItem({ ...selectedItem, check_out_time: dateString, id_out: selectedItem?.checkout_item_id, is_create: selectedItem?.checkout_item_id ? false : true })
    };

    const handleUpdateWorkingTimeItem = async () => {
        const data: IDataItemUpdate = {
            worker_id: selectedItem?.worker_id,
            start_time: selectedItem?.check_in_time ? moment(selectedItem.check_in_time).toISOString() : null,
            end_time: selectedItem?.check_out_time ? moment(selectedItem?.check_out_time).toISOString() : null
        }
        if (!data?.worker_id) return;
        try {
            if (selectedItem?.id_in && selectedItem?.id_out) {
                const data1 = {
                    worker_id: selectedItem?.worker_id,
                    start_time: selectedItem?.check_in_time ? moment(selectedItem.check_in_time).toISOString() : null,
                }
                const data2 = {
                    worker_id: selectedItem?.worker_id,
                    end_time: selectedItem?.check_out_time ? moment(selectedItem?.check_out_time).toISOString() : null
                }
                const res1 = await WorkingTimeService.updateWorkingTimeItem(selectedItem?.id_in, data1);
                const res2 = await WorkingTimeService.updateWorkingTimeItem(selectedItem?.id_out, data2);
                if (res1 && res2) {
                    fetchWorkingTime();
                    setIsShowEditForm(false);
                    fetchWorkingTimeDayBeforeToday();
                }

            } else if (selectedItem?.id_in && !selectedItem?.id_out) {
                delete data?.end_time;
                const res = await WorkingTimeService.updateWorkingTimeItem(selectedItem?.id_in, data);
                if (res) {
                    fetchWorkingTime();
                    setIsShowEditForm(false);
                    fetchWorkingTimeDayBeforeToday();
                }
            } else if (selectedItem?.id_out && !selectedItem?.id_in) {
                delete data?.start_time
                const res = await WorkingTimeService.updateWorkingTimeItem(selectedItem?.id_out, data);
                if (res) {
                    fetchWorkingTime();
                    setIsShowEditForm(false);
                    fetchWorkingTimeDayBeforeToday();
                }
            } else if (!selectedItem?.id_out && !selectedItem?.id_in && selectedItem?.is_create) {
                if (!latitude) return;
                const formData = ObjectToFormData({
                    latitude,
                    longitude,
                    worker_id: userData?.worker?.id,
                    submit_date: selectedItem?.check_out_time ? moment(selectedItem?.check_out_time).toISOString() : null,
                    end_time: selectedItem?.check_out_time ? moment(selectedItem?.check_out_time).toISOString() : null,
                });
                try {
                    await WorkingTimeService.createWorkingTimesSP(formData);
                    fetchWorkingTime();
                    fetchWorkingTimeDayBeforeToday();
                    setIsShowEditForm(false);
                } catch (error) {
                    const response = error?.response;
                    alertError(response.data?.error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchWorkingTime = async () => {
        try {
            const { working_time_management }: any =
                await WorkingTimeService.getWorkingTimeToday({
                    submit_date: moment().zone('+9:00').format('YYYY-MM-DD'),
                    worker_id: userData?.worker?.id,
                });
            const arrWorkingTimeItemsPair = working_time_management?.working_time_items_pair?.map(item => {
                return { ...item, isEdit: item?.checkin_item_id && item?.checkout_item_id ? true : false }
            })
            setWorkingTime({ ...working_time_management, working_time_items_pair: arrWorkingTimeItemsPair });
        } catch (error) {
            const response = error?.response;
            if (response?.data?.error) {
                alertError(response?.data?.error);
            }
        }
    };

    const fetchWorkingTimeDayBeforeToday = async () => {
        try {
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const res =
                await WorkingTimeService.getWorkingTimeToday({
                    submit_date: moment(yesterday).zone('+9:00').format('YYYY-MM-DD'),
                    worker_id: userData?.worker?.id,
                });
            if (!(res as { working_time_management: IWorkingTimeManagement })?.working_time_management) return;
            const working_time_management = (res as { working_time_management: IWorkingTimeManagement })?.working_time_management;
            setWorkingTimeItemsBefore(working_time_management?.working_time_items)
        } catch (error) {
            const response = error?.response;
            if (response?.data?.error) {
                alertError(response?.data?.error);
            }
        }
    };

    const handleCheckin = async () => {
        //if (workingTime?.start_time) return;
        const formData = ObjectToFormData({
            latitude: latitude || '',
            longitude: longitude || '',
            worker_id: userData?.worker?.id,
            submit_date: moment().zone('+9:00').format('YYYY-MM-DD HH:mm:ss'),
        });
        try {
            await WorkingTimeService.checkin(formData);
            fetchWorkingTime();
            fetchWorkingTimeDayBeforeToday();
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const handleCheckout = async () => {
        //if (workingTime?.end_time || !workingTime.start_time) return;
        const formData = ObjectToFormData({
            latitude: latitude || '',
            longitude: longitude || '',
            worker_id: userData?.worker?.id,
            submit_date: moment().zone('+9:00').format('YYYY-MM-DD HH:mm:ss'),
        });
        try {
            await WorkingTimeService.checkout(formData);
            fetchWorkingTime();
            fetchWorkingTimeDayBeforeToday();
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    useEffect(() => {
        fetchWorkingTime();
        fetchWorkingTimeDayBeforeToday();
    }, []);

    return {
        ...props,
        handleCheckin,
        handleCheckout,
        workingTime,
        loading: !latitude,
        isShowEditForm,
        setIsShowEditForm,
        onChangeStartTime,
        onChangeEndTime,
        selectedItem,
        setSelectedItem,
        handleUpdateWorkingTimeItem,
        workingTimeItemsBefore
    };
};

export type Props = ReturnType<typeof useTimeKeeping>;

export default useTimeKeeping;
