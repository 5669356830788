import Api from './api/performApi';

const createPerform = async (formData?: FormData) => {
    return await Api()
        .post(``, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updatePerform = async (implement_id: number, formData?: FormData) => {
    return await Api()
        .put(`${implement_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deletePerform = async (
    implement_id: number,
    implement_item_id: number,
    project_id: number,
) => {
    return await Api()
        .delete(`${implement_id}/implement_items/${implement_item_id}`, {
            data: { project_id: project_id },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListPerform = async (values?: {
    id?: number;
    project_id?: number;
}) => {
    return await Api()
        .get(`${values?.id}`, { params: { project_id: values?.project_id } })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const performService = {
    createPerform,
    updatePerform,
    getListPerform,
    deletePerform,
};

export default performService;
