import usePrivateMessage, { Props } from './hook';
import { ReceivedProps } from './type';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { StylesPrivateMessage } from './styled';
import {
    Box,
    Grid,
    TextField,
    InputAdornment,
    Button,
    Autocomplete,
    Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import moment from 'moment';
import ChatBoxPrivateMessage from './chatbox';

const PrivateMessageLayout = ({
    ModalPrivateMessage,
    openPrivateMessage,
    closePrivateMessage,
    isOpenPrivateMessage,
    loading,
    histories,
    activeMessage,
    userData,
    setActiveMesssage,
    fetchChats,
    listUnread,
    fetchHasUnreadChat,
    workersOptions,
    setListMessage,
    handleReceived,
    listMessage,
    setHistories,
    handleReadChat,
    isInHistories,
    setIsInHistories,
    fetchMessages,
    onReadMessage
}: Props) => {
    return (
        <StylesPrivateMessage>
            <ActionCableConsumer
                channel={{
                    channel: 'ChatStatusChannel',
                    user_id: userData.id,
                }}
                onReceived={(response) => handleReceived(response)}
            ></ActionCableConsumer>

            <ModalPrivateMessage>
                <Box
                    sx={{
                        width: 'calc(100vw - 302px)',
                        height: '80vh',
                        '.input-search': {
                            border: '1px solid #cdd1d5',
                            borderRadius: '8px',
                            marginBottom: '14.5px',
                            paddingLeft: '14px',
                        },
                        '.MuiTextField-root': {
                            border: '1px solid #cdd1d5',
                            width: '100%',
                            borderRadius: '8px',
                            marginRight: '12.5px',
                        },
                        '.MuiInputBase-input': {
                            height: '35px',
                            padding: '0px 0px 0px 0px !important',
                        },
                        '.scrollbar-container': {
                            overflow: 'scroll !important',
                            overscrollBehavior: 'contain',
                        },
                        '.scrollbar-container::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                    className="bg-white p-6 rounded-lg"
                >
                    <Grid height="100%" columnSpacing={3} container>
                        <Grid height="100%" item xs={3}>
                            <Box
                                position="sticky"
                                top={0}
                                bgcolor="#fff"
                                paddingBottom="26px"
                            >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={workersOptions}
                                    sx={{
                                        padding: '0px',
                                        '.MuiInputBase-root': {
                                            padding: '5px 12px !important',
                                        },
                                        '.MuiAutocomplete-endAdornment': {
                                            display: 'none',
                                        },
                                        '.MuiInputBase-input': {
                                            height: '20px',
                                        },
                                    }}
                                    onChange={(event, newValue: any) => {
                                        const workerMessage = histories?.find(
                                            (history) =>
                                                history?.workers?.[1]?.id ===
                                                newValue.value,
                                        );
                                        if (workerMessage?.id) {
                                            setIsInHistories(true);
                                            setActiveMesssage(workerMessage);
                                        } else {
                                            setIsInHistories(false);
                                            setActiveMesssage({
                                                workerId: newValue.value,
                                                workerName: newValue.label,
                                            });
                                        }
                                    }}
                                    noOptionsText="該当なし"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            sx={{
                                                                color: '#000',
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label=""
                                        />
                                    )}
                                />

                                <Box
                                    display="flex"
                                    marginTop="10px"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        sx={{
                                            backgroundColor: '#215493',
                                            width: '100px',
                                            height: '32px',
                                            borderRadius: '8px',
                                        }}
                                        variant="contained"
                                    >
                                        検索
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    '.history:last-child': {
                                        marginBottom: '0px !important',
                                    },
                                    overflow: 'scroll',
                                    height: 'calc(100% - 104px)',
                                    overscrollBehavior: 'contain',
                                }}
                            >
                                {histories?.map((history) => (
                                    <Box
                                        key={history.id}
                                        onClick={() => {
                                            setActiveMesssage(history)
                                            setIsInHistories(true)
                                        }
                                        }
                                        borderBottom="1px solid #CDD1D5"
                                        className={`${activeMessage?.id === history.id &&
                                            'bg-[#C7DCF5]'
                                            } cursor-pointer history`}
                                        padding="8px"
                                    >
                                        <Typography className="!text-[18px]">
                                            {
                                                history?.workers?.find(
                                                    (worker) =>
                                                        worker.id !==
                                                        userData.worker.id,
                                                )?.name
                                            }
                                        </Typography>
                                        <Typography
                                            className={`text-xs !text-[#333] ${listUnread
                                                ?.filter(
                                                    (item) =>
                                                        item.has_unread_chat,
                                                )
                                                .find(
                                                    (item) =>
                                                        item.direct_chat_id ===
                                                        history.id,
                                                ) && '!font-bold'
                                                }`}
                                        >
                                            {history?.last_message}
                                        </Typography>
                                        <Typography className="!text-xs !text-[#666]">
                                            {history?.last_message_published_at
                                                ? moment(
                                                    history?.last_message_published_at,
                                                )
                                                    ?.zone('+09:00')
                                                    .format(
                                                        'YYYY/MM/DD HH:mm:ss',
                                                    )
                                                : ''}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box
                                padding="14px 16px"
                                bgcolor="#F3F3F3"
                                borderRadius="8px"
                            >
                                <Typography fontSize="18px">
                                    {
                                        activeMessage?.workerName ? activeMessage?.workerName : (
                                            activeMessage?.workers?.find(
                                                (worker) =>
                                                    worker.id !==
                                                    userData.worker.id,
                                            )?.name
                                        )
                                    }
                                </Typography>
                            </Box>
                            <ChatBoxPrivateMessage
                                setListMessage={setListMessage}
                                listMessage={listMessage}
                                fetchHasUnreadChat={fetchHasUnreadChat}
                                activeMessage={activeMessage}
                                fetchChats={fetchChats}
                                loading={loading}
                                setHistories={setHistories}
                                setActiveMessage={setActiveMesssage}
                                isInHistories={isInHistories}
                                setIsInHistories={setIsInHistories}
                                fetchListMessage={fetchMessages}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </ModalPrivateMessage>
            <a onClick={onReadMessage}
                href={process.env.REACT_APP_LINE_URL} target='_blank'>
                <img
                    src={
                        listUnread?.length > 0
                            ? '/img/chat-noti.svg'
                            : '/img/chat.svg'
                    }
                    height={80}
                    width={80}
                    className="message-icon"
                    alt="chat"
                />
            </a>
        </StylesPrivateMessage>
    );
};

const PrivateMessage = (props: ReceivedProps) => {
    return <PrivateMessageLayout {...usePrivateMessage(props)} />;
};

export default PrivateMessage;
