import { Modal } from "antd";
import styled from "styled-components";

export const StyledModalInvoice = styled(Modal)`
    .MuiPaper-root {
        box-shadow: none;
    }
    .ant-select-dropdown {
        z-index: 99999999999 !important;
    }
    .ant-select-selector {
        height: 40px !important;
        width: 100%;
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .cancelBtn,
        .registerBtn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 18px;
            gap: 8px;
            width: 49%;
            height: 44px;
            border-radius: 2px;
            border-color: #215493;
            outline: none;
        }

        .cancelBtn {
            box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
            border: 1px solid #d9d9d9;
            color: #215493;
        }

        .registerBtn {
            background: #215493;
            color: #ffffff;
            border: none;
        }
    }

    .ant-modal-body {
        padding: 0px !important;
        border-radius: 12px !important;
    }

    .ant-modal-content {
        border-radius: 20px !important;
    }
`;