import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import WorkingTimeService from 'services/workingTimesService';
import moment from 'moment';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import departmentsService from 'services/departmentsService';
import PositionsService from 'services/positionService';
import workersService from 'services/wokersService';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';


interface ITotal {
    total_working_days: number;
    total_working_times: number;
}

interface IDataSendToBE {
    worker_id?: number | undefined;
    month: number;
    year: number;
}

interface IDataSendToBE6Months {
    worker_id: number;
    start_month: string | Date;
}

interface IItemWorkingTimePair {
    worker_name?: string;
    submit_date?: string;
    check_in_time?: string;
    check_out_time?: string;
    total_time?: number;
    checkin_location?: string;
    checkout_location?: string;
    checkin_item_id?: number;
    checkout_item_id?: number;
    uid?: number;
    worker_id?: number;
    is_manual_checkin?: boolean;
    is_manual_checkout?: boolean;
}

const useWorkingTimes = (props: any) => {
    const [workingTimeData, setWorkingTimeData] = useState({});
    const [departmentOptions, setDepartmentOptions] = useState<any>([]);
    const [positionOptions, setPositionOptions] = useState<any>([]);
    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [isOpenDepartment, setIsOpenDepartment] = useState(false);
    const [isOpenWorker, setIsOpenWorker] = useState(false);
    const [isOpenPosition, setIsOpenPosition] = useState(false);
    const [deleteData, setDeleteData] = useState<any>({});
    const [total, setTotal] = useState<ITotal>();
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [month, setMonth] = useState<Date | string>('');
    const [idWorker, setIdWorker] = useState<number>(0);
    const [srcPDF, setSrcPdf] = useState<string | null>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isErrorDate, setIsErrorDate] = useState(true);
    const [isShowPopup6Months, setIsShowPopup6Months] = useState(false);
    const [startMonth, setStartMonth] = useState<Date | string>('');
    const [srcPDF6Months, setSrcPdf6Months] = useState<string | null>();
    const [idWorker6Months, setIdWorker6Months] = useState<number>(0);
    const [disabledBtnSubmit6Months, setDisabledBtnSubmit6Month] = useState(false);
    const [isErrorStartMonth, setIsErrorStartMonth] = useState(true);
    const [isErrorWorker6Months, setIsErrorWorker6Month] = useState(true);
    const [isErrorWorker, setIsErrorWorker] = useState(true);
    const [disabledBtnSubmit1Month, setDisabledBtnSubmit1Month] = useState(false);
    const [pageQueries, setPageQueries] = useState<any>({
        page: 1,
        per_page: 10,
    });
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage]: any = useState();
    const [statusPrev, setStatusPrev]: any = useState(false);
    const [statusNext, setStatusNext]: any = useState(false);
    const [workingTimeLists, setWorkingTimeLists] = useState<any>([]);

    const [workTimeList, setWorkTimeList] = useState<IItemWorkingTimePair[]>([]);
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );

    const navigate = useNavigate();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            year: (() => {
                const currentTime = new Date();
                return currentTime.getFullYear();
            })(),
            month: (() => {
                const currentTime = new Date();
                return currentTime.getMonth() + 1;
            })(),
            data_type: 'attendance',
            time_range: 'weekly',
            week_number: '1',
            free_word: '',
            department_id: [],
            position_id: [],
            worker_id: {
                name: '',
                id: '',
            },
        },
        onSubmit: (values) => fetchWorkingTime(values),
    });

    const onSearch = (data) => {
        fetchWorkingTimes(data)
    };

    const handleOpenDelete = (data) => {
        setDeleteData(data);
        openDelete();
    };

    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    }

    const handleDelete = async () => {
        try {
            const res = await WorkingTimeService.deleteWorkingTimes(deleteData.uid, {
                worker_id: deleteData?.worker_id,
            });
            if (res) {
                alertSuccess('勤怠を登録しました。')
                closeDelete();
                fetchWorkingTimes();
            }
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const getPdfWorkingTime = async (workerId: number, month: number, year: number) => {
        if (!month || !year || isErrorDate) {
            return;
        }
        let data: IDataSendToBE = {
            worker_id: workerId,
            month: month,
            year: year
        }
        if (!workerId || workerId === 0) {
            delete data.worker_id
        }
        setDisabledBtnSubmit1Month(true);
        try {
            const res = await WorkingTimeService.getWorkingTimePdf(data)
            const src = window.URL.createObjectURL(new Blob([res as ArrayBuffer], { type: 'application/pdf' }));
            setSrcPdf(src)
            setIsShowPopup(false)
            setIdWorker(0)
            setIsErrorWorker(true)
            setDisabledBtnSubmit1Month(false)
            setMonth('')
            setIsErrorDate(true)
        } catch (error) {
            console.log(error);
            setIsErrorWorker(true);
            setDisabledBtnSubmit1Month(false);
            setIsErrorDate(true);
        }
    }

    const getPdfWorkingTime6Months = async () => {
        if (!idWorker6Months || idWorker6Months === 0 || !startMonth) return;

        let data: IDataSendToBE6Months = {
            worker_id: idWorker6Months,
            start_month: moment(startMonth).format('YYYY-MM-DD'),
        }
        setDisabledBtnSubmit6Month(true);
        try {
            const res = await WorkingTimeService.getWorkingTimePdf6Months(data);
            const src = window.URL.createObjectURL(new Blob([res as ArrayBuffer], { type: 'application/pdf' }));
            setSrcPdf6Months(src);
            setIsShowPopup6Months(false);
            setStartMonth('');
            setIdWorker6Months(0);
            setDisabledBtnSubmit6Month(false);
            setIsErrorStartMonth(true);
            setIsErrorWorker6Month(true);
        } catch (error) {
            console.log(error);
            setDisabledBtnSubmit6Month(false);
            setIsErrorStartMonth(true);
            setIsErrorWorker6Month(true);
        }
    }

    const getWeekStartDays = (values) => {
        const startOfMonth = moment(`${values?.year}/${values?.month}`).startOf(
            'month',
        );
        const endOfMonth = startOfMonth.clone().endOf('month');
        if (values?.time_range === 'monthly') {
            return {
                start_date: startOfMonth.format('YYYY-MM-DD'),
                end_date: endOfMonth.format('YYYY-MM-DD'),
            };
        } else {
            let currentWeek = startOfMonth
                .clone()
                .add(values.week_number, 'week')
                .startOf('week');
            if (currentWeek.isSameOrBefore(endOfMonth)) {
                const result = currentWeek?.clone();
                return {
                    start_date: result
                        .startOf('week')
                        .add(1, 'day')
                        .format('YYYY-MM-DD'),
                    end_date: result
                        .endOf('week')
                        .add(1, 'day')
                        .format('YYYY-MM-DD'),
                };
            }
        }
    };

    const fetchWorkingTime = async (values: any = {}) => {
        const data = getWeekStartDays(values);
        setLoading(true);
        if (!data) {
            setWorkingTimeData([]);
            setLoading(false);
            return;
        }
        try {
            const params = {
                ...data,
                attribute_department_ids: values?.department_id?.map(department => department.id) || [],
                attribute_position_ids: values?.position_id?.map(department => department.id) || [],
                worker_id: values?.worker_id?.id || '',
                free_word: values.free_word,
            };
            const { working_time_managements }: any =
                await WorkingTimeService.getWorkingTimeWorker(params);
            setWorkingTimeData(
                working_time_managements.reduce(
                    (a, v) => ({
                        ...a,
                        [v.name]: v.total_working_times.toString(),
                    }),
                    {},
                ),
            );
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const { departments } =
                await departmentsService.getListDepartments();
            setDepartmentOptions(
                departments?.map((department) => ({
                    value: department.id,
                    name: department.name,
                    label: department.name
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchPositions = async () => {
        try {
            const { positions } = await PositionsService.getListPositions();
            setPositionOptions(
                positions.map((item) => ({
                    value: item.id,
                    name: item.name,
                    label: item.name
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkerOptions = async () => {
        try {
            const { workers } = await workersService.getListWorkers();
            setWorkerOptions(
                workers.map((item) => ({
                    value: item.id,
                    name: item.name,
                    label: item.name
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkingTimes = async (params: any = {}) => {
        try {
            const { working_time_managements, meta }: any =
                await WorkingTimeService.getWorkingTime({
                    page: page,
                    per_page: 10,
                    attribute_department_ids:
                        params?.department_id?.map(
                            (department) => department,
                        ) || [],
                    attribute_position_ids:
                        params?.position_id?.map(
                            (department) => department,
                        ) || [],
                    worker_id: params?.worker_id || '',
                    free_word: params?.free_word || '',
                    start_date: params?.start_date ? moment(params?.start_date).format("YYYY-MM-DD") : '',
                    end_date: params?.end_date ? moment(params?.end_date).format("YYYY-MM-DD") : '',
                });
            let arrItemPairs: IItemWorkingTimePair[] = [];
            if (working_time_managements?.length > 0) {
                working_time_managements?.map(item => {
                    if (item?.working_time_items_pair?.length > 0) {
                        item?.working_time_items_pair?.map((itPair, idx) => {
                            arrItemPairs.push({ ...itPair, uid: item?.id, worker_id: item?.worker?.id })
                        })
                    }
                })
            }
            setWorkTimeList(arrItemPairs?.sort((a, b) => {
                let bCheckoutId = b?.checkout_item_id ? b?.checkout_item_id : 0;
                let aCheckoutId = a?.checkout_item_id ? a?.checkout_item_id : 0;
                return (b?.checkin_item_id! > bCheckoutId ? b?.checkin_item_id! : bCheckoutId) - (a?.checkin_item_id! > aCheckoutId ? a?.checkin_item_id! : aCheckoutId)
            }));
            const totalCount = meta?.total_count;
            const totalInteger = Math.floor(totalCount / 10);
            const totalSurplus = totalCount % 10;
            const totalPageCustom =
                totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
            setTotalPage(totalPageCustom);
            setTotal(meta?.totals);
            setTotalCount(meta?.totals)
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         console.log('Latitude is :', position.coords.latitude);
    //         console.log('Longitude is :', position.coords.longitude);
    //     });
    // }, []);

    useEffect(() => {
        fetchDepartments();
        fetchPositions();
        fetchWorkerOptions();
    }, []);

    useEffect(() => {
        fetchWorkingTimes();
    }, [page]);
    return {
        ...props,
        formik,
        workingTimeData,
        positionOptions,
        workerOptions,
        departmentOptions,
        loading,
        isOpenDepartment,
        setIsOpenDepartment,
        isOpenPosition,
        setIsOpenPosition,
        isOpenWorker,
        setIsOpenWorker,
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        errors,
        onSearch,
        navigate,
        workTimeList, setWorkTimeList,
        pageQueries, setPageQueries,
        workingTimeLists,
        ModalDelete,
        openDelete,
        closeDelete,
        isOpenDelete,
        handleOpenDelete,
        handleDelete,
        total,
        isShowPopup,
        setIsShowPopup,
        month,
        setMonth,
        getPdfWorkingTime,
        idWorker,
        setIdWorker,
        srcPDF,
        setSrcPdf,
        isErrorDate,
        setIsErrorDate,
        isShowPopup6Months,
        setIsShowPopup6Months,
        startMonth,
        setStartMonth,
        setIdWorker6Months,
        getPdfWorkingTime6Months,
        srcPDF6Months,
        setSrcPdf6Months,
        disabledBtnSubmit6Months,
        setDisabledBtnSubmit6Month,
        isErrorStartMonth,
        setIsErrorStartMonth,
        isErrorWorker6Months,
        setIsErrorWorker6Month,
        isErrorWorker,
        setIsErrorWorker,
        disabledBtnSubmit1Month,
        setDisabledBtnSubmit1Month,
        totalCount,
        page,
        statusPrev,
        statusNext,
        setPagePrev,
        setPageNext,
        totalPage,
        setPage
    };
};

export type Props = ReturnType<typeof useWorkingTimes>;

export default useWorkingTimes;
