import useWorkingTimesManagementsCreate, { Props } from './hook';
import { StylesWorkingTimesManagementCreate } from './styled';
import DateTimePickerField from 'pages/schedules/FormField/DateTimePicker';
import {
    Button,
    TextField,
    Autocomplete,
    Grid,
    Box,
    CircularProgress,
} from '@mui/material';
import ja from 'date-fns/locale/ja';
import { DatePicker, Select, TimePicker } from 'antd';
import { Controller } from 'react-hook-form';
import workTimeManagementValidation from 'utils/validation/registers/worktimeManagement';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { FocusEventHandler } from 'react';
import { RequireSign } from 'components/common';

const WorkingTimesManagementCreateLayout = ({
    formik,
    workerOptions,
    navigate,
    loading,
    isDetail,
    control,
    setValue,
    register,
    handleSubmit,
    errors,
    clearErrors,
    watch,
    getValues,
    setError,
    onSubmit,
    isErrorSubmitDate,
    isErrorWorker,
    isErrorStartDate,
    isErrorEndDate
}: Props) => {
    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };
    return (
        <div>
            <Box
                sx={{
                    '.react-datepicker__input-container': {
                        input: {
                            color: isDetail && 'rgba(0, 0, 0, 0.38)',
                        },
                    },
                }}
            >

                {loading ? (
                    <div className="flex justify-center items-center mt-4 max-w-[400px] min-h-[354px]">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StylesWorkingTimesManagementCreate className="filterBoxTop mt-4">
                            <p className="working_times--title mb-0">勤怠管理</p>
                            <div className="flex flex-col">
                                <p className="fieldLabel mt-2 flex flex-row !w-[102px]"><RequireSign />名前</p>
                                <Controller
                                    name="worker_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="wrapSelectField !w-[400px]">
                                            <Select
                                                options={workerOptions}
                                                showSearch
                                                {...register(
                                                    'worker_id',
                                                    workTimeManagementValidation().worker_id()
                                                )}
                                                {...field}
                                                filterOption={(input, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                disabled={isDetail}
                                                className="!w-full !h-[40px]"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.worker_id && (
                                    <span className="error">
                                        {errors.worker_id.message?.toString()}
                                    </span>
                                )}
                                {/* {isErrorWorker && !formik?.values?.worker_id && <p className='text-[#f44336] ml-[102px]'>必須です</p>} */}
                            </div>
                            <div className="flex mt-2 items-center">
                                <div className="flex flex-col">
                                    <p className="fieldLabel flex flex-row !w-[102px]"><RequireSign /> 日付</p>

                                    <Controller
                                        name="submit_date"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <div className="wrapSelectField !w-[400px]">
                                                <DatePicker
                                                    placeholder="日付選択"
                                                    {...register(
                                                        'submit_date',
                                                        workTimeManagementValidation().submit_date()
                                                    )}
                                                    {...field}
                                                    disabled={isDetail}
                                                    format={'YYYY年MM月DD日'}
                                                    style={{ height: '40px', background: '#fff', color: '#000' }}
                                                    className="py-0 w-full"
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.submit_date && (
                                        <span className="error">
                                            {errors.submit_date.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col mt-2">
                                <p className="fieldLabel flex flex-row !w-[102px]"><RequireSign />
                                    出勤時間
                                </p>
                                <Controller
                                    name="start_time"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="wrapSelectField !w-[400px]">
                                            <DatePicker
                                                placeholder="出勤時間"
                                                {...register(
                                                    'start_time',
                                                    workTimeManagementValidation().start_time()
                                                )}
                                                {...field}
                                                disabled={isDetail}
                                                showHour={true}
                                                showMinute={true}
                                                showTime={true}
                                                // format={'YYYY年MM月DD日'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0 w-full"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.start_time && (
                                    <span className="error">
                                        {errors.start_time.message?.toString()}
                                    </span>
                                )}
                                {/* {isErrorStartDate && !formik?.values?.start_time && <p className='text-[#f44336] ml-[102px]'>必須です</p>} */}
                            </div>
                            <div className="flex flex-col mt-2">
                                <p className="fieldLabel flex flex-row !w-[102px]"><RequireSign />位置</p>
                                <Controller
                                    name="checkin_location"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="checkin_location"
                                                className="textBox rounded-[2px] w-[400px] h-[40px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'checkin_location',
                                                    workTimeManagementValidation().checkin_location()
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={255}
                                                disabled={isDetail}
                                            ></input>
                                            {errors.checkin_location && (
                                                <span className="error">
                                                    {errors.checkin_location.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                {/* <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        disabled={isDetail}
                                        name="checkin_location"
                                        type="text"
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        value={formik.values.checkin_location}
                                        error={
                                            formik.touched.checkin_location &&
                                            !!formik.errors.checkin_location
                                        }
                                        helperText={
                                            formik.touched.checkin_location &&
                                            formik.errors.checkin_location
                                        }
                                    />
                                </div> */}
                            </div>
                            <div className="flex mt-2 flex-col">
                                <p className="fieldLabel flex flex-row !w-[102px]"><RequireSign />
                                    退勤時間
                                </p>
                                <Controller
                                    name="end_time"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="wrapSelectField !w-[400px]">
                                            <DatePicker
                                                placeholder="退勤時間"
                                                {...register(
                                                    'end_time',
                                                    workTimeManagementValidation().end_time()
                                                )}
                                                {...field}
                                                disabled={isDetail}
                                                showHour
                                                showMinute
                                                showTime
                                                // showSecond={false}
                                                // format={'YYYY年MM月DD日'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0 w-full"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.end_time && (
                                    <span className="error">
                                        {errors.end_time.message?.toString()}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col mt-2">
                                <p className="fieldLabel flex flex-row !w-[102px]"><RequireSign />位置</p>
                                <Controller
                                    name="checkout_location"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="checkout_location"
                                                className="textBox rounded-[2px] w-[400px] border-[1px] h-[40px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'checkout_location',
                                                    workTimeManagementValidation().checkout_location()
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={255}
                                                disabled={isDetail}
                                            ></input>
                                            {errors.checkout_location && (
                                                <span className="error">
                                                    {errors.checkout_location.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                {/* <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        name="checkout_location"
                                        type="text"
                                        disabled={isDetail}
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        value={formik.values.checkout_location}
                                        error={
                                            formik.touched.checkout_location &&
                                            !!formik.errors.checkout_location
                                        }
                                        helperText={
                                            formik.touched.checkout_location &&
                                            formik.errors.checkout_location
                                        }
                                    />
                                </div> */}
                            </div>
                        </StylesWorkingTimesManagementCreate>
                        <div className='bg-[#fff] w-full flex flex-row justify-end py-[15px] px-[25px]' >
                            <div
                                className="modal-create-user-buttons !w-[300px] d-flex flex-row"
                            >
                                <button
                                    onClick={() =>
                                        navigate('/working_times_management')
                                    }
                                    className="modal-create-user-button1 d-flex flex-row !px-[3px] !py-[9px]"
                                >
                                    戻る
                                </button>
                                {!isDetail && (
                                    <button className="modal-create-user-button2 d-flex flex-row">
                                        登録
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </Box>
        </div>
    );
};

const WorkingTimesManagementCreate = (props: any) => {
    return (
        <WorkingTimesManagementCreateLayout
            {...useWorkingTimesManagementsCreate(props)}
        />
    );
};

export default WorkingTimesManagementCreate;
